import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconExclamationTriangleOutline = ({
  className,
  color = 'currentColor',
  size = 40,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.2514 6.52405L3.74004 29.0802C3.46147 29.5626 3.31407 30.1095 3.31251 30.6666C3.31095 31.2237 3.45529 31.7715 3.73115 32.2554C4.00702 32.7394 4.4048 33.1427 4.88493 33.4252C5.36506 33.7078 5.91079 33.8596 6.46783 33.8658H33.4905C34.0475 33.8596 34.5933 33.7078 35.0734 33.4252C35.5535 33.1427 35.9513 32.7394 36.2272 32.2554C36.503 31.7715 36.6474 31.2237 36.6458 30.6666C36.6443 30.1095 36.4969 29.5626 36.2183 29.0802L22.707 6.52405C22.4226 6.05523 22.0222 5.66762 21.5444 5.39861C21.0666 5.1296 20.5275 4.98828 19.9792 4.98828C19.4308 4.98828 18.8918 5.1296 18.414 5.39861C17.9362 5.66762 17.5358 6.05523 17.2514 6.52405V6.52405Z"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9785 14.7227V21.1035"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.9785 27.4844H19.9952"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
