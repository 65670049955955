class BaseModel {
  static defaultFields = {
    id: null,
    isModelConfigured: false,
  };

  constructor(modelData = {}) {
    const { defaultFields } = this.constructor;
    const fieldNames = Object.keys(defaultFields);

    if (fieldNames.length === 0)
      throw new ReferenceError(
        'Attempted to extend from `BaseModel` without specifying any `defaultFields`',
      );

    if (modelData) {
      for (let key of fieldNames) {
        // Use the value from constructor, or the default if not provided
        this[key] = modelData[key] ?? defaultFields[key];
      }
    }

    this.isLoaded = 'id' in modelData && typeof modelData.id === 'string';

    // Maintain a copy of the original data in case the constructor massages any of the incoming data
    this.__originalData = modelData;
  }
}

export default BaseModel;
