import classNames from 'classnames';

import styles from './Splotch.module.scss';

export const Splotch = ({ className, color, opacity, size }) => (
  <div
    className={classNames(styles.container, className)}
    style={{ height: size, width: size, background: color, opacity }}
  />
);

Splotch.defaultProps = {
  opacity: 1.0,
  size: 11,
};
