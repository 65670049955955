import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconEnvelope = ({
  className,
  color = 'currentColor',
  size = 24,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.8 5.72461L19.2 5.72461C20.19 5.72461 21 6.53461 21 7.52461V18.3246C21 19.3146 20.19 20.1246 19.2 20.1246H4.8C3.81 20.1246 3 19.3146 3 18.3246L3 7.52461C3 6.53461 3.81 5.72461 4.8 5.72461Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 7.52441L12 13.8244L3 7.52441"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
