import { NavLink, useHistory } from 'react-router-dom';

import Page from 'pages/elements';

import styles from './Error.module.scss';

export const Error = ({ defaultReturnLink, messageText, statusCode }) => {
  const { location } = useHistory();

  // Check to see if there's a `from` in location.state to determine where
  // to send the user back to, otherwise send them to Home page
  const returnLink = location?.state?.from ? location?.state?.from : defaultReturnLink;
  const buttonText = location?.state?.from ? 'Go back' : 'Take me Home';

  return (
    <div className={styles.container}>
      <main className={styles.content}>
        {statusCode ? (
          <Page.Heading element="h1" className={styles.statusCode}>
            {statusCode}
          </Page.Heading>
        ) : (
          <Page.Heading element="h1" aria-label="Error" />
        )}

        <p className={styles.text}>{messageText}</p>

        <div className={styles.buttonWrapper}>
          <NavLink className={styles.button} to={returnLink}>
            {buttonText}
          </NavLink>
        </div>
      </main>
    </div>
  );
};

Error.defaultProps = {
  defaultReturnLink: '/',
  messageText: 'Oops! Something went wrong.',
};
