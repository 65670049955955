import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';

import { ChildStatusEnum } from 'graphql/constants';
import { Blanket, ConfirmationPrompt } from 'ui';
import { formatDate } from 'utilities/dates';

import { DobInput } from '../DobInput/index';
import { DobText } from '../DobText/index';
import { PendingChildRow } from '../PendingChildRow';
import { RoomDropdown } from '../RoomDropdown/index';
import { RoomText } from '../RoomText/index';

import styles from './ChildDetailRow.module.scss';
import { useProfile } from 'app/profile';
import ToggleSwitch from './ToggleSwitch';
import { useChildUpdate } from 'hooks';

export const ChildDetailRow = ({
  child,
  disabled,
  isEditing,
  roomOptions,
  childFormData,
  setChildFormData,
  childFormErrors,
  childUpdateLoading,
  handleReactivation,
  handleApproveChildAccount,
  handleUnrecogniseChildAccount,
  nurseryCanToggleInstantBook,
}) => {
  const { nursery } = useProfile();
  const originalBirthDate = useMemo(
    () => (child.birthDate ? formatDate(child.birthDate) : ''),
    [child.birthDate],
  );

  const isPending = child.status === ChildStatusEnum.PENDING;
  const isDisabled = disabled || isPending;
  const canEdit = isEditing && !isDisabled;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [childIsInstantBook, setChildIsInstantBook] = useState(child.instantBook);

  const [showTooltip, setShowTooltip] = useState(false);

  const showInstantBookTooltip = useMemo(() => {
    return child.instantBookLastUpdated && child.instantBookLastUpdatedBy;
  }, [child]);

  const tooltipRef = useRef(null);

  const { edit: editChild } = useChildUpdate();

  const handleToggle = async () => {
    await editChild({
      childId: child.id,
      instantBook: !childIsInstantBook,
    });

    setChildIsInstantBook((prev) => !prev);
  };

  const handleTooltipClick = () => {
    setShowTooltip((prevShowTooltip) => !prevShowTooltip);
  };

  //   handle closing tooltip on mouse event
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!childUpdateLoading) {
      setIsLoading(false);
      setShowConfirmation(false);
    }
  }, [childUpdateLoading]);

  const reactivateRef = useRef();
  const [reactivateFocus, setReactivateFocus] = useState(false);
  useEffect(() => {
    if (reactivateFocus && reactivateRef.current) {
      reactivateRef.current.focus();
      setReactivateFocus(false);
    }
  }, [reactivateFocus]);

  return (
    <>
      {!disabled && isPending && (
        <PendingChildRow
          child={child}
          roomOptions={roomOptions}
          handleApproveChildAccount={handleApproveChildAccount}
          handleUnrecogniseChildAccount={handleUnrecogniseChildAccount}
          childUpdateLoading={childUpdateLoading}
          isExternallyManaged={nursery?.isExternallyManaged}
        />
      )}
      <div
        className={classNames(
          nurseryCanToggleInstantBook && !isPending
            ? styles.personDetailsInstantBook
            : styles.personDetails,
          isPending && styles.pendingAccountDetails,
        )}>
        <dl data-testid={child.id}>
          <div className={classNames(styles.personDetailElement, isDisabled && styles.disabled)}>
            <dt>Name</dt>
            <dd>{child.fullName}</dd>
          </div>

          <div className={classNames(styles.personDetailElement, isDisabled && styles.disabled)}>
            <dt>
              {!canEdit ? (
                <>Date of birth</>
              ) : (
                <label htmlFor={`DoB.${child.id}`}>Date of birth</label>
              )}
            </dt>
            <dd>
              {canEdit ? (
                <DobInput
                  child={child}
                  childFormData={childFormData}
                  childFormErrors={childFormErrors}
                  originalBirthDate={originalBirthDate}
                  setChildFormData={setChildFormData}
                />
              ) : (
                <DobText birthDate={child.birthDate} />
              )}
            </dd>
          </div>

          <div className={classNames(styles.personDetailElement, isDisabled && styles.disabled)}>
            <dt>Room</dt>
            <dd>
              {canEdit ? (
                <RoomDropdown
                  child={child}
                  childFormData={childFormData}
                  roomOptions={roomOptions}
                  setChildFormData={setChildFormData}
                />
              ) : (
                <RoomText room={child.primaryNurseryRoom} isDisabled={isDisabled} />
              )}
            </dd>
          </div>

          {child.status !== ChildStatusEnum.DEACTIVATED && (
            <div className={classNames(styles.personDetailElement, styles.small)}>
              <dt>Bookings</dt>
              <dd>{child.bookingsConfirmedCount > 0 ? child.bookingsConfirmedCount : '-'}</dd>
            </div>
          )}

          {!isPending && nurseryCanToggleInstantBook && (
            <div className={classNames(styles.instantBookElement, styles.small)}>
              <dt>
                Instant Book{' '}
                {showInstantBookTooltip && (
                  <div ref={tooltipRef} onClick={handleTooltipClick} className={styles.tooltipIcon}>
                    <span>i</span>
                    {showTooltip && (
                      <div className={styles.tooltipContent}>
                        Last updated {child.instantBookLastUpdated || ''} by{' '}
                        {child.instantBookLastUpdatedBy.fullName || ''}
                      </div>
                    )}
                  </div>
                )}
              </dt>
              <dd>
                <ToggleSwitch
                  label={childIsInstantBook ? 'Enabled' : 'Disabled'}
                  childId={child.id}
                  isChecked={childIsInstantBook}
                  onToggle={handleToggle}
                />
              </dd>
            </div>
          )}
        </dl>
      </div>

      <ConfirmationPrompt
        title="You are about to reactivate this account. Are you sure?"
        message="Once reactivated, this child will be able to book at your centre into the room listed."
        isVisible={showConfirmation}
        confirmationLabel="Reactivate"
        onConfirm={() => {
          setIsLoading(true);
          handleReactivation({ childId: child.id });
        }}
        onCancel={() => {
          setShowConfirmation(false);
          setReactivateFocus(true);
        }}
        isConfirmationWorking={isLoading}
      />
      <Blanket
        isVisible={showConfirmation}
        onClick={() => {
          setShowConfirmation(false);
          setReactivateFocus(true);
        }}
      />
    </>
  );
};

ChildDetailRow.defaultProps = {
  disabled: false,
};
