import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

export const Wizard = ({
  currentScreen,
  screens,
  setScreen,
  size,
  onScreenChange,
  useHistoryApi,
  ...wizardProps
}) => {
  const history = useHistory();

  const { formData, setFormData } = wizardProps;
  const currentIndex = screens.findIndex((s) => s?.screenKey === currentScreen);
  const activeScreen = screens.find((s) => s?.screenKey === currentScreen);

  const hasValidation = 'validate' in activeScreen && typeof activeScreen.validate === 'function';
  const isStepValid = hasValidation ? activeScreen.validate(formData) : true;

  const hasFooter = 'footer' in activeScreen && typeof activeScreen.footer === 'function';

  const hasNextScreen = currentIndex < screens.length - 1;

  const goToNext = () => {
    if (!hasNextScreen || !isStepValid) return;

    const nextScreen = hasNextScreen ? screens[currentIndex + 1] : null;

    if (typeof onScreenChange === 'function') {
      onScreenChange(nextScreen, activeScreen, true);
    }

    setScreen(nextScreen.screenKey);
  };

  const goToPrevious = () => {
    if (currentIndex === 0) return;

    const previousScreen = screens[currentIndex - 1];

    if (typeof onScreenChange === 'function') {
      onScreenChange(previousScreen, activeScreen, false);
    }

    setScreen(previousScreen.screenKey);
  };

  const onChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const Footer = () => (
    <activeScreen.footer
      {...wizardProps}
      goToNextScreen={goToNext}
      goToPreviousScreen={goToPrevious}
      canProceed={isStepValid}
      onChange={onChange}
    />
  );

  /**
   * Sets the current screen whenever the browser location changes
   */
  useEffect(() => {
    if (!useHistoryApi) return;

    const screenName = history?.location?.state?.screen;
    const firstScreen = screens[0].screenKey;

    // Only update screen name in this way if it differs from what's currently in browser state.
    if (screenName !== currentScreen) {
      setScreen(screenName ?? firstScreen);
    }
  }, [history.location, currentScreen, screens, setScreen, useHistoryApi]);

  return (
    <div>
      {screens.map((Screen) => {
        const isVisible = currentScreen === Screen.screenKey;

        return (
          <div key={Screen.screenKey}>
            {isVisible && (
              <Screen
                {...wizardProps}
                canProceed={isStepValid}
                goToNextScreen={goToNext}
                goToPreviousScreen={goToPrevious}
                onChange={onChange}
                setFormData={setFormData}
              />
            )}
          </div>
        );
      })}

      {hasFooter && <Footer />}
    </div>
  );
};

Wizard.defaultProps = {
  formData: {},
  setFormData: () => null,
  onScreenChange: () => {},
  useHistoryApi: true,
};

Wizard.propTypes = {
  currentScreen: PropTypes.string.isRequired,
  setScreen: PropTypes.func.isRequired,
  screens: function (props, propName, componentName) {
    const areAllScreensValid = props[propName].every((screen) => screen.screenKey !== undefined);

    if (!areAllScreensValid) {
      return new Error(
        'Invalid prop `' +
          propName +
          '` supplied to ' +
          ' `' +
          componentName +
          '`. All screens inside of ' +
          '`Wizard` must have a `screenKey` static.',
      );
    }
  },
  onScreenChange: PropTypes.func,
  useHistoryApi: PropTypes.bool,
};
