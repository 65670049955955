import { differenceInDays, format, isFuture, parseISO, parseJSON } from 'date-fns';

import { BookingSessionTypeEnum, BookingStatusEnum, BookingType } from 'graphql/constants';
import { Nursery } from 'models';
import { stringToColor } from 'utilities/colors';

import BaseModel from './BaseModel';

export class BookingRequest extends BaseModel {
  static defaultFields = {
    additionalNotes: '',
    cancellationDetails: '',
    cancellationReason: '',
    cancelledAt: null,
    child: {},
    createdAt: null,
    date: null,
    id: null,
    nursery: {},
    payment: null,
    paymentExemptionReason: '',
    rejectedAt: null,
    rejectionDetails: '',
    rejectionReason: '',
    room: {},
    sessionType: '',
    customSessionType: null,
    status: '',
    type: '',
    isPrimary: null,
    discount: null,
    originalAmount: null,
    finalAmount: null,
  };

  static sessionTypeLabels = {
    [BookingSessionTypeEnum.AM]: 'AM',
    [BookingSessionTypeEnum.PM]: 'PM',
    [BookingSessionTypeEnum.FULL]: 'Full',
  };

  static statusLabels = {
    [BookingStatusEnum.CANCELLED]: 'Cancelled',
    [BookingStatusEnum.CONFIRMED]: 'Confirmed',
    [BookingStatusEnum.PENDING]: 'Pending',
    [BookingStatusEnum.REJECTED]: 'Declined',
    [BookingStatusEnum.SCHEDULING]: 'Pending',
    [BookingStatusEnum.ON_HOLD]: 'On hold',
  };

  static typeLabels = {
    [BookingType.INSTANT]: 'Instant',
    [BookingType.REQUEST]: 'Request',
  };

  constructor(bookingRequest) {
    super(bookingRequest);

    this.date = parseISO(this.date);
    this.createdAt = parseJSON(this.createdAt);

    this.nursery = new Nursery(bookingRequest?.nursery);

    const roomName = this.room?.name ?? null;
    this.roomColor = stringToColor(roomName);
  }

  /**
   * Returns `this.book` in a human-readable string
   */
  get dateFormatted() {
    return format(this.date, 'EEE, dd MMM yyyy');
  }

  /**
   * Returns `this.createdAt` in a human-readable string
   */
  get createdAtFormatted() {
    return format(this.createdAt, 'dd.MM.yy');
  }

  /**
   * Returns true if this booking was instantly booked
   */
  get isInstantBook() {
    return this.type === BookingType.INSTANT;
  }

  /**
   * Returns true if this booking is confirmed
   */
  get isConfirmed() {
    return this.status === BookingStatusEnum.CONFIRMED;
  }

  /**
   * Returns true if this booking pending
   */
  get isPending() {
    return this.status === BookingStatusEnum.PENDING;
  }

  /**
   * Returns true if this booking happens in the future
   */
  get isUpcoming() {
    return isFuture(this.date);
  }

  /**
   * Returns the desired UI label for `this.type`
   */
  get typeLabel() {
    return BookingRequest.typeLabels[this.type] || '';
  }

  /**
   * Returns the desired UI label for `this.status`
   */
  get statusLabel() {
    return BookingRequest.statusLabels[this.status] || '';
  }

  /**
   * Returns the desired UI label for `this.sessionType`
   */
  get sessionTypeLabel() {
    return BookingRequest.sessionTypeLabels[this.sessionType] || '';
  }

  /**
   * Returns true if the booking is within `bookingHoldThresholdDays` of the nursery, or false
   * otherwise.
   *
   * @returns {Boolean}
   */
  get isWithinDelayThreshold() {
    const { bookingHoldThresholdDays, isBookingHoldEnabled } = this.nursery;

    if (!isBookingHoldEnabled || !bookingHoldThresholdDays) return false;

    return differenceInDays(this.date, new Date()) < bookingHoldThresholdDays;
  }
}
