import { Switch, Route } from 'react-router-dom';

import { useProfile } from 'app/profile';
import { DefaultAppPage } from 'layouts';

import { SettingsLink } from './components';
import * as Pages from './pages';
import styles from './Settings.module.scss';
import { trackAction, actions } from 'app/amplitude';

export const Settings = () => {
  const { nursery } = useProfile();

  return (
    <DefaultAppPage
      title="Settings"
      intro={<>View and adjust your settings.</>}
      useNurserySwitcher
      contentClass={styles.container}
      headerClass={styles.header}>
      <nav className={styles.sidebar}>
        <ul className={styles.sidebarItems}>
          <SettingsLink
            label="Payment details"
            slug="payment-details"
            disabled={!nursery?.paymentsEnabled}
            onClick={() => trackAction(actions.PAYMENT_DETAILS)}
          />
          {!nursery.isExternallyManaged && (
            <SettingsLink
              label="Contract cancellation"
              slug="contract-cancellation"
              onClick={() => trackAction(actions.CONTRACT_CANCELLATION)}
            />
          )}

          <SettingsLink
            label="Logout"
            className={styles.logout}
            href="/logout"
            onClick={() => trackAction(actions.LOGOUT)}
          />
        </ul>
      </nav>

      <main className={styles.page}>
        <Switch>
          <Route path="/settings/payment-details" component={Pages.PayoutDetails} exact />
          <Route path="/settings/contract-cancellation" component={Pages.ContractCancellation} />
        </Switch>
      </main>
    </DefaultAppPage>
  );
};
