import { useMemo } from 'react';

import { useProfile } from 'app/profile';
import { DefaultAppPage } from 'layouts';

import {
  AvailabilityPlannerTile,
  BookingsTile,
  BookingSummaryTile,
  SpacesLeftTile,
  ToDoTile,
} from './tiles';
import styles from './Home.module.scss';

export const Home = () => {
  const { nursery } = useProfile();
  const greeting = useMemo(getGreeting, []);

  return (
    <DefaultAppPage
      title={`${greeting},`}
      intro="Here is an overview of what’s happening"
      headerClass={styles.pageHeader}
      useNurserySwitcher>
      <div className={styles.container}>
        <div className={styles.modules}>
          <div className={styles.bookingCount}>
            <BookingSummaryTile nurseryId={nursery.id} />
          </div>
          <div className={styles.spacesLeft}>
            <SpacesLeftTile nurseryId={nursery.id} />
          </div>
          <div className={styles.whosComing}>
            <BookingsTile nurseryId={nursery.id} />
          </div>
          <div className={styles.toDoList}>
            <ToDoTile nurseryId={nursery.id} />
          </div>
          <div className={styles.availabilityPlanner}>
            <AvailabilityPlannerTile nurseryId={nursery.id} />
          </div>
        </div>
      </div>
    </DefaultAppPage>
  );
};

/**
 * Returns a greeting based on the current hour
 *
 * @returns {String}
 */
const getGreeting = () => {
  const hour = new Date().getHours();

  if (hour >= 0 && hour < 12) {
    return 'Good morning';
  } else if (hour >= 12 && hour < 17) {
    return 'Good afternoon';
  } else {
    return 'Good evening';
  }
};
