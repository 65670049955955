import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconSearch = ({ className, color = 'currentColor', size = 24, stroke, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.1154 18.7308C14.8735 18.7308 18.7308 14.8735 18.7308 10.1154C18.7308 5.35724 14.8735 1.5 10.1154 1.5C5.35724 1.5 1.5 5.35724 1.5 10.1154C1.5 14.8735 5.35724 18.7308 10.1154 18.7308Z"
      stroke="#7F839B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.4998 22.4998L16.1992 16.1992"
      stroke="#7F839B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
