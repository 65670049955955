/**
 * Converts an array into smaller chunks
 *
 * @example
 * // returns [[1, 2, 3], [4, 5, 6], [7, 8]]
 * chunk([1, 2, 3, 4, 5, 6, 7, 8], 3);
 *
 * @param {Array} array The list of items to transform
 * @param {Number} size The size of each chunk
 */
export const chunk = (array, size) => {
  const chunkedArray = [];
  for (let i = 0; i < array.length; i++) {
    const last = chunkedArray[chunkedArray.length - 1];
    if (!last || last.length === size) {
      chunkedArray.push([array[i]]);
    } else {
      last.push(array[i]);
    }
  }
  return chunkedArray;
};

/**
 * Returns an array that is sorted by multiple fields
 *
 * @param {Array} array The original array to sort
 * @param {Array} sortOrder An list of fields to sort by
 */
export const multiSort = (array, sortOrder) => [...array.sort(fieldSorter(sortOrder))];

/**
 * Sorter function that allows easily sorting by multiple fields
 *
 * @example children.sort(fieldSorter(['name', 'age']))
 *   This would sort children first by name, then by age
 * @example children.sort(fieldSorter(['-age', 'name']))
 *   This example would sort children first by age in reverse, then by name
 *
 * @param {Array} fields The list of fields to sort by
 */
function fieldSorter(fields) {
  return function (a, b) {
    return fields
      .map(function (o) {
        var dir = 1;
        if (o[0] === '-') {
          dir = -1;
          o = o.substring(1);
        }
        if (a[o] > b[o]) return dir;
        if (a[o] < b[o]) return -dir;
        return 0;
      })
      .reduce(function firstNonZeroValue(p, n) {
        return p ? p : n;
      }, 0);
  };
}
