import { useContext, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import emailValidator from 'email-validator';

import { AuthContext } from 'app/auth';
import { useAnalytics, ANALYTICS_EVENTS } from 'app/analytics';
import { PublicPageWithIllustration } from 'layouts';
import { ErrorMessage } from 'pages/elements';
import { Blanket, Button, Dialog, TextInput } from 'ui';
import { passwordMatcher } from 'utilities/regexes';

import styles from './Register.module.scss';

export const Register = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [showErrors, setShowErrors] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const formErrors = useMemo(() => {
    const errors = {};
    if (!emailValidator.validate(formData.email)) errors.email = true;
    if (formData.password !== formData.confirmPassword) errors.confirmPassword = true;
    if (!passwordMatcher.test(formData.password)) errors.password = true;

    return errors;
  }, [formData]);

  const updateFormData = (key) => (event) =>
    setFormData({
      ...formData,
      [key]: event.target.value,
    });

  const handleSignUpClick = async () => {
    setShowErrors(false);

    if (Object.keys(formErrors).length > 0) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);

    try {
      await authContext.signUp(formData.email, formData.password);

      trackEvent(ANALYTICS_EVENTS.SIGNUP);

      history.push('/verify-account', {
        email: formData.email,
      });
    } catch (error) {
      console.log('[Auth] Signup Failed:', error);
      // Error dialog
      setShowErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PublicPageWithIllustration title="Sign up">
      <p>
        Already have an account? <Link to="/login">Login</Link>
      </p>

      <TextInput
        label="Your nursery email"
        type="email"
        placeholder="your.name@mail.com"
        value={formData.email}
        onChange={updateFormData('email')}
        hasError={'email' in formErrors && showErrors}
        className={styles.textInput}>
        <ErrorMessage
          isVisible={'email' in formErrors && showErrors}
          className={styles.errorMessage}>
          You must enter an email address
        </ErrorMessage>
      </TextInput>

      <TextInput
        label="Create a password"
        placeholder="Password"
        hintText="Min 8 characters, with uppercase and lowercase and a number"
        type="password"
        value={formData.password}
        onChange={updateFormData('password')}
        hasError={'password' in formErrors && showErrors}
        className={styles.textInput}>
        <ErrorMessage
          isVisible={'password' in formErrors && showErrors}
          className={styles.errorMessage}>
          Password does not meet requirements
        </ErrorMessage>
      </TextInput>

      <TextInput
        label="Confirm password"
        placeholder="Re-type password"
        type="password"
        value={formData.confirmPassword}
        onChange={updateFormData('confirmPassword')}
        hasError={'confirmPassword' in formErrors && showErrors}
        className={styles.textInput}>
        <ErrorMessage
          isVisible={'confirmPassword' in formErrors && showErrors}
          className={styles.errorMessage}>
          Passwords must be the same
        </ErrorMessage>
      </TextInput>

      <Button
        className={styles.loginButton}
        disabled={isLoading}
        isWorking={isLoading}
        expand
        onClick={handleSignUpClick}>
        Sign up
      </Button>

      <i
        style={{
          fontSize: '12px',
          marginTop: '10px',
        }}>
        By accessing the Pebble platform, you agree to the
        <a
          href="https://bookpebble.co.uk/terms/provider-terms"
          target="_blank"
          style={{
            marginLeft: '3px',
          }}
          rel="noreferrer">
          Provider User Terms
        </a>
        . For further information about how Pebble processes your personal data, please refer to
        Pebble's
        <a
          href="https://www.bookpebble.co.uk/privacy-policy"
          target="_blank"
          style={{
            marginLeft: '3px',
          }}
          rel="noreferrer">
          Privacy Policy
        </a>
        .
      </i>

      <Dialog
        title="Registration issue"
        isVisible={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        menu={
          <Button href="/forgot-password" expand palette="pink">
            Reset password
          </Button>
        }>
        <p>
          We’re sorry, we were unable to create your account. If you already registered and forgot
          your password you can reset it below, or contact{' '}
          <a href="mailto:support@bookpebble.co.uk" className={styles.supportLink}>
            support@bookpebble.co.uk
          </a>
        </p>
      </Dialog>

      <Blanket isVisible={showErrorDialog} />
    </PublicPageWithIllustration>
  );
};
