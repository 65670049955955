import { useState } from 'react';

import { BookingStatusEnum } from 'graphql/constants';
import { IconClock } from 'icons';
import { Dropdown } from 'ui';

import styles from './OnHoldStatusPicker.module.scss';

export const OnHoldStatusPicker = ({ onStatusPick, options }) => {
  const [pickedValue, setPickedValue] = useState(null);

  const handlePick = ({ value }) => {
    setPickedValue(value);
    onStatusPick(value);
  };

  return (
    <Dropdown
      noValueText={
        <div className={styles.noValueText}>
          <IconClock size={16} className={styles.iconClock} />
          On Hold
        </div>
      }
      onChange={handlePick}
      options={options}
      value={pickedValue}
      palette="electro"
      variant="outline"
    />
  );
};

OnHoldStatusPicker.defaultOptions = [
  { value: BookingStatusEnum.CONFIRMED, label: 'Approve' },
  { value: BookingStatusEnum.REJECTED, label: 'Decline' },
];

OnHoldStatusPicker.defaultProps = {
  options: OnHoldStatusPicker.defaultOptions,
};
