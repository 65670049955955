import classNames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import { BookingStatusEnum, PaymentStatusEnum } from 'graphql/constants';

import styles from './PaymentTag.module.scss';

const currencySymbol = '£';
const currencyFormat = (amount) => `${currencySymbol}${amount}`;

const exemptReasonText = {
  EMERGENCY_CARE_SESSION: 'Emergency care session',
  SETTLE_IN_SESSION: 'Settle-in session',
  SESSION_IN_LIEU: 'Day in lieu',
  TAX_FREE_SESSION: 'Paid from tax-free account',
  FULL_DISCOUNT: '100% discount',
  CARD_PAYMENT_FAILED: 'Card payment failed',
};

const NotPrepaidTag = ({ isEnabled, exemptReason = '' }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'top',
    });

  const showTooptip = visible && isEnabled && !!exemptReason;

  return (
    <>
      <div
        className={classNames(styles.paymentTag, isEnabled ? styles.notPrepaid : styles.disabled)}
        ref={setTriggerRef}>
        Not prepaid
      </div>

      {showTooptip && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: styles.tooltipContainer })}>
          {exemptReasonText[exemptReason]}
          <div {...getArrowProps({ className: styles.tooltipArrow })} />
        </div>
      )}
    </>
  );
};

export const PaymentTag = ({ booking, isEnabled }) => {
  const hasFullDiscount = booking?.discount > 0 && Number(booking?.finalAmount) === 0;

  if (booking?.status === BookingStatusEnum.REJECTED) {
    return null;
  }

  if (booking?.payment?.isRefunded && booking?.payment?.status !== PaymentStatusEnum.Canceled) {
    return (
      <div className={classNames(styles.paymentTag, isEnabled ? styles.refunded : styles.disabled)}>
        Refunded
      </div>
    );
  }

  if (hasFullDiscount) {
    return <NotPrepaidTag isEnabled={isEnabled} exemptReason="FULL_DISCOUNT" />;
  }

  if (booking?.paymentExemptionReason) {
    return <NotPrepaidTag isEnabled={isEnabled} exemptReason={booking?.paymentExemptionReason} />;
  }

  switch (booking?.payment?.status) {
    case PaymentStatusEnum.Processing:
    case PaymentStatusEnum.RequiresAction:
    case PaymentStatusEnum.RequiresCapture:
    case PaymentStatusEnum.RequiresConfirmation:
    case PaymentStatusEnum.Succeeded:
      return (
        <div
          className={classNames(styles.paymentTag, isEnabled ? styles.prepaid : styles.disabled)}>
          {currencyFormat(booking?.payment?.amount)}
        </div>
      );

    case PaymentStatusEnum.Canceled:
      if ([BookingStatusEnum.CONFIRMED, BookingStatusEnum.PENDING].includes(booking?.status)) {
        return (
          <NotPrepaidTag isEnabled={isEnabled} exemptReason={booking?.paymentExemptionReason} />
        );
      }

      return null;

    default:
      if (booking?.payment?.paidViaCsm) {
        return (
          <div
            className={classNames(styles.paymentTag, isEnabled ? styles.prepaid : styles.disabled)}>
            {currencyFormat(booking?.finalAmount)}
          </div>
        );
      }

      if (!booking?.payment) {
        if (booking.status === BookingStatusEnum.PENDING && booking.finalAmount) {
          return (
            <div
              className={classNames(
                styles.paymentTag,
                isEnabled ? styles.prepaid : styles.disabled,
              )}>
              {currencyFormat(booking.finalAmount)}
            </div>
          );
        }

        // if the booking went through with no paymentExemptionReason then we can assume that payments weren't enabled at the time
        if (booking.status === BookingStatusEnum.SUCCEEDED && !booking?.paymentExemptionReason) {
          return null;
        }

        // if not paidViaCMS show hover state "Card payment failed"
        const exemptReason = booking?.paymentExemptionReason || 'CARD_PAYMENT_FAILED';

        return <NotPrepaidTag isEnabled={isEnabled} exemptReason={exemptReason} />;
      }

      return null;
  }
};

PaymentTag.defaultProps = {
  isEnabled: true,
};
