import { useState } from 'react';

import { IconCheck } from 'icons';
import { Button, Dropdown, Table, Tag } from 'ui';
import { trackAction, actions } from 'app/amplitude';

import styles from './PendingChildTableRow.module.scss';

export const PendingChildTableRow = ({
  booking,
  className,
  isPending,
  roomOptions,
  rowIndex,
  rowProps,
  onChildApprove,
  onChildReject,
  paymentsEnabled,
  isExternallyManaged,
}) => {
  const [roomId, setRoomId] = useState(booking?.child?.primaryNurseryRoom?.id || null);
  const [hasRoomError, setRoomError] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const handleYesClick = async (e) => {
    e.preventDefault();

    if (!roomId) {
      setRoomError(true);
    } else {
      setIsApproving(true);
      await onChildApprove({
        childId: booking?.child?.id,
        roomId,
      })(e);
    }
    trackAction(actions.BOOKINGS_CHILD_RECOGNISED_YES);
  };

  const handleNoClick = async (e) => {
    e.preventDefault();
    await onChildReject({ childId: booking?.child?.id, roomId })(e);
    trackAction(actions.BOOKINGS_CHILD_RECOGNISED_NO);
  };

  const cells = [
    Table.cell({
      content: <Tag>New child</Tag>,
    }),

    Table.cell({
      colSpan: 3,
      content: (
        <>
          <span className={styles.pendingChildQuestion}>Do you recognise this child?</span>
          <span className={styles.pendingChildName}>{booking?.child?.fullName}</span>
        </>
      ),
    }),

    Table.cell({
      colSpan: paymentsEnabled ? 2 : 1,
      content: !isExternallyManaged && isPending && (
        <Dropdown
          className={styles.pendingChildRoomDropdown}
          onChange={(room) => {
            setRoomId(room.value);
            setRoomError(false);
            trackAction(actions.BOOKINGS_ROOM_SELECTION);
          }}
          options={roomOptions}
          value={roomId}
          size="small"
          palette={hasRoomError ? 'red' : 'electro'}
          variant="outline"
        />
      ),
    }),

    Table.cell({
      content: isPending ? (
        <div className={styles.buttonGroup}>
          <Button isWorking={isApproving} onClick={handleYesClick}>
            Yes
          </Button>
          <Button disabled={isApproving} type="secondary" onClick={handleNoClick}>
            No
          </Button>
        </div>
      ) : (
        <div className={styles.childVerified}>
          <IconCheck size={12} className={styles.icon} />
          Child verified
        </div>
      ),
    }),
  ];
  return <Table.Row className={className} cells={cells} rowIndex={rowIndex} {...rowProps} />;
};

PendingChildTableRow.defaultProps = {
  isPending: true,
};
