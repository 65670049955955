import { NavLink } from 'react-router-dom';

import { PublicPageWithIllustration } from 'layouts';

import styles from './ResetPasswordConfirmation.module.scss';

export const ResetPasswordConfirmation = () => (
  <PublicPageWithIllustration>
    <div className={styles.container}>
      <h1>Reset password</h1>

      <p>
        Password successfully changed. You may now login using your email address and new password.
      </p>

      <NavLink className={styles.loginLink} to="/login">
        Login
      </NavLink>
    </div>
  </PublicPageWithIllustration>
);

ResetPasswordConfirmation.screenKey = 'RESET_PASSWORD_CONFIRMATION';
