import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconBellCircle = ({
  className,
  color = 'currentColor',
  circleColor = '#C6F991',
  size = 24,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3537 26.2305C18.3125 26.3539 18.3125 26.5185 18.3125 26.642C18.3125 27.5473 19.0532 28.2881 19.9586 28.2881C20.8639 28.2881 21.6047 27.5473 21.6047 26.642C21.6047 26.4774 21.5635 26.3539 21.5635 26.2305H18.3537Z"
      fill={color}
    />
    <path
      d="M26.6673 24.3773C26.6673 24.2538 26.585 24.1304 26.5027 24.0069C26.5027 24.0069 25.0624 22.4431 25.0624 18.3691C25.0624 15.2003 22.9225 13.8835 21.1941 13.4719V12.5254C21.1941 11.867 20.6591 11.332 20.0007 11.332C19.3422 11.332 18.8072 11.867 18.8072 12.5254V13.4719C17.0377 13.8835 14.9389 15.2003 14.9389 18.3691C14.9389 22.4431 13.4986 23.9658 13.4986 24.0069C13.4163 24.0892 13.334 24.2538 13.334 24.3773V25.4884H26.6673V24.3773Z"
      fill={color}
    />
    <path
      d="M5 12.3203C0.397627 20.2919 3.12888 30.485 11.1004 35.0874C14.761 37.2008 18.89 37.7678 22.7311 36.9911"
      stroke={circleColor}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9691 3.63672C22.2722 2.54968 28.0023 4.06328 32.1165 8.1775C37.6436 13.7046 38.4772 22.148 34.6172 28.5547"
      stroke={circleColor}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
