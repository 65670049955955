import { PublicPageWithIllustration } from 'layouts';
import { Button, Input } from 'ui';

import styles from './EnterEmail.module.scss';

export const EnterEmail = ({
  formData,
  goToNextScreen,
  initiatePasswordReset,
  isLoading,
  onChange,
}) => (
  <PublicPageWithIllustration title="Reset password" useHistoryBack>
    <div className={styles.container}>
      <p>
        Enter the email associated with the account and we will send you instructions to reset your
        password.
      </p>

      <div className={styles.inputWrapper}>
        <label className={styles.label}>
          Your e-mail
          <Input
            placeholder="Email address"
            value={formData.email}
            onChange={(e) => onChange('email', e.target.value)}
          />
        </label>
      </div>

      <Button
        className={styles.button}
        disabled={isLoading || formData.email?.trim().length === 0}
        expand
        onClick={(e) => {
          e.preventDefault();

          initiatePasswordReset();
          goToNextScreen();
        }}
      >
        Send instructions
      </Button>
    </div>
  </PublicPageWithIllustration>
);

EnterEmail.screenKey = 'ENTER_EMAIL';
