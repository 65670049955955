import { gql } from '@apollo/client';

export const profileQuery = gql`
  {
    nurseryAdmin {
      ... on NurseryAdminType {
        id
        fullName
        emailAddress
        nurseries {
          id
          name
          isExternallyManaged
          availabilityLastSynchronised
          availabilityLastEditedAt
          bookingHoldThresholdDays
          isBookingHoldEnabled
          hubspotAccountId
          nurseryRooms {
            id
            name
          }
          paymentsEnabled
          allowOnlyBookingRequests
          isExtracurricularActivityEnabled
          isCustomSessionBookingsEnabled
          instantBookingCutoffWindow
          bookingRequestCutoffWindow
          instantBookingCutoffWindowHours
          bookingRequestCutoffWindowHours
          showCustomSessionTimes
          sessionTypes {
            id
            name
            startTime
            endTime
            availabilityType
          }
          paymentAccount {
            status
          }
        }
      }
    }
  }
`;

export const getNurseryAvailability = gql`
  query AvailabilityForNurseryAdmin($startDate: Date!, $endDate: Date!, $nurseryId: UUID!) {
    availabilityForNurseryAdmin(startDate: $startDate, endDate: $endDate, nurseryId: $nurseryId) {
      nursery {
        id
        name
      }
      availability {
        date
        rooms {
          nurseryRoom {
            id
            name
            ageMonthsEnd
            ageMonthsStart
          }
          amAvailability
          pmAvailability
          remainingAmSpots
          remainingPmSpots
        }
      }
    }
  }
`;

export const getNurseryActivityBookings = gql`
  query ActivitiesByNursery(
    $nurseryId: UUID!
    $datetimeStart: DateTime
    $datetimeEnd: DateTime
    $activityId: UUID
  ) {
    extraBookingSpotsForNurseryAdmin(
      nurseryId: $nurseryId
      datetimeStart: $datetimeStart
      datetimeEnd: $datetimeEnd
      activityId: $activityId
    ) {
      id
      spotNumber
      spotStart
      spotEnd
      status
      extraBooking {
        id
        createdAt
        numberOfSpots
        firstSpotDate
        status
        activity {
          id
          name
          dayOfTheWeek
          startTime
          endTime
        }
        child {
          id
          fullName
        }
      }
    }
  }
`;

export const getNurseryActivities = gql`
  query ActivitiesByNursery($nurseryId: UUID!) {
    activitiesByNursery(nurseryId: $nurseryId) {
      id
      name
    }
  }
`;

export const getNurseryBookings = gql`
  query BookingsByNursery(
    $nurseryId: UUID!
    $startDate: Date
    $endDate: Date
    $filterAction: BookingFilterActionEnum
    $sortBy: String
  ) {
    bookingsByNursery(
      nurseryId: $nurseryId
      startDate: $startDate
      endDate: $endDate
      filterAction: $filterAction
      sortBy: $sortBy
    ) {
      id
      createdAt
      date
      sessionType
      customSessionType {
        id
        name
        startTime
        endTime
        availabilityType
        requestOnly
      }
      status
      type
      isPrimary
      nursery {
        id
        name
        nurseryRooms {
          id
          name
        }
        bookingHoldThresholdDays
        isBookingHoldEnabled
        isExtracurricularActivityEnabled
        isCustomSessionBookingsEnabled
        isExternallyManaged
        hubspotAccountId
        organization {
          name
        }
      }
      room {
        id
        name
      }
      child {
        id
        fullName
        birthDate
        status
        primaryNursery {
          name
          organization {
            name
          }
        }
        primaryNurseryRoom {
          id
          name
        }
        guardian {
          fullName
        }
      }
      additionalNotes
      payment {
        amount
        isRefunded
        status
        paidViaCsm
      }
      finalAmount
      paymentExemptionReason
      discount
      originalAmount
      finalAmount
    }
  }
`;

export const getBookingsByNurserySummary = gql`
  query getBookingsByNurserySummary($nurseryId: UUID!) {
    bookingsByNurserySummary(nurseryId: $nurseryId) {
      nurseryId
      totalConfirmedBookingsAllTime
      totalConfirmedBookingsThisMonth
    }
  }
`;

export const getTotalRemainingAvailability = gql`
  query totalRemainingAvailability($nurseryId: UUID!) {
    availabilityForNurseryAdminSummary(nurseryId: $nurseryId) {
      lastUpdatedAt
      totalRemainingAvailability
    }
  }
`;

export const getPendingRequestsSummary = gql`
  query getBookingsByNurserySummary($nurseryId: UUID!) {
    bookingsByNurserySummary(nurseryId: $nurseryId) {
      nurseryId
      totalPendingRequestsUpcoming
      totalPendingRequestsUpcomingUrgent
    }
  }
`;

export const getGuardiansByNursery = gql`
  query getGuardiansByNursery($nurseryId: UUID!) {
    guardiansByNursery(nurseryId: $nurseryId) {
      id
      fullName
      status
      children {
        id
        fullName
        status
        guardian {
          email
        }
        primaryNurseryRoom {
          id
          name
        }
        instantBook
        instantBookLastUpdated
        instantBookLastUpdatedBy {
          id
          fullName
        }
      }
    }
  }
`;

export const getGuardianById = gql`
  query getGuardianById($guardianId: UUID!, $nurseryId: UUID) {
    guardianById(guardianId: $guardianId, nurseryId: $nurseryId) {
      id
      dateJoined
      fullName
      phoneNumber
      status
      children {
        id
        birthDate
        bookingsConfirmedCount
        fullName
        status
        primaryNurseryRoom {
          id
          name
        }
        guardian {
          email
          phoneNumber
        }
        instantBook
        instantBookLastUpdated
        instantBookLastUpdatedBy {
          id
          fullName
        }
      }
    }
  }
`;

export const getGuardiansByNurserySummary = gql`
  query getGuardiansByNurserySummary($nurseryId: UUID!) {
    guardiansByNurserySummary(nurseryId: $nurseryId) {
      nurseryId
      guardiansCount
      childrenCount
    }
  }
`;
