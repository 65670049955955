import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import { BookingRequest } from 'models';
import { ChildStatusEnum } from 'graphql/constants';
import { getNurseryBookings } from 'graphql/queries';
import { multiSort } from 'utilities/arrays';

const sortBySessionType = (bookings, sortDirection) => bookings.sort((a, b) => {
  const compA = a?.customSessionType ? a.customSessionType?.startTime : a.sessionType;
  const compB = b?.customSessionType ? b.customSessionType?.startTime : b.sessionType;
  if (compA < compB) {
    return sortDirection === 'asc' ? -1 : 1;
  } else if (compA > compB) {
    return sortDirection === 'asc' ? 1 : -1;
  }
  return 0;
});

/**
 * Fetches bookings for a given nursery, and automatically converts them into models
 *
 * @param {String} nurseryId The id of the nursery to fetch bookings for
 */
export const useBookingsModels = (
  nurseryId,
  { filters = {}, sortBy, filterAction, startDate, endDate, addPendingChildFlow },
) => {
  const [bookings, setBookings] = useState([]);

  const result = useQuery(getNurseryBookings, {
    variables: {
      nurseryId,
      filterAction,
      startDate,
      endDate,
      sortBy: sortBy === 'sessionType_asc' || sortBy === 'sessionType_desc' ? null : sortBy,
    },
    skip: !nurseryId,
    errorPolicy: 'all',
  });
  const dir = sortBy.split('_')[sortBy.split('_').length - 1];

  const { data, loading, refetch } = result;

  const filteredBookings = useMemo(() => {
    // Return early if there are no filters applied
    if (!filters.roomId) return bookings;

    const filterByRoom = (booking) => {
      if (filters.roomId === null) return true;

      return booking.room?.id === filters.roomId;
    };

    return bookings.filter(filterByRoom);
  }, [bookings, filters.roomId]);

  useEffect(() => {
    if (!Array.isArray(data?.bookingsByNursery)) return;

    if (addPendingChildFlow) {
      const bookingsData = withPendingChildFlow(data?.bookingsByNursery);
      setBookings(bookingsData);
    } else {
      setBookings(data?.bookingsByNursery.map((b) => new BookingRequest(b)));
    }
  }, [data, addPendingChildFlow]);

  return {
    bookings:
      ['sessionType_asc', 'sessionType_desc'].includes(sortBy)
        ? sortBySessionType(filteredBookings, dir)
        : filteredBookings,
    data: bookings,
    loading,
    refetch,
  };
};

const withPendingChildFlow = (bookings) => {
  let bookingsWithExtras = [];
  let currentChild;

  const pendingChildren = bookings.filter((b) => b.child?.status === ChildStatusEnum.PENDING);
  const sortedRest = multiSort(
    bookings.filter((b) => b.child?.status !== ChildStatusEnum.PENDING),
    ['date', 'sessionType', 'createdAt'],
  );

  const sortedBookings = [...pendingChildren, ...sortedRest];

  sortedBookings.forEach((booking) => {
    if (booking.child !== currentChild) {
      currentChild = booking.child;
      if (booking.child?.status === ChildStatusEnum.PENDING) {
        const extra = {
          pendingChildFlow: true,
          child: {
            id: booking.child?.id,
            fullName: booking.child?.fullName,
            primaryNurseryRoom: {
              id: booking.child?.primaryNurseryRoom?.id,
            },
            guardian: {
              fullName: booking.child?.guardian?.fullName,
            },
          },
        };
        bookingsWithExtras.push(extra);
      }
    }
    bookingsWithExtras.push(new BookingRequest(booking));
  });

  return bookingsWithExtras;
};
