import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { AuthProvider } from 'app/auth';
import { ProfileProvider } from 'app/profile';
import { AnalyticsProvider } from 'app/analytics';
import { IntercomProvider } from 'app/intercom';
import { initializeAmplitude, AmplitudePageTracker } from './amplitude';
import apolloClient from 'graphql/client';
import { shouldFullstoryRecord } from 'utilities/fullstory';
import { FeatureFlagProvider } from './featureFlags';

import { Notifications } from './Notifications';
import Pages from './routes';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

initializeAmplitude();

// Fullstory should only be used in staging or production, no need to use in local
if (process.env.REACT_APP_FULLSTORY_ORG !== undefined) {
  FullStory.init({
    devMode: !shouldFullstoryRecord,
    orgId: process.env.REACT_APP_FULLSTORY_ORG,
  });
}

const isSproutDomain = window.location.host.match(/booksprout/i) !== null;
const pebbleDomain =
  process.env.REACT_APP_ENV === 'production'
    ? `https://nursery.bookpebble.co.uk${window.location.pathname}`
    : `https://nursery.${process.env.REACT_APP_ENV}.bookpebble.co.uk${window.location.pathname}`;

const App = () =>
  isSproutDomain ? (
    window.location.replace(pebbleDomain)
  ) : (
    <AuthProvider>
      <ApolloProvider client={apolloClient}>
        <Router>
          <ProfileProvider>
            <FeatureFlagProvider>
              <AnalyticsProvider>
                <IntercomProvider>
                  <Notifications />
                  <AmplitudePageTracker />

                  <Pages />
                </IntercomProvider>
              </AnalyticsProvider>
            </FeatureFlagProvider>
          </ProfileProvider>
        </Router>
      </ApolloProvider>
    </AuthProvider>
  );

export default App;
