import { Button } from 'ui';

import pebbleMedal from './pebbleMedal.svg';
import pebbleLogo from './pebbleLogoWhite.svg';
import styles from './Onboarding.module.scss';

export const Onboarding = () => (
  <div className={styles.container}>
    <div className={styles.dialog}>
      <img src={pebbleMedal} alt="Pebble logo in a grey circle" className={styles.pebbleMedal} />

      <h1>We're working on it…</h1>
      <p>
        Thanks for creating your Pebble login. We're either in the process of setting up your
        account, or your nursery is not registered with us yet. Please drop us a line to find out
        more.
      </p>

      <div className={styles.actions}>
        <Button expand href={mailLink} target="onboardingEmail">
          Contact the Pebble team
        </Button>
      </div>
      <a href="/logout" className={styles.logout} target="logout">
        Logout
      </a>
    </div>

    <a href={window.location.origin} className={styles.pebbleLogo}>
      <img src={pebbleLogo} alt="Pebble logo" />
    </a>
  </div>
);

const subject = encodeURI('New Nursery Sign-up');
const mailLink = `mailto:support@bookpebble.co.uk?subject=${subject}`;
