import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useProfile } from 'app/profile';
import { useAnalytics } from 'app/analytics';

export const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

// prettier-ignore
const load = () => {
  (function(){const w=window;const ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{const d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;const l=function(){const s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;const x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

const update = () => {
  window && window.Intercom && window.Intercom('update');
};

export const setUser = (id, name) => {
  if (global.window) {
    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: APP_ID,
      user_id: id,
      name,
    };
    update();
  }
};

export const trackIntercomEvent = (eventName, eventMetadata = {}) => {
  window && window.Intercom && window.Intercom('trackEvent', eventName, eventMetadata);
};

// Initializes Intercom
const boot = (options = {}) => {
  window &&
    window.Intercom &&
    window.Intercom('boot', { app_id: APP_ID, hide_default_launcher: true, ...options });
};

export const IntercomProvider = (props) => {
  const history = useHistory();
  const { profile } = useProfile();
  const { listen } = useAnalytics();

  if (typeof window !== 'undefined') {
    load();
    boot();
  }

  useEffect(() => {
    update();
    history.listen(update);
  }, [history]);

  useEffect(() => {
    if (profile) {
      setUser(profile.id, profile.fullName);
      const selectedNurseryId = history.location.search.split('=')[1];

      const foundNursery = profile.nurseries.find((nursery) => nursery.id === selectedNurseryId);

      setUser(selectedNurseryId, foundNursery?.name);
    }
  }, [history.location.search, profile]);

  const handleEventHappened = useCallback(trackIntercomEvent, []);
  listen(handleEventHappened);

  return props.children;
};
