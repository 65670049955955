import { useMemo } from 'react';
import classNames from 'classnames';

import styles from './Placeholder.module.scss';

export const Placeholder = ({ className, height, width }) => {
  const style = useMemo(() => ({ width, height }), [width, height]);

  return <div className={classNames(styles.container, className)} style={style} />;
};

Placeholder.defaultProps = {
  width: 64,
  height: 16,
};
