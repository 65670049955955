import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconInfoCircle = ({ className, color = 'currentColor', size = 18, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.8">
      <path
        d="M8.99935 0.665363C13.6017 0.665363 17.3327 4.39632 17.3327 8.9987C17.3327 13.6011 13.6017 17.332 8.99935 17.332C4.39698 17.332 0.666016 13.6011 0.666016 8.9987C0.666016 4.39632 4.39698 0.665363 8.99935 0.665363Z"
        stroke="#0E206F"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.332L9 8.16536"
        stroke="#0E206F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 5.66406H9.00833"
        stroke="#0E206F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
