import { useMemo, useState, useEffect } from 'react';

export const useWindowHeight = () => {
  // Use `100vh` as a default value to minimize content jumps after size detection
  const [height, setHeight] = useState('100vh');
  const boxStyle = useMemo(() => ({ height }), [height]);

  const handleResize = () => setHeight(window.innerHeight);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return { height, boxStyle };
};
