import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import { Button } from 'ui';
import { usePortal } from 'hooks';

import { trackAction, actions } from 'app/amplitude';

import styles from './ConfirmationPrompt.module.scss';

export const ConfirmationPrompt = ({
  cancelLabel,
  children,
  confirmationLabel,
  isConfirmationWorking,
  isConfirmationDisabled,
  isVisible,
  message,
  onCancel,
  onConfirm,
  showCancelLabel,
  title,
  dataTestId,
  className,
  bannerMessage,
}) => {
  const ref = useRef(null);
  const target = usePortal('confirmation-prompts');

  useEffect(() => {
    if (isVisible) ref.current.focus();
  }, [isVisible]);

  if (!isVisible) return null;

  const content = (
    <div
      role="dialog"
      aria-modal="true"
      {...(title ? { 'aria-labelledby': 'dialog-title' } : {})}
      {...(message ? { 'aria-describedby': 'dialog-description' } : {})}
      className={classNames(styles.container, isVisible && styles.visible)}
      data-testid={dataTestId}>
      <div className={classNames(styles.prompt, className)} ref={ref} tabIndex="0">
        {title && (
          <div className={styles.title} id="dialog-title">
            {title}
          </div>
        )}

        {bannerMessage && <div className={styles.bannerMessage}>{bannerMessage}</div>}

        <div className={styles.content}>
          {message !== undefined ? (
            <div className={styles.message} id="dialog-description">
              {message}
            </div>
          ) : (
            children
          )}
        </div>

        <div className={styles.actions}>
          {showCancelLabel && (
            <Button
              type="secondary"
              expand
              onClick={(e) => {
                onCancel(e);
                trackAction(actions.REQUEST_ONLY_CANCEL);
              }}>
              {cancelLabel}
            </Button>
          )}
          <Button
            expand
            onClick={(e) => {
              onConfirm(e);
              trackAction(actions.REQUEST_ONLY_CONFIRM);
            }}
            isWorking={isConfirmationWorking}
            disabled={isConfirmationDisabled}>
            {confirmationLabel}
          </Button>
        </div>
      </div>
    </div>
  );

  return createPortal(content, target);
};

ConfirmationPrompt.defaultProps = {
  confirmationLabel: 'Ok',
  cancelLabel: 'Cancel',
  dataTestId: 'confirmation-prompt',
  showCancelLabel: true,
  isVisible: false,
  title: '',
  onCancel: () => {},
  onClose: () => {},
};
