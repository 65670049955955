import { createElement } from 'react';

import classNames from 'classnames';

import styles from './elements.module.scss';

export const Container = ({ children, className, ...props }) => (
  <div {...props} className={classNames(styles.container, className)}>
    {children}
  </div>
);

export const Divider = ({ className, ...props }) => (
  <hr {...props} className={classNames(styles.divider, className)} />
);

export const ErrorMessage = ({ children, className, isVisible, ...props }) => (
  <div
    {...props}
    className={classNames(styles.errorMessage, className)}
    style={{ display: isVisible ? 'initial' : 'none' }}
  >
    {children}
  </div>
);

ErrorMessage.defaultProps = {
  isVisible: true,
};

export const Instructions = ({ children, className, ...props }) => (
  <p {...props} className={classNames(styles.instructions, className)}>
    {children}
  </p>
);

export const Heading = ({ element = 'div', children, className, ...props }) =>
  createElement(
    element,
    {
      ...props,
      className: classNames(styles.heading, className),
    },
    children,
  );

export const List = ({ children, className, ...props }) => (
  <ul {...props} className={classNames(styles.list, className)}>
    {children}
  </ul>
);

export const SubHeading = ({ children, className, ...props }) => (
  <div {...props} className={classNames(styles.subHeading, className)}>
    {children}
  </div>
);

export const Wrapper = ({ children, className, unpadded, ...props }) => (
  <div {...props} className={classNames(styles.wrapper, className, !unpadded && styles.padded)}>
    {children}
  </div>
);

Wrapper.defaultProps = {
  unpadded: false,
};

const elements = {
  Container,
  Divider,
  ErrorMessage,
  Heading,
  Instructions,
  List,
  Wrapper,
  SubHeading,
};

export default elements;
