import React from 'react';
import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconNetwork = ({ className, color = 'currentColor', size = 24, stroke, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M15.0078 18H3.35491C2.33073 18 1.5 17.1693 1.5 16.1451V5.67425C1.5 5.04836 1.81294 4.46799 2.33642 4.12091L8.16287 0.302987C8.78307 -0.100996 9.57966 -0.100996 10.1942 0.302987L16.0206 4.12091C16.5441 4.4623 16.857 5.04836 16.857 5.67425V16.1451C16.8627 17.1693 16.032 18 15.0078 18Z"
        fill="#4378FF"
      />
      <path
        d="M12.7612 6.375V14.385H11.1975L7.56372 9.84V14.385H5.59497V6.375H7.13622L10.77 10.8975V6.375H12.7612Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
