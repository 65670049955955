import { createPortal } from 'react-dom';
import classNames from 'classnames';

import { usePortal } from 'hooks';

import styles from './Blanket.module.scss';

export const Blanket = ({ onClick, isVisible }) => {
  const target = usePortal('modal-backdrops');

  const content = (
    <div className={classNames(styles.container, isVisible && styles.visible)} onClick={onClick} />
  );

  return createPortal(content, target);
};

Blanket.defaultProps = {
  isVisible: false,
};
