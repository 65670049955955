/**
 * Converts a string into a color deterministically.
 *
 * @param {String} str A string to convert
 * @returns {String} The color in hsl()
 */
export const stringToColor = (str, defaultColor = 'transparent') => {
  if (!str || typeof str !== 'string') return defaultColor;

  const value = str.split('').reduce((val, letter) => {
    const letterVal = (((letter.toUpperCase().charCodeAt(0) - 65) * 100) / 26) * 360;

    return val + letterVal;
  }, 0);
  const hue = value % 360;

  return `hsl(${Math.floor(hue)}, 90%, 50%)`;
};

export const electro500 = '#4378ff';
export const electro600 = '#2d5fe8';
export const grey500 = '#7f839b';
export const orange500 = '#f99834';
