import { useEffect } from 'react';

/**
 * Hook for handling keypresses inside of compoennts.
 *
 * @param {key} key The key name to active the callback for
 * @param {callback} callback The function to be activated when the user hits the correct key
 */
export const useKeyPress = (key, callback) => {
  useEffect(() => {
    function onKeyup(e) {
      if (e.key === key) callback();
    }

    window.addEventListener('keyup', onKeyup);

    return () => window.removeEventListener('keyup', onKeyup);
  }, [key, callback]);
};
