import BaseModel from './BaseModel';

export class NurseryAdminProfile extends BaseModel {
  static defaultFields = {
    id: null,
    emailAddress: '',
    fullName: '',
    nurseries: [],
  };
}
