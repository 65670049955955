import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../../app/auth';

import Page from 'pages/elements';
import { PublicPageWithIllustration } from 'layouts';
import { Button, Input } from 'ui';
import { passwordMatcher } from 'utilities/regexes';

import styles from './ChangePassword.module.scss';

export const ChangePassword = () => {
  const history = useHistory();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const authContext = useContext(AuthContext);

  const handleChangePassword = async (e) => {
    e.preventDefault();

    setFormErrors({});

    const errors = {};

    if (!passwordMatcher.test(newPassword)) {
      errors.newPassword = true;
    }

    if (newPassword !== confirmPassword) {
      errors.confirmPassword = true;
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      try {
        await authContext.changePassword(newPassword);
        history.push('/home');
      } catch (error) {
        console.log('[ChangePassword] Change Password Failed: ', error);
      }
    }
  };

  return (
    <PublicPageWithIllustration>
      <div className={styles.container}>
        <form onSubmit={handleChangePassword} role="main" className={styles.form}>
          <h1>Welcome</h1>

          <p>
            You're almost ready to start using Pebble! Please type in a unique password and click on
            update to continue.
          </p>

          <div className={styles.inputWrapper}>
            <label className={styles.label}>
              New password
              <small>Min 8 characters, with uppercase and lowercase and a number</small>
              <Input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                hasError={'newPassword' in formErrors}
              />
            </label>

            <Page.ErrorMessage isVisible={'newPassword' in formErrors} data-testid="login__error">
              This password does not meet the requirements.
            </Page.ErrorMessage>

            <label className={styles.label}>
              Confirm password
              <Input
                type="password"
                placeholder="Re-type Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                hasError={'confirmPassword' in formErrors}
              />
            </label>
            <Page.ErrorMessage
              isVisible={'confirmPassword' in formErrors}
              data-testid="login__error"
            >
              This password doesn’t match the first one. Please try again.
            </Page.ErrorMessage>
          </div>

          <Button className={styles.changePasswordButton} expand>
            Update password
          </Button>
        </form>
      </div>
    </PublicPageWithIllustration>
  );
};
