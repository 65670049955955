import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconGuardians = ({
  className,
  color = 'currentColor',
  size = 24,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3336 14V12.6667C12.3336 11.9594 12.0527 11.2811 11.5526 10.781C11.0525 10.281 10.3742 10 9.66695 10H4.33365C3.6264 10 2.94813 10.281 2.44804 10.781C1.94794 11.2811 1.66699 11.9594 1.66699 12.6667V14"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.99966 7.33331C8.47241 7.33331 9.66631 6.1394 9.66631 4.66665C9.66631 3.1939 8.47241 2 6.99966 2C5.52691 2 4.33301 3.1939 4.33301 4.66665C4.33301 6.1394 5.52691 7.33331 6.99966 7.33331Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.333 13.9993V12.6659C16.3326 12.0751 16.1359 11.5011 15.7739 11.0341C15.4119 10.5672 14.9051 10.2336 14.333 10.0859"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.668 2.08594C12.2416 2.2328 12.75 2.5664 13.1131 3.03414C13.4761 3.50188 13.6732 4.07715 13.6732 4.66926C13.6732 5.26137 13.4761 5.83664 13.1131 6.30438C12.75 6.77211 12.2416 7.10571 11.668 7.25258"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
