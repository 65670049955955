import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconLightningBolt = ({
  className,
  color = 'currentColor',
  size = 24,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.65305 2.64297C8.71755 2.127 8.04946 1.86762 7.7489 2.29194L3.22563 8.67774C2.99108 9.00887 3.22786 9.46675 3.63364 9.46675H8.16675L7.68044 13.3572C7.61595 13.8732 8.28403 14.1325 8.58459 13.7082L13.1079 7.32242C13.3424 6.99129 13.1056 6.53341 12.6999 6.53341H8.16675L8.65305 2.64297Z"
      fill={color}
    />
  </svg>
);
