import classNames from 'classnames';

import { IconTimes } from 'icons';

import styles from './CloseButton.module.scss';

export const CloseButton = ({ className, iconSize, ...buttonProps }) => (
  <button {...buttonProps} className={classNames(styles.container, className)} aria-label="Close">
    <IconTimes size={iconSize} className={styles.closeIcon} />
  </button>
);

CloseButton.defaultProps = {
  iconSize: 20,
};
