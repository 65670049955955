import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({ label, childId, isChecked, onToggle }) => {
  return (
    <div className="container">
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          name={childId}
          id={childId}
          checked={isChecked}
          onChange={onToggle}
        />
        <label className="label" htmlFor={childId}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
      <span className="toggle-switch-label">{label}</span>
    </div>
  );
};

export default ToggleSwitch;
