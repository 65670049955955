import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { stringify } from 'query-string';

import { useProfile } from 'app/profile';
import { useQueryParams } from 'hooks';
import Select from 'react-select';
import { trackAction, actions } from 'app/amplitude';

export const NurserySwitcher = ({ onChange, value, ...props }) => {
  const { profile, nursery } = useProfile();
  const history = useHistory();
  const queryParams = useQueryParams();

  const makeUrlWithNurseryId = useCallback(
    (nurseryId) => {
      const newQueryParams = stringify({
        ...queryParams,
        nurseryId,
      });

      // Convert "short" paths (i.e., `/home/{nurseryId}) to move the nurseryId into search params
      const path = '/' + history.location.pathname.split('/')[1];

      return [path, newQueryParams].join('?');
    },
    [queryParams, history],
  );

  const handleDropdownChange = (e) => {
    history.push(makeUrlWithNurseryId(e.value));
  };

  return (
    <Select
      styles={{
        container: (provided) => ({
          ...provided,
          width: 300,
          alignSelf: 'flex-start',
        }),
        control: (provided) => ({
          ...provided,
          fontFamily: 'Mulish',
          border: 'solid 2px #d5e1ff',
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 999,
        }),
        menu: (provided, state) => ({
          ...provided,
          color: state.selectProps.menuColor,
          borderRadius: 10,
          width: '100%',
          boxShadow: '0 5px 5px 0 rgba(0, 0, 0, 0.1)',
        }),
        option: (provided, state) => ({
          ...provided,
          fontFamily: 'Mulish',
          fontSize: '0.9rem',
          color: '#0e206f',
          backgroundColor: state.isFocused ? '#f0f4ff' : 'white',
          fontWeight: state.isSelected || state.isFocused ? 'bold' : 'regular',
          paddingLeft: 20,
          paddingRight: 20,
        }),
      }}
      openMenuOnClick={false}
      defaultValue={{ value: nursery.id, label: nursery.name }}
      isDisabled={profile.nurseries.length === 1}
      noOptionsMessage={() => 'No match - please try again'}
      options={profile.nurseries?.map((nursery) => ({ value: nursery.id, label: nursery.name }))}
      onChange={(e) => {
        handleDropdownChange(e);
        trackAction(actions.CHANGE_NURSERY);
      }}
    />
  );
};
