import classNames from 'classnames';
import { ErrorMessage } from 'pages/elements';
import { Input } from 'ui';

import styles from './DobInput.module.scss';

export const DobInput = ({
  child,
  childFormData,
  childFormErrors,
  originalBirthDate,
  setChildFormData,
}) => (
  <div className={styles.dobWrapper}>
    <Input
      id={`DoB.${child.id}`}
      mask="99 | 99 | 9999"
      maskPlaceholder="DD | MM | YYYY"
      className={classNames(styles.dobInput, !!childFormErrors?.birthDate && styles.hasErrors)}
      placeholder="DD | MM | YYYY"
      aria-label="DD | MM | YYYY"
      value={childFormData?.birthDate || originalBirthDate || ''}
      onChange={(e) =>
        setChildFormData({
          id: child.id,
          birthDate: e.target.value,
        })
      }
      name="childName"
    />
    <ErrorMessage className={styles.inputErrorMessage} isVisible={!!childFormErrors?.birthDate}>
      Please enter a valid date
    </ErrorMessage>
  </div>
);
