import { BookingStatusEnum, BookingRejectionEnum } from 'graphql/constants';
import { ConfirmationPrompt } from 'ui';

import { BookingDeclinePromptContent } from '../BookingDeclinePromptContent';
import { trackAction, actions } from 'app/amplitude';

import styles from './BookingPromptGroup.module.scss';

const reasonOptions = [
  { value: BookingRejectionEnum.STAFF_ABSENCE, label: 'Staff absence' },
  {
    value: BookingRejectionEnum.CHILD_STAFF_RATIO,
    label: 'Child-to-staff ratio',
  },
  {
    value: BookingRejectionEnum.ROOM_AT_CAPACITY,
    label: 'Overall room capacity',
  },
  {
    value: BookingRejectionEnum.LATE_ON_PAYMENTS,
    label: 'Parent is late on payments',
  },
  { value: BookingRejectionEnum.OTHER, label: 'Other' },
];

export const BookingPromptGroup = ({
  booking,
  newStatus,
  flowType,
  isWorking,
  showConfirmationModal,
  showErrorModal,
  handleConfirmationPromptSave,
  handleModalClose,
  declineDetails,
  setDeclineDetails,
  declineReason,
  setDeclineReason,
}) => {
  return (
    <>
      <ConfirmationPrompt
        dataTestId="decline-booking-prompt"
        className={styles.declinePrompt}
        confirmationLabel="Decline"
        isVisible={
          showConfirmationModal && newStatus === BookingStatusEnum.REJECTED && !showErrorModal
        }
        message={
          <BookingDeclinePromptContent
            booking={booking}
            reasonOptions={reasonOptions}
            declineDetails={declineDetails}
            setDeclineDetails={setDeclineDetails}
            declineReason={declineReason}
            setDeclineReason={setDeclineReason}
          />
        }
        onCancel={(e) => {
          setDeclineReason();
          setDeclineDetails();
          handleModalClose(e);
          trackAction(actions.REQUEST_DECLINE_CANCEL);
        }}
        onConfirm={(e) => {
          handleConfirmationPromptSave(e);
          trackAction(actions.REQUEST_DECLINE_CONFIRMED);
        }}
        isConfirmationWorking={isWorking}
        isConfirmationDisabled={
          !declineReason || (declineReason === BookingRejectionEnum.OTHER && !declineDetails)
        }
        title="Are you sure you want to decline this booking request?"
      />

      <ConfirmationPrompt
        confirmationLabel="Close"
        showCancelLabel={false}
        isVisible={showErrorModal && flowType === 'booking'}
        message="Something didn't work while confirming/declining the booking. Please close and try again."
        onConfirm={handleModalClose}
        title="Oops! Something went wrong."
      />

      <ConfirmationPrompt
        confirmationLabel="OK, I understand"
        isVisible={showErrorModal && flowType === 'payment'}
        message="We're sorry but it's taken too long to accept the booking and the payment hold has expired. Please make sure you charge this booking to the parent's monthly invoice."
        onConfirm={handleModalClose}
        showCancelLabel={false}
        title="The payment has expired"
      />
    </>
  );
};
