import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';

import { useProfile } from 'app/profile';
import { IconCheck } from 'icons';
import { Table } from 'ui';

import { PendingChildTableRow } from '../PendingChildTableRow';
import { OutstandingBookingTableRow } from '../OutstandingBookingTableRow/index';

import styles from './OutstandingBookingsList.module.scss';

const EmptyState = ({ onClose }) => (
  <div className={styles.loadingMessage}>
    <p>There are no new booking requests.</p>
  </div>
);

const RoomSwitchToolTipLink = ({ nurseryId, isExternallyManaged }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'top-start',
    });

  return (
    <>
      {!isExternallyManaged && (
        <NavLink
          to={`/guardians?nuseryId=${nurseryId}`}
          className={styles.link}
          ref={setTriggerRef}>
          Any room changes lately?
        </NavLink>
      )}

      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: styles.tooltipContainer })}>
          You can easily change the room inside a child's profile
          <div {...getArrowProps({ className: styles.tooltipArrow })} />
        </div>
      )}
    </>
  );
};

export const OutstandingBookingsList = ({
  allDone,
  bookings,
  isWorking,
  loading,
  nurseryRooms,
  onConfirm,
  onDecline,
  onDelay,
  onChildApprove,
  onChildReject,
  paymentsEnabled,
}) => {
  const { nursery } = useProfile();

  const requiresAttention = !loading && bookings.length > 0;

  const roomOptions = useMemo(() => {
    const options = [
      { value: null, label: 'Select a room' },
      ...nurseryRooms.map((room) => ({
        value: room.id,
        label: room.name,
      })),
    ];

    return options;
  }, [nurseryRooms]);

  const headerTitles = [
    'Child',
    'Booking date',
    'Session',
    'Received',
    'Room',
    ...(paymentsEnabled ? ['Payment'] : []),
    'Action',
  ];
  const tableRows = useMemo(
    () =>
      bookings.map((booking) => {
        if (booking.pendingChildFlow) {
          return (
            <PendingChildTableRow
              booking={booking}
              roomOptions={roomOptions}
              key={booking.child?.id}
              onChildApprove={onChildApprove}
              onChildReject={onChildReject}
              isExternallyManaged={nursery.isExternallyManaged}
              paymentsEnabled={paymentsEnabled}
            />
          );
        } else {
          return (
            <OutstandingBookingTableRow
              key={booking.id}
              booking={booking}
              isWorking={isWorking}
              onConfirm={onConfirm}
              onDecline={onDecline}
              onDelay={onDelay}
              paymentsEnabled={paymentsEnabled}
              isExternallyManaged={nursery.isExternallyManaged}
              roomOptions={roomOptions}
            />
          );
        }
      }),
    [
      bookings,
      roomOptions,
      onChildApprove,
      onChildReject,
      paymentsEnabled,
      nursery.isExternallyManaged,
      isWorking,
      onConfirm,
      onDecline,
      onDelay,
    ],
  );

  let content = (
    <div className={styles.bookingsTableWrapper}>
      <Table headerTitles={headerTitles} rows={tableRows} className={styles.bookingsTable} />
    </div>
  );

  if (bookings.length === 0) {
    content = <EmptyState />;
  }

  if (allDone) {
    content = (
      <div className={styles.emptyState}>
        <div className={styles.emptyStateIconWrapper}>
          <IconCheck size={18} className={styles.emptyStateIcon} />
        </div>

        <p>All done for now!</p>
      </div>
    );
  }

  return (
    <section className={styles.container}>
      <div className={styles.headingContainer}>
        <h2 className={styles.heading}>Outstanding booking requests</h2>
        <RoomSwitchToolTipLink
          nurseryId={nursery.id}
          isExternallyManaged={nursery.isExternallyManaged}
        />
      </div>

      <div
        className={classNames(
          styles.bookings,
          allDone && styles.allDone,
          requiresAttention && styles.requiresAttention,
        )}>
        <div className={styles.bookingsLeftStripe} />

        {loading ? (
          <div className={styles.loadingMessage}>
            <p>Loading your booking requests…</p>
          </div>
        ) : (
          content
        )}
      </div>
    </section>
  );
};
