import { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-hot-toast';

import { useAnalytics, ANALYTICS_EVENTS } from 'app/analytics';
import { useProfile } from 'app/profile';
import { Blanket, Dropdown, ToggleToken } from 'ui';
import {
  BookingFilterActionEnum,
  BookingRejectionEnum,
  BookingStatusEnum,
  ChildStatusEnum,
} from 'graphql/constants';
import { editBookingRequest } from 'graphql/mutations';
import { mutationHasErrors } from 'graphql/utils';
import { useChildUpdate } from 'hooks';
import { IconCheckCircle, IconCrossCircle } from 'icons';
import { DefaultAppPage } from 'layouts';
import { useBookingsModels } from 'models';

import { BookingsTable } from './components/BookingsTable';
import { BookingPromptGroup } from './components/BookingPromptGroup';
import { RoomPromptGroup } from './components/RoomPromptGroup';
import { ChildPromptGroup } from './components/ChildPromptGroup';
import { AvailabilityPrompt } from './components/AvailabilityPrompt';
import { OnHoldStatusPicker } from './components/OnHoldStatusPicker';
import { OutstandingBookingsList } from './components/OutstandingBookingsList';
import { trackAction, actions } from 'app/amplitude';
import styles from './Bookings.module.scss';

export const Bookings = ({ defaultSortBy, filterOptions, defaultTypeFilter }) => {
  const { nursery } = useProfile();
  const { trackEvent } = useAnalytics();
  const [typeFilter, setTypeFilter] = useState(defaultTypeFilter);
  const [roomFilter, setRoomFilter] = useState(null);
  const [nurseryRooms, setNurseryRooms] = useState([]);
  const [sortBy, setSortBy] = useState(defaultSortBy);

  const {
    bookings: outstandingBookings,
    loading: outstandingLoading,
    refetch: outstandingRefetch,
  } = useBookingsModels(nursery.id, {
    filterAction: BookingFilterActionEnum.NEED_ACTION,
    sortBy: 'child_asc',
    addPendingChildFlow: true,
  });

  const [isAllOutstandingDone, setIsAllOutstandingDone] = useState();
  useEffect(() => {
    if (outstandingLoading) {
      return;
    }
    if (outstandingBookings.length > 0) {
      setIsAllOutstandingDone(false);
    }
    if (isAllOutstandingDone === false && outstandingBookings.length === 0) {
      setIsAllOutstandingDone(true);
    }
  }, [isAllOutstandingDone, outstandingBookings, outstandingLoading]);

  const { bookings, loading, refetch } = useBookingsModels(nursery.id, {
    filters: {
      roomId: roomFilter,
    },
    filterAction: typeFilter,
    sortBy,
  });

  /**
   * Generate a list of room options based on the currently selected nursery
   */
  const roomOptions = useMemo(() => {
    setRoomFilter(null);
    setNurseryRooms([]);
    const nurseryRooms = nursery?.nurseryRooms ?? [];

    const options = [
      { value: null, label: 'All rooms' },
      ...nurseryRooms.map((room) => ({
        value: room.id,
        label: room.name,
      })),
    ];
    setNurseryRooms(nurseryRooms);

    return options;
  }, [nursery?.nurseryRooms]);

  const [newBookingRoomId, setNewBookingRoomId] = useState();
  const [newStatus, setNewStatus] = useState();
  const [declineReason, setDeclineReason] = useState();
  const [declineDetails, setDeclineDetails] = useState();

  const [bookingToEdit, setBookingToEdit] = useState();

  const [childInfoToEdit, setChildInfoToEdit] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [flowType, setFlowType] = useState();
  const [editBooking, { error: editBookingError }] = useMutation(editBookingRequest);
  const [isWorking, setIsWorking] = useState(false);
  const [deductAvailability, setDeductAvailability] = useState(null);

  const handleConfirmClick =
    ({ booking, roomId }) =>
    async (e) => {
      if (e) e.preventDefault();
      const shouldInitiateEdit =
        booking?.customSessionType?.requestOnly && !booking?.nursery?.isExternallyManaged;
      const bookingOptions = {
        booking,
        bookingStatus: BookingStatusEnum.CONFIRMED,
        ...(booking.room.id !== roomId && { roomId }),
      };
      if (shouldInitiateEdit) {
        handleInitiateEdit({
          ...bookingOptions,
          showDeductAvailabilityModal: true,
        })(e);
      } else {
        await handleEditBooking(bookingOptions);
      }
      trackEvent(ANALYTICS_EVENTS.BOOKING.REQUEST_ACCEPTED);
      trackAction(actions.REQUEST_APPROVE);
    };

  const handleDeclineClick =
    ({ booking, roomId }) =>
    (e) => {
      trackEvent(ANALYTICS_EVENTS.BOOKING.REQUEST_REJECTED);
      trackAction(actions.REQUEST_DECLINE);

      handleInitiateEdit({
        booking,
        bookingStatus: BookingStatusEnum.REJECTED,
        roomId,
      })(e);
    };

  const handleDelayClick =
    ({ booking }) =>
    async (e) => {
      e.preventDefault();

      await handleEditBooking({
        booking,
        bookingStatus: BookingStatusEnum.ON_HOLD,
        roomId: booking?.room?.id,
      });
      trackAction(actions.REQUEST_HOLD);
    };

  const handleInitiateEdit =
    ({ booking, bookingStatus, roomId, showDeductAvailabilityModal }) =>
    (e) => {
      if (e) e.preventDefault();

      setNewStatus(bookingStatus);
      setBookingToEdit(booking);
      setNewBookingRoomId(roomId);
      setFlowType(showDeductAvailabilityModal ? 'deductAvailability' : 'booking');
      setShowConfirmationModal(true);
    };

  const showBookingStatusChangedToast = (childName, newStatus) => {
    if (newStatus === BookingStatusEnum.CONFIRMED) {
      toast(
        <div className={styles.toastContainer}>
          <IconCheckCircle className={styles.checkIcon} size={48} />
          <div className={styles.toastContent}>
            <strong>{childName}'s booking is confirmed</strong>
            The parent has been notified that their booking request was accepted.
          </div>
        </div>,
      );
    } else if (newStatus === BookingStatusEnum.REJECTED) {
      toast(
        <div className={styles.toastContainer}>
          <IconCrossCircle className={styles.checkIcon} size={48} />
          <div className={styles.toastContent}>
            <strong>{childName}'s booking has been declined</strong>
            The parent has been notified that their booking request was declined.
          </div>
        </div>,
      );
    } else if (newStatus === BookingStatusEnum.ON_HOLD) {
      toast(
        <div className={styles.toastContainer}>
          <IconCheckCircle className={styles.checkIcon} size={48} />
          <div className={styles.toastContent}>
            <strong>{childName}'s booking has been put on hold</strong>
            This booking has been moved to the 'On Hold' booking listing for review.
          </div>
        </div>,
      );
    }
  };

  const showChildStatusChangedToast = (childName, newStatus) => {
    if (newStatus === ChildStatusEnum.APPROVED) {
      toast(
        <div className={styles.toastContainer}>
          <IconCheckCircle className={styles.checkIcon} size={48} />
          <div className={styles.toastContent}>
            <strong>{childName} is verified</strong>
            They are confirmed as a member of your nursery
          </div>
        </div>,
      );
    } else if (newStatus === ChildStatusEnum.UNRECOGNIZED) {
      toast(
        <div className={styles.toastContainer}>
          <IconCrossCircle className={styles.checkIcon} size={48} />
          <div className={styles.toastContent}>
            <strong>{childName} is not recognised</strong>
            They will not be able to book at your nursery
          </div>
        </div>,
      );
    }
  };

  const showRoomChangeToast = (childName) => {
    toast(
      <div className={styles.toastContainer}>
        <IconCheckCircle className={styles.checkIcon} size={48} />
        <div className={styles.toastContent}>
          <strong>{childName}'s room has been updated</strong>
          Availability planner has been updated to reflect room occupancy.
        </div>
      </div>,
    );
  };

  const handleEditBooking = async ({ booking, bookingStatus, roomId, deductAvailabilityFlag }) => {
    setIsWorking(true);
    let hasErrors = false;
    let hasPaymentErrors = false;

    try {
      const editBookingVariables = {
        id: booking.id,
        status: bookingStatus,
        deductAvailability: deductAvailabilityFlag,
      };

      if (roomId) editBookingVariables.roomId = roomId;

      if (bookingStatus === BookingStatusEnum.REJECTED) {
        // Add decline reason to payload if rejecting
        editBookingVariables.rejectionReason = declineReason;

        trackEvent(ANALYTICS_EVENTS.BOOKING.REQUEST_REJECTED_CODE, declineReason);

        if (declineReason === BookingRejectionEnum.OTHER) {
          editBookingVariables.rejectionDetails = declineDetails;
        }
      }

      const { data } = await editBooking({
        variables: editBookingVariables,
      });

      hasErrors = mutationHasErrors({
        innerData: data?.bookingUpdate,
        error: editBookingError,
      });
      hasPaymentErrors = data?.bookingUpdate?.errors?.some(
        (error) => error.code === 'PAYMENT_NOT_CAPTURED',
      );
    } catch {
      hasErrors = true;
    }
    setIsWorking(false);

    if (hasErrors && !hasPaymentErrors) {
      setFlowType('booking');
      setShowErrorModal(true);
    } else {
      setDeclineReason();
      setDeclineDetails();

      outstandingRefetch();
      refetch();

      if (flowType === 'changeRoom') {
        showRoomChangeToast(booking.child.fullName);
      } else {
        showBookingStatusChangedToast(booking.child.fullName, bookingStatus);
      }

      if (hasPaymentErrors) {
        setFlowType('payment');
        setShowErrorModal(true);
      }
    }

    setShowConfirmationModal(false);
    setNewBookingRoomId();
    setNewStatus();
    setDeductAvailability(null);
  };

  const handleRoomChange = async (booking, roomId) => {
    setNewBookingRoomId(roomId);
    setFlowType('changeRoom');
    setShowConfirmationModal(true);
    setBookingToEdit(booking);
  };

  const handleConfirmRoomChange = async () => {
    await handleEditBooking({
      booking: bookingToEdit,
      bookingStatus: bookingToEdit.status,
      roomId: newBookingRoomId,
    });
    setFlowType(null);
  };

  const handleEditBookingWithState = async (e) => {
    e.preventDefault();
    await handleEditBooking({
      booking: bookingToEdit,
      bookingStatus: newStatus,
      ...(newBookingRoomId ? { roomId: newBookingRoomId } : {}),
      deductAvailabilityFlag: deductAvailability,
    });
  };

  const handleModalClose = (e) => {
    e.preventDefault();

    setShowConfirmationModal(false);
    setShowErrorModal(false);
    setFlowType();
    setNewStatus();
    setChildInfoToEdit();
  };

  const {
    approve: approveChild,
    unrecognise,
    data: childUpdateData,
    error: childUpdateError,
    loading: childUpdateLoading,
  } = useChildUpdate();

  const handleApproveChildAccount =
    ({ childId, roomId }) =>
    (e) => {
      e.preventDefault();

      trackEvent(ANALYTICS_EVENTS.BOOKING.NEW_CHILD_APPROVED);
      approveChild({ childId, roomId });
    };

  const handleRejectChildAccount =
    ({ childId, roomId }) =>
    (e) => {
      e.preventDefault();

      setFlowType('child');
      setChildInfoToEdit({ childId, roomId });
      setShowConfirmationModal(true);
    };

  useEffect(() => {
    if (childUpdateLoading) {
      setIsWorking(true);
      return;
    }

    setIsWorking(false);
    setShowConfirmationModal(false);
    setChildInfoToEdit();

    if (
      mutationHasErrors({
        innerData: childUpdateData?.childUpdateByNursery,
        error: childUpdateError,
      })
    ) {
      setFlowType('child');
      setShowErrorModal(true);
    } else {
      if (childUpdateData === undefined) {
        return;
      }

      showChildStatusChangedToast(
        childUpdateData.childUpdateByNursery?.child?.fullName,
        childUpdateData.childUpdateByNursery?.child?.status,
      );

      if (childUpdateData.childUpdateByNursery?.child?.status === ChildStatusEnum.UNRECOGNIZED) {
        // refetch to hide the child row and the corresponding pending booking rows
        outstandingRefetch();
      }
    }
  }, [outstandingRefetch, childUpdateData, childUpdateError, childUpdateLoading]);

  return (
    <DefaultAppPage
      title="Bookings"
      intro="Here are all the Pebble bookings for your center. You can filter by date, booking status or room. As a reminder, it’s important to respond to booking requests as soon as possible."
      useNurserySwitcher
      contentClass={styles.container}>
      <main>
        <OutstandingBookingsList
          allDone={isAllOutstandingDone}
          bookings={outstandingBookings}
          isWorking={isWorking}
          loading={outstandingLoading}
          nurseryRooms={nurseryRooms}
          onDecline={({ booking, roomId }) => handleDeclineClick({ booking, roomId })}
          onConfirm={({ booking, roomId }) => handleConfirmClick({ booking, roomId })}
          onDelay={({ booking }) => handleDelayClick({ booking })}
          onChildApprove={handleApproveChildAccount}
          onChildReject={handleRejectChildAccount}
          paymentsEnabled={nursery?.paymentsEnabled || false}
          externallyManaged={nursery?.isExternallyManaged || false}
        />

        <nav className={styles.controlBar} aria-label="Bookings navigation">
          <div className={styles.filterControls}>
            Show:
            {filterOptions.map(({ label, value, isEnabled }) => {
              if (typeof isEnabled === 'function' && !isEnabled(nursery)) {
                return null;
              }

              return (
                <ToggleToken
                  key={value}
                  pressed={typeFilter === value}
                  onClick={() => {
                    setTypeFilter(value);
                    trackAction(actions.BOOKINGS_TABLE_FILTER);
                  }}
                  className={styles.toggleToken}>
                  {label}
                </ToggleToken>
              );
            })}
          </div>

          <Dropdown
            className={styles.roomDropdown}
            onChange={(room) => {
              setRoomFilter(room.value);
              trackAction(actions.BOOKINGS_TABLE_ROOM_FILTER);
            }}
            options={roomOptions}
            value={roomFilter}
            noValueText="All rooms"
            size="small"
          />
        </nav>
        <section className={styles.bookings}>
          <BookingsTable
            bookings={bookings}
            loading={loading}
            onSortChange={setSortBy}
            paymentsEnabled={nursery?.paymentsEnabled || false}
            sortBy={sortBy}
            handleRoomChange={handleRoomChange}
            statusActions={(booking) => {
              const showStatusDropdown =
                typeFilter === BookingFilterActionEnum.ON_HOLD &&
                booking.status === BookingStatusEnum.ON_HOLD;

              if (!showStatusDropdown) return null;

              return (
                <div className={styles.statusPickerWrapper}>
                  <OnHoldStatusPicker
                    onStatusPick={(newStatus) => {
                      if (newStatus === BookingStatusEnum.REJECTED) {
                        setBookingToEdit(booking);

                        handleDeclineClick({
                          booking,
                          roomId: booking.roomId,
                        })();
                      } else {
                        handleConfirmClick({ booking, roomId: booking.roomId })();
                      }
                    }}
                  />
                </div>
              );
            }}
          />
          {!loading && bookings.length > 0 && (
            <p className={styles.centreText}>You've reached the end of the list.</p>
          )}
        </section>

        {!loading && bookings.length === 0 && (
          <section className={styles.emptyState}>
            <p>You don’t have any bookings yet. Once you do, they will appear here.</p>
          </section>
        )}
      </main>

      <BookingPromptGroup
        booking={bookingToEdit}
        newStatus={newStatus}
        isWorking={isWorking}
        flowType={flowType}
        showConfirmationModal={showConfirmationModal && flowType === 'booking'}
        showErrorModal={showErrorModal}
        handleConfirmationPromptSave={handleEditBookingWithState}
        handleModalClose={handleModalClose}
        declineDetails={declineDetails}
        setDeclineDetails={setDeclineDetails}
        declineReason={declineReason}
        setDeclineReason={setDeclineReason}
      />

      <RoomPromptGroup
        booking={bookingToEdit}
        roomId={newBookingRoomId}
        showConfirmationModal={showConfirmationModal && flowType === 'changeRoom'}
        showErrorModal={showErrorModal && flowType === 'changeRoom'}
        setNewBookingRoomId={setNewBookingRoomId}
        handleConfirmRoomChange={handleConfirmRoomChange}
        handleModalClose={handleModalClose}
      />

      <ChildPromptGroup
        childInfoToEdit={childInfoToEdit}
        isWorking={isWorking}
        showConfirmationModal={showConfirmationModal && flowType === 'child'}
        showErrorModal={showErrorModal && flowType === 'child'}
        handleModalClose={handleModalClose}
        unrecognise={unrecognise}
      />

      <AvailabilityPrompt
        isWorking={isWorking}
        showConfirmationModal={showConfirmationModal && flowType === 'deductAvailability'}
        onConfirm={handleEditBookingWithState}
        handleModalClose={handleModalClose}
        booking={bookingToEdit}
        deductAvailability={deductAvailability}
        setDeductAvailability={setDeductAvailability}
      />

      <Blanket isVisible={showConfirmationModal || showErrorModal} onClick={handleModalClose} />
    </DefaultAppPage>
  );
};

const defaultFilterOptions = [
  { value: BookingFilterActionEnum.ALL, label: 'All' },
  { value: BookingFilterActionEnum.UPCOMING, label: 'Upcoming' },
  {
    value: BookingFilterActionEnum.ON_HOLD,
    label: 'On hold',
    isEnabled: (nursery) => nursery.isBookingHoldEnabled && nursery.bookingHoldThresholdDays > 0,
  },
  { value: BookingFilterActionEnum.PAST, label: 'Past' },
];

Bookings.defaultProps = {
  filterOptions: defaultFilterOptions,
  defaultTypeFilter: defaultFilterOptions[1].value,
  defaultSortBy: 'date_asc',
};
