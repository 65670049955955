import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { getBookingsByNurserySummary } from 'graphql/queries';

import { StatTile } from '../../components';

export const BookingSummaryTile = ({ nurseryId }) => {
  const { data = {}, loading } = useQuery(getBookingsByNurserySummary, {
    variables: {
      nurseryId,
    },
  });

  const { totalConfirmedBookingsAllTime, totalConfirmedBookingsThisMonth } = useMemo(() => {
    return {
      totalConfirmedBookingsAllTime: data?.bookingsByNurserySummary?.totalConfirmedBookingsAllTime,
      totalConfirmedBookingsThisMonth:
        data?.bookingsByNurserySummary?.totalConfirmedBookingsThisMonth,
    };
  }, [data.bookingsByNurserySummary]);

  return (
    <StatTile
      loading={loading}
      title="Pebble bookings"
      number={totalConfirmedBookingsAllTime}
      footer="Includes all confirmed bookings made through Pebble."
    >
      <strong>{totalConfirmedBookingsThisMonth}</strong> this month
    </StatTile>
  );
};
