import { useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { addDays, format, isPast, isToday, parseISO } from 'date-fns';

import { getNurseryAvailability } from 'graphql/queries';
import { modifyAvailability } from 'graphql/mutations';
import { SessionAvailabilityWorksheet } from 'models';
import { multiSort } from 'utilities/arrays';

import { chunk } from 'utilities/arrays';
import { getMonday } from 'utilities/dates';
import { trackAction, actions } from 'app/amplitude';

/**
 * Converts a date stamp in the format `yyyy-mm-dd` into an object with some
 * helpful meta properties.
 *
 * @param {String} stamp The dateStamp to convert to a date
 */
function dateStampToNurseryDay(stamp) {
  const date = parseISO(stamp);
  const isStampToday = isToday(date);
  const isBeforeToday = !isStampToday && isPast(date);
  const label = isStampToday ? 'Today' : format(date, 'EEEE dd');
  const isWeekend = date.getDay() === 0 || date.getDay() === 6;

  return {
    dateStamp: stamp,
    date,
    isToday: isStampToday,
    isBeforeToday,
    isWeekend,
    label,
  };
}

function generateTitleFromDays(days) {
  let monthNames = [];
  let years = [];

  days.forEach((day) => {
    const monthName = format(day.date, 'MMMM');

    if (!monthNames.includes(monthName)) {
      monthNames.push(monthName);
    }

    if (!years.includes(day.date.getFullYear())) {
      years.push(day.date.getFullYear());
    }
  });

  return `${monthNames.join(' / ')} ${years.join(' & ')}`;
}

export const useAvailabilityWorksheet = (nurseryId, weeksToShow) => {
  const [changes, setChanges] = useState({});
  const [isWorking, setIsWorking] = useState(false);

  const startDate = getMonday(new Date());
  // End date is 3 * `weeksToShow` minus 1 because calendar starts on Monday
  const endDate = addDays(startDate, weeksToShow * 7 - 1);

  const { refetch, data, loading } = useQuery(getNurseryAvailability, {
    variables: {
      nurseryId,
      startDate: format(startDate, 'yyyy-MM-dd'),
      endDate: format(endDate, 'yyyy-MM-dd'),
    },
    skip: !nurseryId,
    onCompleted: () => setChanges({}),
  });

  const {
    rooms = [],
    datesByWeek = [],
    title = '',
    nurseryAvailability = {},
  } = useMemo(() => {
    setChanges({});

    const rooms =
      data?.availabilityForNurseryAdmin.availability?.[0]?.rooms?.map((n) => n?.nurseryRoom) ?? [];
    const sortedRooms = multiSort(rooms, [
      'ageMonthsStart',
      // 'ageMonthsEnd', @todo -- should we be sorting by this at all?
      'name',
    ]);

    const allWeekDays =
      data?.availabilityForNurseryAdmin.availability
        ?.map((d) => dateStampToNurseryDay(d.date))
        .filter((d) => !d.isWeekend) ?? [];

    return {
      rooms: sortedRooms,
      datesByWeek: chunk(allWeekDays, 5),
      title: generateTitleFromDays(allWeekDays),
      nurseryAvailability: new SessionAvailabilityWorksheet(
        data?.availabilityForNurseryAdmin.availability ?? [],
      ),
    };
  }, [data]);

  const [updateAvailability] = useMutation(modifyAvailability);

  return {
    datesByWeek,
    hasChanges: Object.keys(changes).length > 0,
    isWorking,
    loading,
    nurseryAvailability,
    rooms,
    title,

    discardChanges() {
      setChanges({});
    },

    getRemainingSpots(roomId, date, session) {
      const hasChanges = changes?.[roomId]?.[date]?.[session] !== undefined;

      if (hasChanges) {
        return changes?.[roomId]?.[date]?.[session];
      }

      const availability = nurseryAvailability.byRoom[roomId].availabilityByDay[date];

      return session === 'am' ? availability.remainingAmSpots : availability.remainingPmSpots;
    },

    /**
     * Saves all changes to the server
     *
     * The backend currently requires batching requests by room
     */
    async saveChanges() {
      if (Object.keys(changes).length === 0) {
        // No changes
        return;
      }

      let saveError = false;
      setIsWorking(true);

      for (const roomId in changes) {
        const dates = Object.entries(changes[roomId]).map(([date, dayChanges]) => ({
          date,
          // Currently using Math.max to get around saving -1 values on the backend
          newAmSpots: Math.max(dayChanges.am ?? 0, 0),
          newPmSpots: Math.max(dayChanges.pm ?? 0, 0),
        }));

        const variables = {
          nurseryRoom: roomId,
          dates,
        };

        try {
          const { data, errors } = await updateAvailability({
            variables,
          });

          if (!data?.availabilitiesUpdate?.success || errors) {
            saveError = true;
            break;
          }
        } catch (error) {
          saveError = true;
          break;
        }
      }

      setIsWorking(false);

      if (saveError) {
        throw new Error('Unable to save availability');
      }

      refetch();
    },

    /**
     * Updates the availability of a given day for this worksheet.
     *
     * This method updates the worksheet's internal `changes` object above which keeps track
     * of all changes that are pending for the server once `saveChanges` gets called.
     *
     * @param {String} roomId The roomId to be updated
     * @param {String} date The date to be updated, in the format of `yyyy-mm-dd`
     * @param {String} session The session, either `am` or `pm`
     * @param {Number} amount The updated availability for this session
     */
    updateAvailability(roomId, date, session, amount) {
      const updates = { ...changes };

      if (!updates[roomId]) {
        updates[roomId] = {};
      }

      if (!updates[roomId][date]) {
        updates[roomId][date] = {
          am: this.getRemainingSpots(roomId, date, 'am'),
          pm: this.getRemainingSpots(roomId, date, 'pm'),
        };
      }

      updates[roomId][date][session] = amount;

      setChanges(updates);

      if (session === 'am') {
        trackAction(actions.AM_UPDATE);
      } else if (session === 'pm') {
        trackAction(actions.PM_UPDATE);
      }
    },
  };
};
