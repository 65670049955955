import { ConfirmationPrompt } from 'ui';

export const ChildPromptGroup = ({
  childInfoToEdit,
  isWorking,
  showConfirmationModal,
  showErrorModal,
  handleModalClose,
  unrecognise,
}) => {
  return (
    <>
      <ConfirmationPrompt
        confirmationLabel="Yes, I’m sure"
        isVisible={showConfirmationModal && !showErrorModal}
        message={`
          Don’t worry. Sometimes little mix-ups happen.
          This user will still be listed in the Guardian List, but will be marked as blocked.
          You can change this status at anytime.
        `}
        onCancel={handleModalClose}
        onConfirm={(e) => {
          e.preventDefault();
          unrecognise({ childId: childInfoToEdit.childId });
        }}
        isConfirmationWorking={isWorking}
        title="Are you sure you don’t recognise this child?"
      />

      <ConfirmationPrompt
        confirmationLabel="Close"
        showCancelLabel={false}
        isVisible={showErrorModal}
        message="Something didn't work while changing the child status. Please close and try again."
        onConfirm={handleModalClose}
        title="Oops! Something went wrong."
      />
    </>
  );
};
