import classNames from 'classnames';
import styles from './LoadingIndicator.module.scss';

export const LoadingIndicator = ({ className, fullScreen, centred }) => (
  <div
    className={classNames(
      styles.container,
      className,
      fullScreen && styles.fullScreen,
      centred && styles.centred,
    )}
  >
    <div className={styles.dotsBackground}>
      <div className={styles.dots} />
    </div>
  </div>
);
