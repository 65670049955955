import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import amplitude from 'amplitude-js';

const events = {
  PAGE_VIEW_PREFIX: 'page_view',
  APP_LAUNCH: 'app_launch',
};

export const initializeAmplitude = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY, null, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
    logAttributionCapturedEvent: true,
  });
  amplitude.getInstance().logEvent(events.APP_LAUNCH);
};

export const AmplitudePageTracker = () => {
  const history = useHistory();
  function trackPageView() {
    amplitude.getInstance().logEvent(`${events.PAGE_VIEW_PREFIX}${window.location.pathname}`);
  }

  useEffect(() => {
    trackPageView(); // To track the first pageview upon load
    history.listen(trackPageView); // To track the subsequent pageviews
  }, [history]);

  return null;
};

export const setUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const trackAction = (action) => {
  amplitude.getInstance().logEvent(`action/${action}`);
};
