import { RadioButton } from 'components';
import { BookingRejectionEnum } from 'graphql/constants';

import { BookingDeclineGoodToKnow } from '../BookingDeclineGoodToKnow';
import { LegalTooltip } from '../LegalTooltip';
import { trackAction, actions } from 'app/amplitude';

import styles from './BookingDeclinePromptContent.module.scss';

const HeadingSeparator = () => <span className={styles.headingSepartor}>|</span>;

export const BookingDeclinePromptContent = ({
  booking,
  reasonOptions,
  declineDetails,
  setDeclineDetails,
  declineReason,
  setDeclineReason,
}) => {
  return (
    <div className={styles.declineMessageWrapper}>
      <h3 className={styles.declineHeading}>
        <span>{booking?.dateFormatted}</span>
        <HeadingSeparator />
        <span>{booking?.sessionTypeLabel}</span>
        <HeadingSeparator />
        <span>{booking?.room?.name}</span>
      </h3>

      <section className={styles.declineMessage}>
        <span>Select a reason:</span>
        {reasonOptions.map((reasonOption) => (
          <div className={styles.reasonOptionWrapper} key={reasonOption.value}>
            <RadioButton
              id={reasonOption.value}
              name="reason"
              value={reasonOption.value}
              onChange={(e) => {
                setDeclineReason(BookingRejectionEnum[e.target.value]);
                trackAction(actions.REQUEST_DECLINE_REASON);
              }}
            />
            <label className={styles.reasonLabel} htmlFor={reasonOption.value}>
              {reasonOption.label}
            </label>
          </div>
        ))}

        {declineReason === BookingRejectionEnum.OTHER && (
          <div className={styles.declineDetails}>
            <span>Enter the reason for cancellation</span>
            <LegalTooltip />
            <textarea
              placeholder="Type here..."
              value={declineDetails}
              onChange={(e) => setDeclineDetails(e.target.value)}
            />
          </div>
        )}

        <BookingDeclineGoodToKnow declineReason={declineReason} />
      </section>
    </div>
  );
};
