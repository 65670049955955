import { Placeholder } from 'ui';

import styles from './StatTile.module.scss';

export const StatTile = ({ children, footer, loading, title, number }) => {
  if (loading) {
    return (
      <div className={styles.container}>
        <h2 className={styles.heading}>
          <div className={styles.number}>
            <Placeholder width={123} height={43} />
          </div>
          <Placeholder width={175} />
        </h2>

        <Placeholder width="100%" height={1} className={styles.dividerPlaceholder} />

        <div className={styles.content}>
          <Placeholder width={175} />
        </div>

        <div className={styles.tileFooter}>
          <Placeholder width="90%" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>
        <div className={styles.number}>{number ?? <strong>–</strong>}</div>
        {title}
      </h2>

      <hr className={styles.divider} />

      <div className={styles.content}>{children}</div>

      <div className={styles.tileFooter}>{footer}</div>
    </div>
  );
};
