import { usePopperTooltip } from 'react-popper-tooltip';

import { IconInfoCircle } from 'icons';

import styles from './LegalTooltip.module.scss';

const legalCopy = `Please do not enter any unnecessary personal information or any sensitive personal data (i.e. racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data, health data, sexual orientation data) regarding yourself, the parent / guardian or the child.`;

export const LegalTooltip = () => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'top',
    });

  return (
    <>
      <button type="button" ref={setTriggerRef} className={styles.iconButton}>
        <IconInfoCircle />
      </button>

      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: styles.tooltipContainer })}>
          {legalCopy}
          <div {...getArrowProps({ className: styles.tooltipArrow })} />
        </div>
      )}
    </>
  );
};
