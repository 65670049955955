import { useMemo, useState } from 'react';
import { differenceInDays, formatDistanceToNow } from 'date-fns';
import { captureMessage } from '@sentry/react';

import { ChildStatusEnum } from 'graphql/constants';
import { Button, Dropdown, Table } from 'ui';

import styles from './OutstandingBookingTableRow.module.scss';
import { PaymentTag } from '../PaymentTag/index';
import { trackAction, actions } from 'app/amplitude';

const ApproveDeclineButtonGroup = ({
  booking,
  roomId,
  isEnabled,
  isWorking,
  onConfirm,
  onDecline,
  onDelay,
}) => {
  const [isApproving, setIsApproving] = useState(false);
  const [isDelaying, setIsDelaying] = useState(false);

  const showDelayButton = useMemo(() => {
    const { isBookingHoldEnabled, bookingHoldThresholdDays = null } = booking.nursery;

    if (isBookingHoldEnabled && bookingHoldThresholdDays === null) {
      // Detect if `bookingHoldThresholdDays` is properly set up & enabled, if not return false here to hide the button
      captureMessage(
        '`isBookingHoldEnabled` is enabled for nursery but `bookingHoldThresholdDays` is not set. ',
        booking,
      );
      return false;
    }

    const isWithinDelayThreshold =
      differenceInDays(new Date(), booking.date) < bookingHoldThresholdDays;

    return isBookingHoldEnabled && isWithinDelayThreshold;
  }, [booking]);

  return (
    <>
      <div className={styles.buttonGroup}>
        <Button
          onClick={(e) => {
            setIsApproving(true);
            onConfirm({ booking, roomId })(e);
          }}
          disabled={!isEnabled}
          isWorking={isApproving && isWorking}>
          Approve
        </Button>

        <Button
          type="secondary"
          className={styles.declineButton}
          onClick={onDecline({ booking, roomId })}
          disabled={!isEnabled}>
          Decline
        </Button>

        {showDelayButton && (
          <Button
            type="secondary"
            className={styles.delayButton}
            onClick={(e) => {
              setIsDelaying(true);
              onDelay({ booking })(e);
            }}
            isWorking={isDelaying && isWorking}
            disabled={booking.isWithinDelayThreshold || !isEnabled}>
            Hold
          </Button>
        )}
      </div>
    </>
  );
};

export const OutstandingBookingTableRow = ({
  booking,
  isWorking,
  onConfirm,
  onDecline,
  onDelay,
  paymentsEnabled,
  isExternallyManaged,
  roomOptions,
}) => {
  const [roomId, setRoomId] = useState(booking?.room?.id);
  const isEnabled = booking.child?.status === ChildStatusEnum.APPROVED;

  const cells = [
    Table.cell({
      content: booking?.child?.fullName,
      className: styles.childName,
    }),

    Table.cell({ content: booking?.dateFormatted }),

    Table.cell({
      content: booking?.customSessionType ? booking?.customSessionType?.name : booking?.sessionType,
    }),

    Table.cell({
      content: formatDistanceToNow(booking.createdAt, {
        addSuffix: true,
      }),
    }),

    Table.cell({
      content: isEnabled ? (
        isExternallyManaged ? (
          booking.room.name
        ) : (
          <>
            <Dropdown
              className={styles.roomDropdown}
              onChange={(room) => {
                setRoomId(room.value);
                trackAction(actions.BOOKINGS_ROOM_SELECTION);
              }}
              options={roomOptions}
              value={roomId}
              size="small"
              palette="electro"
              variant="outline"
            />
          </>
        )
      ) : (
        '-'
      ),
      className: styles.preventWordWrap,
      width: 120,
    }),

    ...(paymentsEnabled
      ? [
          Table.cell({
            content: <PaymentTag booking={booking} isEnabled={isEnabled} />,
          }),
        ]
      : []),

    Table.cell({
      content: (
        <ApproveDeclineButtonGroup
          booking={booking}
          roomId={roomId}
          isEnabled={isEnabled}
          isWorking={isWorking}
          onConfirm={onConfirm}
          onDecline={onDecline}
          onDelay={onDelay}
        />
      ),
      width: 348,
      className: styles.preventWordWrap,
    }),
  ];
  return (
    <Table.Row cells={cells} className={isEnabled ? '' : styles.disabledRow} key={booking.id} />
  );
};
