import classNames from 'classnames';

import { outlineSuppressionHandlers } from 'ui';

import styles from './ToggleToken.module.scss';

export const ToggleToken = ({ className, children, pressed, ...props }) => (
  <button
    {...outlineSuppressionHandlers}
    {...props}
    aria-pressed={pressed}
    className={classNames(styles.container, className, pressed && styles.pressed)}
  >
    {children}
  </button>
);

ToggleToken.defaultProps = {
  pressed: false,
};
