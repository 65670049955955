const actions = {
  HOME_SELECTION: 'home_selection',
  AVAILABILITY_PLANNER_SELECTION: 'availability_planner_selection',
  BOOKINGS_PAGE_SELECTION: 'bookings_page_selection',
  GUARDIAN_LIST_SELECTION: 'guardian_list_selection',
  SETTINGS_SELECTION: 'settings_selection',
  CONTACT_SUPPORT: 'contact_support',
  CHANGE_NURSERY: 'change_nursery',
  RESPOND_NOW: 'respond_now',
  UPDATE_NOW: 'update_now',
  DISMISS_AVAILABILITY_PLANNER_TOAST_MESSAGE: 'dismiss_availability_planner_toast_message',
  EDIT_SPACES: 'edit_spaces',
  AM_UPDATE: 'am_update',
  PM_UPDATE: 'pm_update',
  SAVE_CHANGES: 'save_changes',
  CANCEL_CHANGES: 'cancel_changes',
  BOOKINGS_ROOM_SELECTION: 'bookings_room_selection',
  BOOKINGS_CHILD_RECOGNISED_YES: 'bookings_child_recognised_yes',
  BOOKINGS_CHILD_RECOGNISED_NO: 'bookings_child_recognised_no',
  REQUEST_APPROVE: 'request_approve',
  REQUEST_DECLINE: 'request_decline',
  REQUEST_DECLINE_REASON: 'request_decline_reason',
  REQUEST_DECLINE_CONFIRMED: 'request_decline_confirmed',
  REQUEST_DECLINE_CANCEL: 'request_decline_cancel',
  REQUEST_HOLD: 'request_hold',
  BOOKINGS_TABLE_FILTER: 'bookings_table_filter',
  BOOKINGS_TABLE_ROOM_FILTER: 'bookings_table_room_filter',
  REQUEST_ONLY_AVAILABILITY_ASSIGN: 'request_only_availability_assign',
  REQUEST_ONLY_NONE: 'request_only_none',
  REQUEST_ONLY_CONFIRM: 'request_only_confirm',
  REQUEST_ONLY_CANCEL: 'request_only_cancel',
  SEARCH: 'search',
  GUARDIAN_LIST_DROPDOWN: 'guardian_list_dropdown',
  GUARDIAN_SELECTED: 'guardian_selected',
  BLOCK_USER: 'block_user',
  EDIT_DETAILS: 'edit_details',
  EDIT_DETAILS_CHILD_UPDATED: 'edit_details_child_updated',
  PAYMENT_DETAILS: 'payment_details',
  STRIPE_CTA: 'stripe_CTA',
  CONTRACT_CANCELLATION: 'contract_cancellation',
  CANCEL_CONTRACT: 'cancel_contract',
  LOGOUT: 'logout',
};

export { actions };
