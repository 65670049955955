import React from 'react';
import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconReview = ({ className, color = 'currentColor', size = 48, stroke, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    viewBox="0 0 48 48"
    width={size}
    height={size}
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"
      fill="white"
    />
    <path
      d="M23.8251 19.8325C18.4785 19.8325 16.2894 24.1866 16.1988 24.3721C16.1238 24.5257 16.1238 24.7014 16.1988 24.855C16.2894 25.0404 18.4785 29.3946 23.8251 29.3946C29.1717 29.3946 31.3609 25.0405 31.4514 24.855C31.5265 24.7014 31.5265 24.5257 31.4514 24.3721C31.361 24.1866 29.1717 19.8325 23.8251 19.8325ZM23.8251 28.2163C19.933 28.2163 18.0029 25.4631 17.5003 24.6135C18.0039 23.7625 19.934 21.0105 23.8251 21.0105C27.7161 21.0105 29.646 23.7623 30.15 24.6134C29.646 25.4646 27.7159 28.2163 23.8251 28.2163V28.2163Z"
      fill="#4378FF"
    />
    <path
      d="M23.8274 22.2544C22.4154 22.2544 21.2666 23.3112 21.2666 24.6102C21.2666 25.9092 22.4154 26.966 23.8274 26.966C25.2395 26.966 26.3883 25.9092 26.3883 24.6102C26.3883 23.3112 25.2395 22.2544 23.8274 22.2544ZM23.8274 25.7881C23.1215 25.7881 22.547 25.2597 22.547 24.6102C22.547 23.9608 23.1215 23.4323 23.8274 23.4323C24.5334 23.4323 25.1079 23.9608 25.1079 24.6102C25.1079 25.2597 24.5334 25.7881 23.8274 25.7881Z"
      fill="#4378FF"
    />
    <path
      d="M27.7665 12.626L30.1885 15.7905L26.0893 16.2386L27.7665 12.626Z"
      fill="#4378FF"
      stroke="#4378FF"
    />
    <path
      d="M28.036 14.6658C28.1912 14.3293 28.0449 13.9287 27.6999 13.7934C25.6468 12.9879 23.4004 12.7881 21.2292 13.2255C18.8382 13.7071 16.6623 14.9364 15.0158 16.7358C13.3693 18.5352 12.3375 20.8114 12.0696 23.2357C11.8017 25.6599 12.3114 28.1066 13.5252 30.2221C14.739 32.3376 16.594 34.0124 18.822 35.0046C21.0501 35.9967 23.536 36.2548 25.9203 35.7414C28.3047 35.2281 30.4641 33.97 32.0866 32.1489C33.5599 30.4952 34.5211 28.455 34.8619 26.2761C34.9191 25.9099 34.6471 25.5814 34.2783 25.5456C33.9094 25.5098 33.5836 25.7803 33.5235 26.146C33.2121 28.0416 32.3685 29.815 31.0845 31.2562C29.6514 32.8647 27.744 33.976 25.6379 34.4294C23.5318 34.8829 21.336 34.6549 19.368 33.7786C17.3999 32.9022 15.7614 31.4228 14.6893 29.5542C13.6171 27.6856 13.1669 25.5244 13.4035 23.3831C13.6402 21.2418 14.5516 19.2311 16.0059 17.6417C17.4602 16.0523 19.3822 14.9665 21.4942 14.5411C23.3863 14.16 25.3431 14.3266 27.1367 15.0144C27.4828 15.1471 27.8808 15.0024 28.036 14.6658Z"
      fill="#4378FF"
    />
  </svg>
);
