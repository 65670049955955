import { Link, useHistory } from 'react-router-dom';

import { IconBack } from 'icons';
import { PebbleLogo } from 'components';

import defaultIllustration from './defaultIllustration.svg';
import styles from './PublicPageWithIllustration.module.scss';

export const PublicPageWithIllustration = ({
  children,
  illustration,
  onBack,
  title,
  useHistoryBack,
  ...props
}) => {
  const history = useHistory();

  const hasBackButton = typeof onBack === 'function' || useHistoryBack;

  const handleBackClick = (e) => {
    if (typeof onBack === 'function') onBack(e);

    if (useHistoryBack) {
      history.goBack();
    }
  };

  return (
    <div {...props} className={styles.container}>
      <main role="main" className={styles.content}>
        <div className={styles.contentWrapper}>
          {hasBackButton && (
            <button className={styles.backButton} onClick={handleBackClick}>
              <IconBack className={styles.chevronIcon} size={14} />
              Back
            </button>
          )}

          {title && <h1 className={styles.title}>{title}</h1>}
          {children}
        </div>
      </main>

      <aside className={styles.illustration}>
        <img src={illustration} alt="Laptop with Pebble home screen displayed on screen" />
      </aside>

      <header>
        <Link to="/" title="Landing page | Nursery Portal | Pebble" className={styles.backLink}>
          <PebbleLogo height={60} />
        </Link>
      </header>
    </div>
  );
};

PublicPageWithIllustration.defaultProps = {
  illustration: defaultIllustration,
};
