import { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { useSelect } from 'downshift';

import { useElementDimensions, usePortal } from 'hooks';
import { IconChevronDown } from 'icons';
import { outlineSuppressionHandlers } from 'ui';

import styles from './Dropdown.module.scss';

export const Dropdown = ({
  className,
  itemToLabel,
  itemToValue,
  noValueText,
  disabled,
  onChange,
  options,
  palette,
  size,
  value,
  variant,
  zIndex,
}) => {
  const [dropdownButton, dimensions, remeasure] = useElementDimensions();
  const selectedItem = useMemo(
    () => options.find((o) => itemToValue(o) === value) || null,
    [options, value, itemToValue],
  );

  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: options,
    itemToString: itemToLabel,
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem),
    selectedItem,
  });

  const dropdownPosition = useMemo(
    () =>
      dimensions.top
        ? {
            top: dimensions.y + dimensions.height,
            left: dimensions.x,
            width: dimensions.width,
          }
        : null,
    [dimensions],
  );

  useEffect(() => {
    if (isOpen) remeasure();
  }, [isOpen, remeasure]);

  return (
    <div
      className={classNames(
        styles.container,
        isOpen && styles.dropdownOpen,
        disabled && styles.dropdownDisabled,
        size && styles[`${size}Size`],
        className,
      )}
      ref={dropdownButton}
    >
      <button
        type="button"
        {...outlineSuppressionHandlers}
        {...getToggleButtonProps({
          'aria-label': noValueText,
          'aria-labelledby': null,
        })}
        className={classNames(styles.button, styles[palette], styles[variant])}
        disabled={disabled || options.length <= 1}
      >
        <span className={styles.buttonText}>
          {selectedItem ? itemToLabel(selectedItem) : noValueText}
        </span>

        <IconChevronDown size={10} className={styles.chevron} />
      </button>

      <DropdownMenu
        position={dropdownPosition}
        className={isOpen ? styles.dropdownOpen : null}
        zIndex={zIndex}
      >
        <ul
          {...getMenuProps({
            'aria-label': noValueText,
            'aria-labelledby': getToggleButtonProps().id,
          })}
          className={classNames(styles.dropdown, styles[palette], styles[variant])}
        >
          {options.map((item, index) => (
            <li
              className={classNames(
                styles.option,
                highlightedIndex === index && styles.highlighted,
                styles[palette],
                styles[variant],
                !isOpen && styles.hide,
              )}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {itemToLabel(item)}
            </li>
          ))}
        </ul>
      </DropdownMenu>
    </div>
  );
};

Dropdown.defaultProps = {
  itemToLabel: (item) => (item && 'label' in item ? item.label : ''),
  itemToValue: (item) => (item && 'value' in item ? item.value : ''),
  noValueText: '',
  palette: 'default',
  variant: 'noVariant',
};

const DropdownMenu = ({ children, className, position, zIndex }) => {
  const target = usePortal('dropdowns');
  const dropdownStyle = useMemo(
    () => ({
      ...position,
      ...(zIndex && { zIndex }),
    }),
    [position, zIndex],
  );

  const content = (
    <div className={classNames(styles.dropdownMenu, className)} style={dropdownStyle}>
      {children}
    </div>
  );

  return createPortal(content, target);
};
