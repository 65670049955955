import { AccountStatusEnum } from 'graphql/constants';
import { Button } from 'ui/Button/index';
import { trackAction, actions } from 'app/amplitude';
import { useAnalytics, ANALYTICS_EVENTS } from 'app/analytics';

export const PaymentComponent = ({ status, handleOnboarding }) => {
  const { trackEvent } = useAnalytics();
  switch (status) {
    case AccountStatusEnum.NOT_STARTED:
      return (
        <>
          <p>Set up your account to track Pebble’s payout.</p>

          <Button
            onClick={(e) => {
              handleOnboarding(e);
              trackAction(actions.STRIPE_CTA);
              trackEvent(ANALYTICS_EVENTS.ADD_BANK_DETAILS);
            }}>
            Add your bank details
          </Button>
        </>
      );

    case AccountStatusEnum.IN_PROGRESS:
      return (
        <>
          <p>Complete your set up to receive payment from Pebble.</p>

          <Button
            onClick={(e) => {
              handleOnboarding(e);
              trackEvent(ANALYTICS_EVENTS.COMPLETE_STRIPE_SETUP);
            }}>
            Complete set up
          </Button>
        </>
      );

    case AccountStatusEnum.COMPLETED:
      return <p>Payments are set up for your nursery.</p>;

    default:
      return <p>Payment status is unknown, please contact Pebble support.</p>;
  }
};
