export * from './Alert';
export * from './Button';
export * from './Blanket';
export * from './Checkbox';
export * from './CloseButton';
export * from './Dialog';
export * from './Dropdown';
export * from './FullScreen';
export * from './ConfirmationPrompt';
export * from './Input';
export * from './IconButton';
export * from './LinkButton';
export * from './LoadingIndicator';
export * from './Pebbles';
export * from './Placeholder';
export * from './Spinner';
export * from './Splotch';
export * from './SortableTable';
export * from './Table';
export * from './Tag';
export * from './TextInput';
export * from './ThreeDots';
export * from './ToggleToken';
export * from './Wizard';

/**
 * Used to suppress the outline on clickable elements when clicked, but not when tabbed to
 */
export const outlineSuppressionHandlers = {
  onMouseDown: (e) => {
    e.currentTarget.style.outline = 'none';
    e.currentTarget.style.boxShadow = 'none';
  },

  onBlur: (e) => {
    e.currentTarget.style.outline = null;
    e.currentTarget.style.boxShadow = null;
  },
};
