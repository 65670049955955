import { useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { useAnalytics, ANALYTICS_EVENTS } from 'app/analytics';
import { useProfile } from 'app/profile';
import { IconSearch } from 'icons';
import { DefaultAppPage } from 'layouts';

import { GuardianStatusEnum } from 'graphql/constants';
import { getGuardiansByNursery } from 'graphql/queries';
import { guardianUpdate } from 'graphql/mutations';
import { mutationHasErrors } from 'graphql/utils';
import { useChildUpdate } from 'hooks';

import { GuardianPicker } from './components/GuardianPicker';
import { GuardianViewer } from './components/GuardianViewer';
import { PeopleSummary } from './components/PeopleSummary';
import styles from './GuardiansList.module.scss';

export const GuardiansList = () => {
  const { nursery } = useProfile();

  const { trackEvent } = useAnalytics();
  const { data, loading, refetch } = useQuery(getGuardiansByNursery, {
    variables: {
      nurseryId: nursery.id,
    },
  });

  const [selectedGuardianId, setSelectedGuardianId] = useState(null);
  const [
    guardianUpdateMutation,
    { data: guardianUpdateData, error: guardianUpdateError, loading: guardianUpdateLoading },
  ] = useMutation(guardianUpdate);

  const selectedGuardian = useMemo(
    () => data?.guardiansByNursery?.find((g) => g.id === selectedGuardianId),
    [data?.guardiansByNursery, selectedGuardianId],
  );

  const roomOptions = useMemo(() => {
    const nurseryRooms = nursery?.nurseryRooms ?? [];

    return [
      { value: null, label: 'Select' },
      ...nurseryRooms.map((room) => ({
        value: room.id,
        label: room.name,
      })),
    ];
  }, [nursery?.nurseryRooms]);

  const handleBlockUser = async (e) => {
    e.preventDefault();

    trackEvent(ANALYTICS_EVENTS.GUARDIAN.USER_BLOCKED);

    await guardianUpdateMutation({
      variables: {
        guardianId: selectedGuardian.id,
        status: GuardianStatusEnum.REJECTED,
      },
    });
    refetch();
  };

  const handleUnblockUser = async (e) => {
    e.preventDefault();

    await guardianUpdateMutation({
      variables: {
        guardianId: selectedGuardian.id,
        status: GuardianStatusEnum.APPROVED,
      },
    });
    refetch();
  };

  const {
    approve: approveChild,
    unrecognise,
    edit: editChild,
    reactivate,
    data: childUpdateData,
    error: childUpdateError,
    loading: childUpdateLoading,
  } = useChildUpdate();

  const handleSaveChanges = async (formData) => {
    await Promise.all(
      formData.children.map((child) =>
        editChild({
          childId: child.id,
          ...(child.roomId && { primaryNurseryRoom: child.roomId }),
          ...(child.birthDate && { birthDate: child.birthDate }),
        }),
      ),
    );

    trackEvent(ANALYTICS_EVENTS.GUARDIAN.USER_DETAILS_EDITED);
    refetch();
  };

  const nurseryCanToggleInstantBook = useMemo(() => {
    return nursery.isExternallyManaged && !nursery.allowOnlyBookingRequests;
  }, [nursery]);

  return (
    <DefaultAppPage
      title="Guardian list"
      intro={
        <>
          View all guardians who have signed up for Pebble.
          <br />
          Click on a name to view their account details.
        </>
      }
      useNurserySwitcher
      contentClass={styles.container}>
      <PeopleSummary nurseryId={nursery.id} className={styles.peopleSummary} />

      <nav aria-label="Guardian list" className={styles.guardiansList}>
        <GuardianPicker
          guardians={data?.guardiansByNursery ?? []}
          loading={loading}
          selectedGuardianId={selectedGuardianId}
          onPick={setSelectedGuardianId}
        />
      </nav>
      <main className={styles.guardianViewer}>
        {!loading && data?.guardiansByNursery?.length === 0 && (
          <div className={styles.emptyState}>
            <div className={styles.noData}>
              <p>
                You don’t have any guardians signed up yet. Once you do, they will appear on this
                page.
              </p>
            </div>
          </div>
        )}
        {selectedGuardian && (
          <GuardianViewer
            nurseryId={nursery.id}
            guardianId={selectedGuardian.id}
            guardianName={selectedGuardian.fullName}
            guardianEmail={selectedGuardian.children[0]?.guardian?.email}
            guardianStatus={selectedGuardian.status}
            guardianUpdateHasErrors={mutationHasErrors({
              innerData: guardianUpdateData?.guardianUpdate,
              error: guardianUpdateError,
            })}
            guardianUpdateLoading={guardianUpdateLoading}
            childUpdateHasErrors={mutationHasErrors({
              innerData: childUpdateData?.childUpdateByNursery,
              error: childUpdateError,
            })}
            childUpdateLoading={childUpdateLoading}
            handleBlockUser={handleBlockUser}
            handleUnblockUser={handleUnblockUser}
            handleSaveChanges={handleSaveChanges}
            handleChildAccountReactivation={reactivate}
            handleApproveChildAccount={approveChild}
            handleUnrecogniseChildAccount={unrecognise}
            roomOptions={roomOptions}
            nurseryCanToggleInstantBook={nurseryCanToggleInstantBook}
          />
        )}
        {!selectedGuardian && data?.guardiansByNursery?.length > 0 && (
          <div className={styles.emptyState}>
            <div>
              <div className={styles.iconWrapper}>
                <IconSearch size={22} />
              </div>
              Select a guardian to view details
            </div>
          </div>
        )}
      </main>
    </DefaultAppPage>
  );
};
