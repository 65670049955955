import { useState, useEffect } from 'react';
import classNames from 'classnames';

import { Button, Dropdown } from 'ui';
import { ChildUnrecognitionPrompt } from 'components';

import styles from './PendingChildRow.module.scss';

export const PendingChildRow = ({
  child,
  roomOptions,
  handleApproveChildAccount,
  handleUnrecogniseChildAccount,
  childUpdateLoading,
  isExternallyManaged,
}) => {
  const [roomId, setRoomId] = useState(child.primaryNurseryRoom?.id || null);
  const [showUnrecognisePrompt, setShowUnrecognisePrompt] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isUnrecognising, setIsUnrecognising] = useState(false);
  const [hasRoomError, setRoomError] = useState(false);

  const handleYesClick = (e) => {
    e.preventDefault();

    if (!roomId) {
      setRoomError(true);
    } else {
      setIsApproving(true);
      handleApproveChildAccount({ childId: child.id, roomId });
    }
  };

  useEffect(() => {
    if (!childUpdateLoading) {
      setIsApproving(false);
      setIsUnrecognising(false);
    }
  }, [childUpdateLoading]);

  return (
    <>
      <div className={styles.personDetails}>
        <div className={styles.childNameWrapper}>
          <span className={styles.pendingChildQuestion}>Do you recognise this child?</span>
          <span className={styles.pendingChildName}>{child.fullName}</span>
        </div>

        {!isExternallyManaged && (
          <div className={styles.roomWrapper}>
            <span
              className={classNames(styles.pendingChildRoomLabel, hasRoomError && styles.error)}>
              Room:
            </span>
            <Dropdown
              className={styles.pendingChildRoomDropdown}
              onChange={(room) => {
                setRoomId(room.value);
              }}
              options={roomOptions}
              value={roomId}
              size="small"
              palette={hasRoomError ? 'red' : 'electro'}
              variant="outline"
            />
          </div>
        )}

        <div className={styles.buttonGroup}>
          <Button isWorking={isApproving} onClick={handleYesClick}>
            Yes
          </Button>
          <Button
            disabled={isApproving}
            onClick={(e) => {
              e.preventDefault();
              setShowUnrecognisePrompt(true);
            }}>
            No
          </Button>
        </div>
      </div>

      <ChildUnrecognitionPrompt
        isVisible={showUnrecognisePrompt}
        onCancel={(e) => {
          e.preventDefault();
          setShowUnrecognisePrompt(false);
        }}
        onConfirm={() => {
          setIsUnrecognising(true);
          handleUnrecogniseChildAccount({ childId: child.id });
        }}
        isWorking={isUnrecognising}
      />
    </>
  );
};
