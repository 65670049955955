import { ConfirmationPrompt } from 'ui';
import { RadioButton } from 'components';
import { IconClock, IconChild } from 'icons';
import { formatTimeString } from 'utilities/dates';
import { trackAction, actions } from 'app/amplitude';
import styles from './AvailabilityPrompt.module.scss';

const PromptContent = ({ availabilityType, setDeductAvailability }) => (
  <div className={styles.radioButtonContainer}>
    <RadioButton
      id={availabilityType}
      name="availability type"
      value={availabilityType}
      label={availabilityType}
      onChange={() => {
        setDeductAvailability(true);
        trackAction(actions.REQUEST_ONLY_AVAILABILITY_ASSIGN);
      }}
    />
    <RadioButton
      id="none"
      name="availability type"
      value="none"
      label="None"
      onChange={() => {
        setDeductAvailability(false);
        trackAction(actions.REQUEST_ONLY_NONE);
      }}
    />
  </div>
);

export const AvailabilityPrompt = ({
  showConfirmationModal,
  handleModalClose,
  booking,
  onConfirm,
  deductAvailability,
  setDeductAvailability,
}) => {
  return booking ? (
    <>
      <ConfirmationPrompt
        isVisible={showConfirmationModal}
        onCancel={handleModalClose}
        onConfirm={onConfirm}
        confirmationLabel="Confirm"
        isConfirmationDisabled={deductAvailability === null}
        title="Do you want to deduct availability from the planner?"
        bannerMessage={
          <>
            <span className={styles.childName}>
              <IconChild /> {booking.child?.fullName}
            </span>
            <span className={styles.sessionTime}>
              <IconClock /> {formatTimeString(booking.customSessionType?.startTime, 'HH:mm')} -{' '}
              {formatTimeString(booking.customSessionType?.endTime, 'HH:mm')}
            </span>
          </>
        }
        message={
          <PromptContent
            availabilityType={booking.customSessionType?.availabilityType}
            setDeductAvailability={setDeductAvailability}
          />
        }
      />
    </>
  ) : null;
};
