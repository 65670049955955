import { useEffect, useMemo, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { format, isValid, parseJSON } from 'date-fns';

import { ChildStatusEnum, GuardianStatusEnum } from 'graphql/constants';
import { getGuardianById } from 'graphql/queries';
import { IconPen, IconTimes } from 'icons';
import { Blanket, Button, ConfirmationPrompt, Placeholder, Tag } from 'ui';
import { parseDate } from 'utilities/dates';
import { firstName, surname } from 'utilities/names';

import styles from './GuardianViewer.module.scss';
import { ChildDetailRow } from '../ChildDetailRow';
import { useProfile } from 'app/profile';
import { trackAction, actions } from 'app/amplitude';

export const GuardianViewer = ({
  className,
  nurseryId,
  guardianId,
  guardianName,
  guardianEmail,
  guardianStatus,
  guardianUpdateHasErrors,
  guardianUpdateLoading,
  childUpdateHasErrors,
  childUpdateLoading,
  handleBlockUser,
  handleUnblockUser,
  handleSaveChanges,
  handleChildAccountReactivation,
  handleApproveChildAccount,
  handleUnrecogniseChildAccount,
  roomOptions,
  nurseryCanToggleInstantBook,
}) => {
  const { nursery } = useProfile();
  const [dateJoined, setDateJoined] = useState(null);
  const [activeChildren, setActiveChildren] = useState([]);
  const [deactivatedChildren, setDeactivatedChildren] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ children: [] });
  const [formErrors, setFormErrors] = useState({ children: {} });

  useEffect(() => {
    if (!guardianUpdateLoading && !childUpdateLoading) {
      setIsEditing(false);
      setIsLoading(false);
    }
  }, [childUpdateLoading, guardianUpdateLoading]);

  useEffect(() => {
    if (!isEditing) {
      setFormData({ children: [] });
    }
  }, [isEditing]);

  const blockUserRef = useRef();
  const [blockUserFocus, setBlockUserFocus] = useState(false);
  useEffect(() => {
    if (blockUserFocus && blockUserRef.current) {
      blockUserRef.current.focus();
      setBlockUserFocus(false);
    }
  }, [blockUserFocus]);

  const unblockUserRef = useRef();
  const [unblockUserFocus, setUnblockUserFocus] = useState(false);
  useEffect(() => {
    if (unblockUserFocus && unblockUserRef.current) {
      unblockUserRef.current.focus();
      setUnblockUserFocus(false);
    }
  }, [unblockUserFocus]);

  const isBlocked = guardianStatus === GuardianStatusEnum.REJECTED;

  const { data, loading, refetch } = useQuery(getGuardianById, {
    variables: {
      guardianId,
      nurseryId,
    },
    onCompleted: (data) => {
      setDateJoined(format(parseJSON(data?.guardianById?.dateJoined), 'dd | MM | yyyy'));
    },
  });

  const approvedChildren = useMemo(
    () =>
      data
        ? data.guardianById?.children.filter((child) => child.status === ChildStatusEnum.APPROVED)
        : [],
    [data],
  );

  useEffect(() => {
    if (!data?.guardianById?.children) {
      setActiveChildren([]);
      setDeactivatedChildren([]);
    } else {
      setActiveChildren(
        data.guardianById.children.filter((child) =>
          [ChildStatusEnum.APPROVED, ChildStatusEnum.PENDING].includes(child.status),
        ),
      );
      setDeactivatedChildren(
        data.guardianById.children.filter((child) => child.status === ChildStatusEnum.DEACTIVATED),
      );
    }
  }, [data]);

  const handleBlockUserClick = (e) => {
    e.preventDefault();

    setShowConfirmation(true);
    trackAction(actions.BLOCK_USER);
  };

  const handleUnblockUserClick = (e) => {
    e.preventDefault();

    setShowConfirmation(true);
  };

  useEffect(() => {
    if (!guardianUpdateLoading) {
      setShowConfirmation(false);
    }
  }, [guardianUpdateLoading]);

  useEffect(() => {
    if (guardianUpdateHasErrors || childUpdateHasErrors) {
      setShowErrorModal(true);
    }
  }, [childUpdateHasErrors, guardianUpdateHasErrors]);

  const setChildFormData = (childData) => {
    setFormData({
      ...formData,
      children: [
        ...formData?.children.filter((c) => c.id !== childData.id),
        {
          id: childData.id,
          ...formData?.children?.find((c) => c.id === childData.id),
          ...childData,
        },
      ],
    });
  };

  const handleSaveChangesClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({ children: {} });

    let childrenDataErrors = {};
    formData.children.forEach((childData) => {
      if (childData.birthDate && !isValid(parseDate(childData.birthDate))) {
        childrenDataErrors[childData.id] = {
          ...formErrors.children[childData.id],
          birthDate: true,
        };
      }
    });

    if (Object.keys(childrenDataErrors).length > 0) {
      setFormErrors({
        ...formErrors,
        children: { ...formErrors.children, ...childrenDataErrors },
      });
    } else {
      await handleSaveChanges({
        ...formData,
        children: formData.children.map((childData) => ({
          ...childData,
          ...(childData.birthDate
            ? {
                birthDate: format(parseDate(childData.birthDate), 'yyyy-MM-dd'),
              }
            : {}),
        })),
      });
      setIsEditing(false);
    }
    setIsLoading(false);

    refetch();
    trackAction(actions.EDIT_DETAILS_CHILD_UPDATED);
  };

  return (
    <section className={classNames(styles.container, className)}>
      {isBlocked && (
        <div className={classNames(styles.personDetails, styles.blockedUser)}>
          <Tag className={styles.blockedTag}>Blocked</Tag>
          <div className={styles.blockedUserText}>
            <h2>{guardianName} has been blocked.</h2>
            <p>Click "unblock" to re-activate this account.</p>
          </div>
          <Button
            className={styles.unblockUserCTA}
            onClick={handleUnblockUserClick}
            ref={unblockUserRef}>
            Unblock
          </Button>
        </div>
      )}

      <div className={styles.headerContainer}>
        <h2 className={classNames(styles.header, isBlocked && styles.blockedUser)}>
          {surname(guardianName)}, {firstName(guardianName)}
        </h2>

        {!loading && !isBlocked && !isEditing && (
          <button className={styles.blockUserCTA} onClick={handleBlockUserClick} ref={blockUserRef}>
            <IconTimes size={18} />
            <>Block user</>
          </button>
        )}

        {!loading &&
          approvedChildren.length > 0 &&
          !isBlocked &&
          !isEditing &&
          !nursery?.isExternallyManaged && (
            <button
              className={styles.editDetailsCTA}
              onClick={() => {
                setIsEditing(true);
                trackAction(actions.EDIT_DETAILS);
              }}>
              <IconPen size={18} />
              <>Edit details</>
            </button>
          )}
      </div>

      <dl
        className={styles.personDetails}
        style={{
          width: nurseryCanToggleInstantBook ? '807px' : '747px',
        }}>
        <div className={classNames(styles.personDetailElement, isBlocked && styles.blockedUser)}>
          <dt>Phone number</dt>
          <dd>{loading ? <Placeholder width={132} /> : data?.guardianById?.phoneNumber}</dd>
        </div>

        <div className={classNames(styles.personDetailElement, isBlocked && styles.blockedUser)}>
          <dt>Date joined</dt>
          <dd>{loading ? <Placeholder width={132} /> : dateJoined}</dd>
        </div>

        {nursery?.isExternallyManaged && (
          <div className={classNames(styles.personDetailElement, isBlocked && styles.blockedUser)}>
            <dt>Email Address</dt>
            <dd>{loading ? <Placeholder width={132} /> : guardianEmail}</dd>
          </div>
        )}
      </dl>

      {activeChildren.length > 0 && (
        <>
          <h3 className={classNames(styles.header, isBlocked && styles.blockedUser)}>
            Child(ren) details
          </h3>

          {activeChildren.map((child, childIndex) => (
            <ChildDetailRow
              key={childIndex}
              child={child}
              disabled={isBlocked}
              isEditing={isEditing}
              roomOptions={roomOptions}
              childFormData={formData.children.find((childData) => childData.id === child.id) || {}}
              setChildFormData={setChildFormData}
              childFormErrors={formErrors.children[child.id]}
              handleApproveChildAccount={handleApproveChildAccount}
              handleUnrecogniseChildAccount={handleUnrecogniseChildAccount}
              childUpdateLoading={childUpdateLoading}
              nurseryCanToggleInstantBook={nurseryCanToggleInstantBook}
            />
          ))}
        </>
      )}

      {deactivatedChildren.length > 0 && (
        <>
          <h3 className={classNames(styles.header, isBlocked && styles.blockedUser)}>
            De-activated child accounts
          </h3>

          {deactivatedChildren.map((child, childIndex) => (
            <ChildDetailRow
              key={childIndex}
              child={child}
              disabled={isBlocked}
              handleReactivation={handleChildAccountReactivation}
              childUpdateLoading={childUpdateLoading}
            />
          ))}
        </>
      )}

      {isEditing && (
        <div className={styles.buttonGroup}>
          <Button
            className={styles.cancelButton}
            type="secondary"
            onClick={() => setIsEditing(false)}
            disabled={isLoading}>
            Cancel
          </Button>
          <Button
            className={styles.saveButton}
            onClick={handleSaveChangesClick}
            isWorking={isLoading}>
            Save changes
          </Button>
        </div>
      )}

      <ConfirmationPrompt
        dataTestId="block-user-confirmation-modal"
        confirmationLabel="Block user"
        isConfirmationWorking={guardianUpdateLoading}
        isVisible={showConfirmation && !isBlocked}
        message="You can block a guardian if needed to prevent them from submitting new bookings. You can re-activate a blocked account by coming back here."
        onCancel={() => {
          setShowConfirmation(false);
          setBlockUserFocus(true);
        }}
        onConfirm={handleBlockUser}
        title="Are you sure you want to block this user?"
      />
      <ConfirmationPrompt
        confirmationLabel="Reactivate"
        isConfirmationWorking={guardianUpdateLoading}
        isVisible={showConfirmation && isBlocked}
        message="Once reactivated, this guardian will be able to book at your centre."
        onCancel={() => {
          setShowConfirmation(false);
          setUnblockUserFocus(true);
        }}
        onConfirm={handleUnblockUser}
        title="You are about to reactivate this account. Are you sure?"
      />
      <ConfirmationPrompt
        confirmationLabel="Close"
        showCancelLabel={false}
        isVisible={showErrorModal}
        message="Something didn't work while updating this account. Please close and try again."
        onConfirm={() => setShowErrorModal(false)}
        title="Oops! Something went wrong."
      />
      <Blanket
        isVisible={showConfirmation || showErrorModal}
        onClick={() => {
          setShowConfirmation(false);
          setUnblockUserFocus(true);
        }}
      />
    </section>
  );
};
