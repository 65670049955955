import React from 'react';
import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconChevronRight = ({
  className,
  color = 'currentColor',
  size = 24,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 9 14"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.99998 12L7.33331 7L1.99998 2"
      fill="transparent"
      stroke={color}
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
