import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconChild = ({ className, color = 'currentColor', size = 24, stroke, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.85 5.11667C14.85 6.55311 13.6611 7.73333 12.175 7.73333C10.6889 7.73333 9.5 6.55311 9.5 5.11667C9.5 3.68022 10.6889 2.5 12.175 2.5C13.6611 2.5 14.85 3.68022 14.85 5.11667Z"
      stroke="#0E206F"
    />
    <path
      d="M19.1188 12.4749L16.2688 9.5999C15.7438 9.0749 15.0188 8.7749 14.2688 8.7749H10.2937C9.54375 8.7749 8.81875 9.0749 8.29375 9.5999L5.39375 12.4749C4.86875 12.9999 4.86875 13.8999 5.39375 14.3999C5.91875 14.9749 6.79375 14.9499 7.34375 14.3999L8.92304 12.8206C8.98604 12.7576 9.09375 12.8022 9.09375 12.8913V20.6249C9.09375 21.3749 9.69375 21.9999 10.4688 21.9999C11.2188 21.9999 11.8438 21.3749 11.8438 20.6249V17.6249C11.8438 17.3999 12.0438 17.1999 12.2688 17.1999C12.4938 17.1999 12.6938 17.3999 12.6938 17.6249V20.6249C12.6938 21.3749 13.2938 21.9999 14.0688 21.9999C14.8188 21.9999 15.4438 21.3749 15.4438 20.6249V12.9163C15.4438 12.8272 15.5515 12.7826 15.6145 12.8456L17.1937 14.4249C17.7187 14.9499 18.5938 14.9749 19.1438 14.4249C19.6688 13.8999 19.6688 12.9999 19.1188 12.4749Z"
      stroke="#0E206F"
    />
  </svg>
);
