import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from 'app/auth';
import { useProfile } from 'app/profile';
import { Sidebar } from 'app/components';
import { LoadingIndicator } from 'ui';

import styles from './PrivateRoute.module.scss';

/**
 * Higher-order component that accepts an auth object. If the user is authenticated, allows the route to execute as normal. If
 * the user is not authenticated, redirects the user to the `/login` route
 */
export const PrivateRoute = ({ component: Component, useSidebar, ...rest }) => {
  const authContext = useContext(AuthContext);
  const profileContext = useProfile();
  const isAuthenticated = authContext.isAuthenticated();

  const loadingIndicator = (
    <div className={styles.loadingIndicator}>
      <Sidebar>
        <LoadingIndicator fullScreen />
      </Sidebar>
    </div>
  );

  if (!authContext.isInitializing && !isAuthenticated) {
    // Force user to login
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: rest.location, // Return them to their original route after login
            search: rest.search, // Preserve query params
          },
        }}
      />
    );
  }

  if (authContext.isInitializing || profileContext.isInitializing) {
    return loadingIndicator;
  } else if (isAuthenticated && profileContext.isInitializing) {
    return <Sidebar>{loadingIndicator}</Sidebar>;
  }

  if (profileContext.requiresOnboarding && rest.path !== '/onboarding') {
    return <Redirect to="/onboarding" />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authContext.isAuthenticated() === true ? (
          useSidebar ? (
            <Sidebar>
              <Component {...props} />
            </Sidebar>
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

PrivateRoute.defaultProps = {
  useSidebar: true,
};
