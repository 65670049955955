import { createContext, useCallback, useContext, useRef } from 'react';

export const AnalyticsContext = createContext({
  trackEvent: () => {},
  listen: () => {},
});

const useAnalyticsContextInternal = () => {
  const listener = useRef(() => {});
  const trackEvent = useCallback((eventName, eventData) => {
    listener.current.call(null, eventName, eventData);
  }, []);

  return {
    trackEvent,
    listen: (callback) => (listener.current = callback),
  };
};

export const AnalyticsProvider = ({ children }) => (
  <AnalyticsContext.Provider value={useAnalyticsContextInternal()}>
    {children}
  </AnalyticsContext.Provider>
);

/**
 *
 * @returns analyticsContext
 */
export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};

export const ANALYTICS_EVENTS = {
  APP: {
    LAUNCH: 'App launch',
  },

  LOGIN: 'Login',
  SIGNUP: 'Signup',
  NURSERY: {
    AVAILABILITY: {
      UPDATE_STARTED: 'Nursery availability update started',
      UPDATED: 'Nursery availability updated',
    },
  },

  BOOKING: {
    NEW_CHILD_APPROVED: 'Booking new child approved',
    REQUEST_ACCEPTED: 'Booking request accepted',
    REQUEST_REJECTED: 'Booking request rejected',
    REQUEST_REJECTED_CODE: 'Booking rejected reason code',
  },

  GUARDIAN: {
    USER_BLOCKED: 'Guardian user blocked',
    USER_DETAILS_EDITED: 'Guardian user details edited',
    GUARDIAN_LIST: 'Guardian list clicked',
  },

  CONTACT_SUPPORT_CLICKED: 'Contact support clicked',

  ADD_BANK_DETAILS: 'Add bank details CTA clicked',
  COMPLETE_STRIPE_SETUP: 'Complete stripe set up CTA clicked',
};
