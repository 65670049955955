import { useEffect, useMemo, useState, Fragment } from 'react';
import classNames from 'classnames';
import { Prompt } from 'react-router-dom';
import { format, isToday, differenceInDays, parseJSON } from 'date-fns';
import { toast } from 'react-hot-toast';

import { useAnalytics, ANALYTICS_EVENTS } from 'app/analytics';
import { useProfile } from 'app/profile';
import { IconReview } from 'icons';
import { Blanket, Button, ConfirmationPrompt, LoadingIndicator } from 'ui';

import { RoomPlanningGrid } from './components/RoomPlanningGrid';
import Page from 'pages/elements';
import { DefaultAppPage } from 'layouts';
import styles from './AvailabilityPlanner.module.scss';
import { useAvailabilityWorksheet } from './useAvailabilityWorksheet';
import { CustomSessionWidget } from './components/CustomSessionWidget';
import { trackAction, actions } from 'app/amplitude';

const REMINDER_KEY = 'availability_reminder_timestamp';

export const AvailabilityPlanner = ({ weeksToShow }) => {
  const reminderLastSeenTimeStamp = window.localStorage.getItem(REMINDER_KEY);
  const { nursery, refetch: refetchMe } = useProfile();
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [reminderLastSeen] = useState(Number(reminderLastSeenTimeStamp));
  const worksheet = useAvailabilityWorksheet(nursery.id, weeksToShow);
  const { trackEvent } = useAnalytics();

  const isAfternoon = new Date().getHours() >= 12;
  const lastEditedAt = useMemo(() => {
    if (!nursery || !nursery.availabilityLastEditedAt) return null;

    try {
      const date = parseJSON(nursery.availabilityLastEditedAt);

      if (isToday(date)) {
        return `Today ${format(date, 'hh:mm a')}`;
      } else {
        return format(date, 'dd MMMM');
      }
    } catch (error) {
      console.log('[AvailabilityPlanner]', error);
    }
  }, [nursery]);

  const handleEditClick = (e) => {
    e.preventDefault();

    trackEvent(ANALYTICS_EVENTS.NURSERY.AVAILABILITY.UPDATE_STARTED);
    trackAction(actions.EDIT_SPACES);

    setIsEditing(true);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();

    setShowConfirmation(true);
    trackAction(actions.SAVE_CHANGES);
  };

  const handleConfirmationPromptSave = (e) => {
    e.preventDefault();

    worksheet
      .saveChanges()
      .then(() => {
        refetchMe();
        setIsEditing(false);
        setShowConfirmation(false);

        trackEvent(ANALYTICS_EVENTS.NURSERY.AVAILABILITY.UPDATED);
      })
      .catch((error) => {
        console.log('[AvailabilityPlanner] Save Changes Error: ', error);
        setIsEditing(false);
        setShowConfirmation(false);
      });
  };

  const handleConfirmationPromptCancel = (e) => {
    e.preventDefault();

    setShowConfirmation(false);
  };

  const handleCancelEditClick = (e) => {
    e.preventDefault();

    worksheet.discardChanges();
    setIsEditing(false);
    trackAction(actions.CANCEL_CHANGES);
  };

  useEffect(() => {
    const showReminder = !reminderLastSeen || !isToday(reminderLastSeen);
    if (showReminder) {
      toast(
        <div className={styles.toastContainer}>
          <div className={styles.reviewIconContainer}>
            <IconReview className={styles.reviewIcon} />
          </div>
          <div className={styles.toastContent}>
            <h3 className={styles.toastHeader}>Review this page regularly</h3>
            It's important that the numbers shown are accurate to ensure the best experience for you
            and your parents.
          </div>
        </div>,
        {
          duration: Infinity,
        },
      );
      window.localStorage.setItem(REMINDER_KEY, Date.now());
    }
  }, [reminderLastSeen]);

  return (
    <DefaultAppPage
      title="Availability Planner"
      intro={
        nursery?.isExternallyManaged
          ? 'Here you can review availability at your nursery. Any confirmed bookings will be automatically reflected in these numbers.'
          : 'Update your planner regularly with the number of spaces available to book. Any confirmed bookings will be automatically reflected in these numbers.'
      }
      useNurserySwitcher
      contentClass={styles.container}
      headerClass={styles.header}>
      {worksheet.loading ? (
        <div className={classNames(styles.gridLoading)}>
          <LoadingIndicator fullScreen />
        </div>
      ) : (
        <>
          <div className={styles.gridContainer}>
            <RoomPlanningGrid
              datesByWeek={worksheet.datesByWeek}
              rooms={worksheet.rooms}
              renderCell={({ day, room, session, shouldAutoFocus }) => {
                const remainingSpots = worksheet.getRemainingSpots(room.id, day.dateStamp, session);

                if (isEditing) {
                  return (
                    <input
                      data-testid={'input-number'}
                      type="number"
                      min="0"
                      disabled={
                        day.isBeforeToday ||
                        (day.isToday && session === 'am') ||
                        (day.isToday && session === 'pm' && isAfternoon) ||
                        day.isWeekend
                      }
                      className={styles.input}
                      value={remainingSpots === null ? '' : Math.max(remainingSpots, 0)}
                      autoFocus={shouldAutoFocus}
                      onFocus={(e) => e.target.select()}
                      onKeyDown={(e) => {
                        if (shouldClearInput(e)) {
                          e.preventDefault();
                          e.stopPropagation();

                          worksheet.updateAvailability(room.id, day.dateStamp, session, null);
                        }
                      }}
                      onChange={(e) =>
                        worksheet.updateAvailability(
                          room.id,
                          day.dateStamp,
                          session,
                          Number(e.target.value),
                        )
                      }
                    />
                  );
                }

                const cellText =
                  day.isWeekend && remainingSpots <= 0 ? '-' : Math.max(remainingSpots, 0);

                return (
                  <div
                    className={classNames(
                      styles.sessionCell,
                      remainingSpots > 0 && styles.positiveValue,
                    )}>
                    {cellText}
                  </div>
                );
              }}
              title={worksheet.title}
            />
          </div>

          <aside className={nursery.isExternallyManaged ? styles.sidebarAlt : styles.sidebar}>
            {nursery.isExternallyManaged ? (
              <span>
                Last synced:{' '}
                {differenceInDays(new Date(), parseJSON(nursery.availabilityLastSynchronised))} days
                ago
              </span>
            ) : (
              <div className={styles.editControls}>
                <Page.Heading element="h2">Edit Availability Planner</Page.Heading>

                {lastEditedAt && (
                  <div className={styles.lastEditedAt}>Last updated: {lastEditedAt}</div>
                )}

                <Page.Instructions>
                  {isEditing
                    ? 'Click on a field to edit a session’s availability. Make sure to save any changes when you’re done.'
                    : 'Click on “Edit spaces” to add or revise the number of spaces available for parents to book.'}
                </Page.Instructions>

                {isEditing ? (
                  <>
                    <Button
                      size="large"
                      onClick={handleSaveClick}
                      expand
                      disabled={!worksheet.hasChanges}>
                      Save changes
                    </Button>

                    <Button size="large" type="secondary" onClick={handleCancelEditClick} expand>
                      Cancel
                    </Button>
                  </>
                ) : (
                  <Button size="large" onClick={handleEditClick} expand>
                    Edit spaces
                  </Button>
                )}
              </div>
            )}

            {nursery?.isCustomSessionBookingsEnabled && nursery?.showCustomSessionTimes && (
              // sessionTypes is readOnly
              <CustomSessionWidget sessionTypes={[...nursery.sessionTypes]} />
            )}
          </aside>

          <Prompt
            when={isEditing}
            message="Are you sure you want to leave? Changes you made may not be saved."
          />
        </>
      )}

      <ConfirmationPrompt
        title="Are you sure you want to update your availability planner?"
        confirmationLabel="Yes, update"
        isVisible={showConfirmation}
        isConfirmationWorking={worksheet.isWorking}
        message="The numbers in your planner will be visible to  parents and other admins. Any days with availability will be open for instant booking."
        onCancel={handleConfirmationPromptCancel}
        onConfirm={handleConfirmationPromptSave}
      />
      <Blanket isVisible={showConfirmation} onClick={() => setShowConfirmation(false)} />
    </DefaultAppPage>
  );
};

AvailabilityPlanner.defaultProps = {
  weeksToShow: 4,
};

/**
 * Determines whether or not to clear the input value
 */
function shouldClearInput(e) {
  if (!e) return;
  return (
    // Only activate for Backspace and Delete Keys
    (e.key === 'Backspace' || e.key === 'Delete') &&
    // Only delete if there's one character left, or if the entire input is selected
    (e.target.value.length === 1 ||
      window.getSelection().toString().length === e.target.value.length)
  );
}
