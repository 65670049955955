import { useMemo, useState } from 'react';
import classNames from 'classnames';
import Fuse from 'fuse.js';

import { ChildStatusEnum, GuardianStatusEnum } from 'graphql/constants';
import { Dropdown, Input, Placeholder, Tag } from 'ui';
import { firstName, surname } from 'utilities/names';

import styles from './GuardianPicker.module.scss';

import { trackAction, actions } from 'app/amplitude';

const filterOptions = [
  { value: 'all', label: 'Show all' },
  { value: 'newChild', label: 'New child' },
  { value: 'blocked', label: 'Blocked' },
];

const getFilterFn = (tagFilter) => {
  switch (tagFilter) {
    case 'newChild':
      return (guardian) =>
        guardian.status !== GuardianStatusEnum.REJECTED &&
        guardian.children?.some((c) => c.status === ChildStatusEnum.PENDING);
    case 'blocked':
      return (guardian) => guardian.status === GuardianStatusEnum.REJECTED;
    default:
      return () => true;
  }
};

export const GuardianPicker = ({ guardians, loading, selectedGuardianId, onPick }) => {
  const [searchResult, setSearchResult] = useState();
  const [tagFilter, setTagFilter] = useState('all');

  const sortedGuardians = useMemo(() => {
    if (loading) {
      return [];
    }

    const unsortedGuardians = searchResult || guardians;
    return [...unsortedGuardians].filter(getFilterFn(tagFilter)).sort((a, b) => {
      if (surname(a.fullName).toLowerCase() < surname(b.fullName).toLowerCase()) {
        return -1;
      }
      if (surname(b.fullName).toLowerCase() < surname(a.fullName).toLowerCase()) {
        return 1;
      }
      if (firstName(a.fullName).toLowerCase() < firstName(b.fullName).toLowerCase()) {
        return -1;
      }
      if (firstName(b.fullName).toLowerCase() < firstName(a.fullName).toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }, [guardians, loading, searchResult, tagFilter]);

  const fuse = new Fuse(guardians, {
    keys: ['fullName', 'children.fullName'],
    threshold: localStorage.getItem('search-threshold') || 0.3,
  });

  const handleSearch = (name) => {
    if (name.trim() !== '') {
      setSearchResult(fuse.search(name).map((item) => item.item));
    } else {
      setSearchResult();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Input
          className={styles.searchInput}
          placeholder="Search"
          onChange={(e) => handleSearch(e.target.value)}
          onBlur={() => trackAction(actions.SEARCH)}
        />

        <Dropdown
          className={styles.filterDropdown}
          size="small"
          palette="white"
          options={filterOptions}
          value={tagFilter}
          onChange={(option) => {
            setTagFilter(option.value);
            trackAction(actions.GUARDIAN_LIST_DROPDOWN);
          }}
        />
      </div>

      {loading ? (
        <div className={styles.guardian}>
          <div className={styles.guardianInfo}>
            <div className={styles.guardianName}>
              <Placeholder width={175} height={24} />
            </div>
            <div className={styles.children}>
              <Placeholder width={325} />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.guardians}>
          {sortedGuardians.map((guardian) => {
            const isSelected = guardian.id === selectedGuardianId;

            const isBlocked = guardian.status === GuardianStatusEnum.REJECTED;
            const hasPendingChild = guardian.children?.some(
              (c) => c.status === ChildStatusEnum.PENDING,
            );

            return (
              <button
                className={classNames(styles.guardian, isSelected && styles.selected)}
                onClick={() => {
                  onPick(guardian.id);
                  trackAction(actions.GUARDIAN_SELECTED);
                }}
                aria-pressed={isSelected}
                key={guardian.id}>
                <div className={styles.guardianInfo}>
                  <div className={styles.guardianName}>
                    {surname(guardian.fullName)}, {firstName(guardian.fullName)}
                  </div>

                  <div className={styles.children}>
                    {guardian.children
                      .filter((child) => child.status !== ChildStatusEnum.UNRECOGNIZED)
                      .map((child) => {
                        const roomName = child?.primaryNurseryRoom?.name ?? '–';

                        return (
                          <span key={child.id}>
                            {firstName(child.fullName)} {roomName}
                          </span>
                        );
                      })}
                  </div>
                </div>

                <div className={styles.guardianStatus}>
                  {isBlocked && <Tag className={styles.blockedTag}>Blocked</Tag>}
                  {hasPendingChild && !isBlocked && (
                    <Tag className={styles.newChildTag}>New child</Tag>
                  )}
                </div>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};
