import { ReactComponent as PebbleColour } from './pebbleColour.svg';
import { ReactComponent as PebbleWhite } from './pebbleWhite.svg';

export const PebbleLogo = ({ className, height, variant }) => {
  switch (variant) {
    case 'white':
      return <PebbleWhite height={height} className={className} />;
    default:
      return <PebbleColour height={height} className={className} />;
  }
};
