import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconPen = ({ className, color = 'currentColor', size = 24, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.97419 13.8301L12.2243 8.57994L9.73061 6.08623L4.48049 11.3364C4.40821 11.4087 4.35686 11.4993 4.3319 11.5985L3.75 14.5605L6.71149 13.9786C6.81092 13.9538 6.90188 13.9024 6.97419 13.8301ZM13.9192 6.88508C14.131 6.67319 14.25 6.38584 14.25 6.08623C14.25 5.78662 14.131 5.49928 13.9192 5.28739L13.0232 4.39137C12.8113 4.17954 12.5239 4.06055 12.2243 4.06055C11.9247 4.06055 11.6374 4.17954 11.4255 4.39137L10.5295 5.28739L13.0232 7.7811L13.9192 6.88508Z"
      fill={color}
    />
  </svg>
);
