import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { ConfirmationPrompt, Dropdown, LoadingIndicator } from 'ui';
import { BookingSessionTypeEnum } from 'graphql/constants';
import { getNurseryAvailability } from 'graphql/queries';
import { useMemo } from 'react';

export const RoomPromptGroup = ({
  roomId,
  booking,
  showConfirmationModal,
  showErrorModal,
  handleModalClose,
  setNewBookingRoomId,
  handleConfirmRoomChange,
}) => {
  const { data, loading } = useQuery(getNurseryAvailability, {
    variables: {
      nurseryId: booking?.nursery?.id,
      startDate: booking?.date && format(booking?.date, 'yyyy-MM-dd'),
      endDate: booking?.date && format(booking?.date, 'yyyy-MM-dd'),
    },
    skip: !booking,
  });

  const currentRoom = useMemo(
    () =>
      data?.availabilityForNurseryAdmin.availability[0]?.rooms?.find(
        (room) => room.nurseryRoom.id === roomId,
      ),
    [roomId, data?.availabilityForNurseryAdmin.availability],
  );

  const availability =
    {
      [BookingSessionTypeEnum.PM]: currentRoom?.pmAvailability,
      [BookingSessionTypeEnum.AM]: currentRoom?.amAvailability,
      [BookingSessionTypeEnum.FULL]: Math.min(
        currentRoom?.amAvailability,
        currentRoom?.pmAvailability,
      ),
    }?.[booking?.sessionType] || 0;

  return loading ? (
    <LoadingIndicator centred />
  ) : (
    <>
      <ConfirmationPrompt
        isVisible={showConfirmationModal}
        title={`${availability} spaces available in ${currentRoom?.nurseryRoom?.name} room`}
        message={
          <>
            <p>
              The Availability Planner shows {availability} spaces for this room.
              {availability > 0
                ? ' Would you like to reassign the child to this room?'
                : ' Are you sure you want to reassign the child to this room?'}
            </p>
            <Dropdown
              variant="outline"
              options={booking?.nursery?.nurseryRooms?.map((room) => ({
                label: room.name,
                value: room.id,
              }))}
              value={roomId}
              size="small"
              palette="electro"
              zIndex={999}
              onChange={(room) => setNewBookingRoomId(room.value)}
            />
          </>
        }
        confirmationLabel={availability > 0 ? 'Yes' : 'Yes, update anyway'}
        onCancel={handleModalClose}
        onConfirm={(e) => {
          e.preventDefault();
          handleModalClose(e);
          handleConfirmRoomChange();
        }}
      />

      <ConfirmationPrompt
        confirmationLabel="Close"
        showCancelLabel={false}
        isVisible={showErrorModal}
        message="Something didn't work while changing the child's room. Please close and try again."
        onConfirm={handleModalClose}
        title="Oops! Something went wrong."
      />
    </>
  );
};
