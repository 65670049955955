import { createContext, useContext } from 'react';

import { NurseryAdminProfile } from 'models';
import { noOp } from 'utilities/functions';

import { useProfileData } from './';

const ProfileContext = createContext({
  error: undefined,
  isInitializing: true,
  profile: new NurseryAdminProfile(),
  nursery: null,
  refetch: noOp,
});

export const ProfileProvider = ({ children }) => (
  <ProfileContext.Provider value={useProfileData()}>{children}</ProfileContext.Provider>
);

export const useProfile = () => {
  return useContext(ProfileContext);
};
