import { BookingRejectionEnum } from 'graphql/constants';
import { IconBellCircle, IconExclamationTriangleOutline } from 'icons';
import { electro500, orange500 } from 'utilities/colors';

import styles from './BookingDeclineGoodToKnow.module.scss';

export const BookingDeclineGoodToKnow = ({ declineReason }) =>
  declineReason === BookingRejectionEnum.LATE_ON_PAYMENTS ? (
    <div className={styles.goodToKnowContainer}>
      <IconExclamationTriangleOutline color={orange500} />
      <div className={styles.goodToKnowTextContainer}>
        <h4 className={styles.goodToKnowTitle}>Good to know:</h4>
        <p className={styles.goodToKnowContent}>
          Because the parent is late on payments, we will not offer an alternative booking option.
        </p>
      </div>
    </div>
  ) : (
    <div className={styles.goodToKnowContainer}>
      <IconBellCircle color={electro500} />
      <div className={styles.goodToKnowTextContainer}>
        <h4 className={styles.goodToKnowTitle}>Good to know:</h4>
        <p className={styles.goodToKnowContent}>
          If there is any alternative availability on this day we will notify the parent.
        </p>
      </div>
    </div>
  );
