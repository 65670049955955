import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

/**
 * Convenience hook for easily converting query paramaters into an object, or returns
 * an empty object if there are no query params.
 *
 * @returns {Object}
 */
export const useQueryParams = () => {
  const history = useHistory();
  const queryParams = useMemo(() => queryString.parse(history.location.search), [history]);

  return queryParams || {};
};
