import React from 'react';
import classNames from 'classnames';

import { IconCaretDown } from 'icons';

import styles from './SortableTable.module.scss';

export const SortableTable = ({ className, columns, data, onSort, sortBy }) => {
  const sortDirection = sortBy?.split('_').slice(-1)[0];

  const handleHeadingClick = (sortKey) => {
    if (typeof onSort !== 'function') return;

    const isSameSortKey = sortBy.includes(sortKey);

    if (isSameSortKey) {
      // flip direction
      const nextSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';

      onSort(`${sortKey}_${nextSortDirection}`);
    } else {
      // set new sort key, in ascending mode to start
      onSort(`${sortKey}_asc`);
    }
  };

  return (
    <table className={classNames(styles.table, className)}>
      <thead>
        <tr>
          {columns?.map(({ title, sortable, sortKey }, columnIndex) => {
            const shouldShowCaret = sortable && sortBy.includes(sortKey);

            return (
              <th
                key={columnIndex}
                className={classNames(styles.heading, sortable && styles.clickable)}
                onClick={(e) => sortable && handleHeadingClick(sortKey)}
              >
                {title}
                {shouldShowCaret && (
                  <IconCaretDown
                    size={8}
                    className={classNames(
                      styles.sortCaret,
                      sortDirection === 'desc' && styles.caretFlipped,
                    )}
                  />
                )}
              </th>
            );
          })}
        </tr>
      </thead>

      {data?.map((data, rowIndex) => {
        const extraRow = columns.find(({ extraRow }) => !!extraRow)?.extraRow(data);
        const renderRow = columns.map(({ content, className, width, ownProps }, columnIndex) => {
          // Allow passing a funciton as a className so that styles can be applied based on the actual data
          const resolvedClassName = typeof className === 'function' ? className(data) : className;

          return (
            <td
              key={columnIndex}
              className={resolvedClassName}
              {...ownProps}
              {...(width && {
                style: { width },
              })}
            >
              {content(data)}
            </td>
          );
        });

        if (extraRow) {
          return (
            <tbody
              key={rowIndex}
              className={classNames(styles.tableRowGroup, styles.tableRowGroupHighlight)}
            >
              <tr>
                <td className={styles.tableRowBorder} colSpan={columns.length - 1}>
                  {extraRow}
                </td>
                <td>
                  {/* blank cell needed to visually fill the space above the last cell on the next row */}
                </td>
              </tr>
              <tr>{renderRow}</tr>
            </tbody>
          );
        }

        return (
          <tbody key={rowIndex} className={styles.tableRowGroup}>
            <tr>{renderRow}</tr>
          </tbody>
        );
      })}
    </table>
  );
};

SortableTable.defaultProps = {
  sortBy: '',
};

SortableTable.column = ({
  className,
  content,
  extraRow,
  width,
  sortKey = null,
  title,
  ...ownProps
}) => ({
  className,
  content,
  extraRow,
  width,
  sortable: sortKey !== null,
  sortKey,
  title,
  ownProps,
});
