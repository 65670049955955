import BaseModel from './BaseModel';

export class Nursery extends BaseModel {
  static defaultFields = {
    addressLine1: '',
    addressLine2: '',
    availabilityLastEditedAt: null,
    availabilityLastSynchronised: '',
    bookingHoldThresholdDays: 0,
    city: '',
    country: '',
    hubspotAccountId: '',
    id: null,
    isExternallyManaged: false,
    isBookingHoldEnabled: false,
    isExtracurricularActivityEnabled: false,
    isCustomSessionBookingsEnabled: false,
    isVisible: false,
    name: '',
    nurseryRooms: [],
    organization: {},
    paymentAccount: {},
    paymentsEnabled: false,
    phoneNumber: '',
    postCode: '',
    sessionTypes: [],
    instantBookingCutoffWindow: 0,
    bookingRequestCutoffWindow: 0,
    instantBookingCutoffWindowHours: '',
    bookingRequestCutoffWindowHours: '',
    showCustomSessionTimes: false,
    allowOnlyBookingRequests: false,
  };
}
