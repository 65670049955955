import { useMutation } from '@apollo/client';

import { childUpdate } from 'graphql/mutations';
import { ChildStatusEnum } from '../graphql/constants';

export const useChildUpdate = () => {
  const [childUpdateMutation, { data, error, loading }] = useMutation(childUpdate);

  const edit = (variables) => childUpdateMutation({ variables });
  const approve = ({ childId, roomId }) =>
    edit({ childId, primaryNurseryRoom: roomId, status: ChildStatusEnum.APPROVED });
  const unrecognise = ({ childId }) => edit({ childId, status: ChildStatusEnum.UNRECOGNIZED });
  const reactivate = ({ childId }) => edit({ childId, status: ChildStatusEnum.APPROVED });

  return { approve, unrecognise, edit, reactivate, data, error, loading };
};
