import classNames from 'classnames';

import { IconExclamationTriangle } from 'icons';

import styles from './Alert.module.scss';

export const Alert = ({ className, children }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <IconExclamationTriangle size={14} className={styles.icon} />
      {children}
    </div>
  );
};
