import { useQuery } from '@apollo/client';
import classNames from 'classnames';

import { getGuardiansByNurserySummary } from 'graphql/queries';
import { Placeholder } from 'ui';

import styles from './PeopleSummary.module.scss';

export const PeopleSummary = ({ nurseryId, className }) => {
  const { data, loading } = useQuery(getGuardiansByNurserySummary, {
    variables: { nurseryId },
  });

  return (
    <aside className={classNames(styles.container, className)}>
      {loading && (
        <>
          <Placeholder width={178} height={24} className={styles.personSummary} />
          <Placeholder width={178} height={24} className={styles.personSummary} />
        </>
      )}
      {data && (
        <>
          <div className={styles.personSummary}>
            <span className={styles.personSummaryLabel}>Guardian accounts</span>
            <div className={styles.countBadge}>{data.guardiansByNurserySummary.guardiansCount}</div>
          </div>

          <div className={styles.personSummary}>
            <span className={styles.personSummaryLabel}>Child accounts</span>
            <div className={styles.countBadge}>{data.guardiansByNurserySummary.childrenCount}</div>
          </div>
        </>
      )}
    </aside>
  );
};
