import React from 'react';
import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconChevronDown = ({
  className,
  color = 'currentColor',
  size = 24,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 10 6"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 1L5 5L1 0.999998"
      stroke={color}
      fill="transparent"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
