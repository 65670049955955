import classNames from 'classnames';

import styles from './RadioButton.module.scss';

export const RadioButton = ({ className, label, id, ...props }) => (
  <div className={styles.wrapper}>
    <div className={classNames(styles.container, className)}>
      <input {...props} id={id} type="radio" className={styles.input} />
      <div className={styles.label} />
    </div>
    {label && (
      <label className={styles.inputLabel} htmlFor={id}>
        {label}
      </label>
    )}
  </div>
);
