import { useToaster, toast } from 'react-hot-toast';

import { CloseButton } from 'ui';

import styles from './Notifications.module.scss';
import { trackAction, actions } from 'app/amplitude';

export const Notifications = () => {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  return (
    <div className={styles.container} onMouseEnter={startPause} onMouseLeave={endPause}>
      {toasts.map((notification) => (
        <div
          key={notification.id}
          role={notification.role}
          aria-live={notification.ariaLive}
          className={styles.toast}>
          {notification.message}

          <CloseButton
            className={styles.closeButton}
            iconSize={16}
            onClick={() => {
              toast.remove(notification.id);
              trackAction(actions.DISMISS_AVAILABILITY_PLANNER_TOAST_MESSAGE);
            }}
          />
        </div>
      ))}
    </div>
  );
};
