import { addDays, subDays, format, parse, parseISO } from 'date-fns';

/**
 * Returns the previous Monday of the given date
 * @param {Date} d The initial date to start from
 */
export const getMonday = (d) => subDays(d, d.getDay() - 1);

/**
 * Returns an array of all days to be rendered inside of the DayPicker
 *
 * @param {Date} date The initial date to start from, which will be rounded back to Monday
 * @param {Number} weeksToRender The number of weeks to return
 */
export const getDaysToRender = (date, weeksToRender = 2) => {
  const startDate = getMonday(date);
  const totalDays = weeksToRender * 7;
  const daysToRender = Array.from({ length: totalDays }, (_, i) => addDays(startDate, i));

  return daysToRender;
};

/**
 * Converts a day number to a string.
 *
 * @param {Number} index
 * @return {String} Returns day as string
 */
export const getDayName = (index) => {
  return (
    ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][index] || ''
  );
};

/**
 * Given a date string in "YYYY-MM-DD" format, returns date string in 'DD | MM | YYYY' format.
 *
 * @param {String} isoDate in "YYYY-MM-DD" format
 * @returns {String} date in "DD | MM | YYYY"
 */
export const formatDate = (isoDate) => format(parseISO(isoDate), 'dd | MM | yyyy');

/**
 * Given a date string in "DD | MM | YYYY" format, parse and return the date object
 *
 * @param {String} dateString in "DD | MM | YYYY" format
 * @returns {Date} date object
 */
export const parseDate = (dateString) => parse(dateString, 'dd | MM | yyyy', new Date());

/**
 * Given a date object, return the iso string without the milliseconds
 *
 * @param {Date} date
 * @returns {String} shortend ISO string
 */
export const getShortISOString = (date) => {
  if (!date instanceof Date) return;
  return date.toISOString().split('.')[0];
};

export const formatTimeString = (time, timeFormat) =>
  format(parse(time || '00:00:00', 'HH:mm:ss', new Date()), timeFormat);
