import { gql } from '@apollo/client';

export const modifyAvailability = gql`
  mutation NurseryAdminSessionAvailabilityMutations(
    $dates: [AvailabilityDateType]
    $nurseryRoom: UUID
  ) {
    availabilitiesUpdate(roomId: $nurseryRoom, dates: $dates) {
      availabilities {
        date
        rooms {
          nurseryRoom {
            name
          }
        }
      }
      success
      errors {
        message
        code
      }
    }
  }
`;

export const editBookingRequest = gql`
  mutation BookingUpdateMutation(
    $id: UUID!
    $status: BookingStatusEnum!
    $roomId: UUID
    $rejectionDetails: String
    $rejectionReason: BookingRejectionEnum
    $deductAvailability: Boolean
  ) {
    updateSessionBooking(
      id: $id
      status: $status
      roomId: $roomId
      rejectionDetails: $rejectionDetails
      rejectionReason: $rejectionReason
      deductAvailability: $deductAvailability
    ) {
      bookingRequest {
        id
        createdAt
        date
        sessionType
        status
        type
        nursery {
          id
          name
        }
        room {
          id
          name
        }
        child {
          id
          fullName
          primaryNurseryRoom {
            id
            name
          }
          guardian {
            fullName
          }
        }
        additionalNotes
      }
    }
  }
`;

export const childUpdate = gql`
  mutation ChildUpdateMutation(
    $childId: UUID
    $birthDate: Date
    $primaryNurseryRoom: UUID
    $status: ChildStatusEnum
    $instantBook: Boolean
  ) {
    childUpdateByNursery(
      childId: $childId
      birthDate: $birthDate
      primaryNurseryRoom: $primaryNurseryRoom
      status: $status
      instantBook: $instantBook
    ) {
      child {
        id
        birthDate
        fullName
        primaryNurseryRoom {
          id
        }
        status
      }
      success
      errors {
        code
        message
      }
    }
  }
`;

export const guardianUpdate = gql`
  mutation GuardianUpdateMutation($guardianId: UUID!, $status: GuardianStatusEnum) {
    guardianUpdate(guardianId: $guardianId, status: $status) {
      guardian {
        id
        fullName
        status
        children {
          id
          fullName
          status
          primaryNurseryRoom {
            id
            name
          }
        }
      }
      success
      errors {
        code
        message
      }
    }
  }
`;

export const nurseryPaymentAccountCreate = gql`
  mutation NurseryPaymentAccountCreateMutation($nurseryId: UUID!) {
    nurseryPaymentAccountCreate(nurseryId: $nurseryId) {
      nurseryPaymentAccount {
        paymentAccountOnboardingUrl
      }
      success
      errors {
        code
        message
      }
    }
  }
`;
