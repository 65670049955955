import { Blanket, ConfirmationPrompt } from 'ui';

export const ChildUnrecognitionPrompt = ({ isVisible, onCancel, onConfirm, isWorking }) => (
  <>
    <ConfirmationPrompt
      confirmationLabel="Yes, I’m sure"
      isVisible={isVisible}
      message={`
        Don’t worry. Sometimes little mix-ups happen.
        This user will still be listed in the Guardian List, but will be marked as blocked.
        You can change this status at anytime.
      `}
      onCancel={onCancel}
      onConfirm={onConfirm}
      isConfirmationWorking={isWorking}
      title="Are you sure you don’t recognise this child?"
    />
    <Blanket isVisible={isVisible} onClick={onCancel} />
  </>
);
