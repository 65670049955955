import { Splotch } from 'ui';
import { stringToColor, grey500 } from 'utilities/colors';

export const RoomText = ({ room, isDisabled }) =>
  room ? (
    <>
      <Splotch
        color={isDisabled ? grey500 : stringToColor(room.name)}
        opacity={isDisabled ? 0.8 : 1.0}
      />
      {room.name}
    </>
  ) : (
    '-'
  );
