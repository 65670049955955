export const errorCodes = {
  NURSERY_ADMIN_DOES_NOT_EXIST: 'NURSERY_ADMIN_DOES_NOT_EXIST',
};

export const AccountStatusEnum = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};

export const ActivityFilterActionEnum = {
  ALL: 'ALL',
  UPCOMING: 'UPCOMING',
  PAST: 'PAST',
};

export const BookingFilterActionEnum = {
  ALL: 'ALL',
  UPCOMING: 'UPCOMING',
  PAST: 'PAST',
  NEED_ACTION: 'NEED_ACTION',
  CONFIRMED: 'CONFIRMED',
  ON_HOLD: 'ON_HOLD',
};

export const BookingRejectionEnum = {
  STAFF_ABSENCE: 'STAFF_ABSENCE',
  CHILD_STAFF_RATIO: 'CHILD_STAFF_RATIO',
  ROOM_AT_CAPACITY: 'ROOM_AT_CAPACITY',
  LATE_ON_PAYMENTS: 'LATE_ON_PAYMENTS',
  OTHER: 'OTHER',
};

export const BookingSessionTypeEnum = {
  AM: 'AM',
  PM: 'PM',
  FULL: 'FULL',
};

export const BookingStatusEnum = {
  PENDING: 'PENDING',
  SCHEDULING: 'SCHEDULING',
  CONFIRMED: 'CONFIRMED',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  ON_HOLD: 'ON_HOLD',
};

export const BookingType = {
  INSTANT: 'INSTANT',
  REQUEST: 'REQUEST',
};

export const ChildStatusEnum = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  UNRECOGNIZED: 'UNRECOGNIZED',
  DEACTIVATED: 'DEACTIVATED',
};

export const GuardianStatusEnum = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const PaymentExemptionReasonEnum = {
  EMERGENCY_CARE_SESSION: 'EMERGENCY_CARE_SESSION',
  SETTLE_IN_SESSION: 'SETTLE_IN_SESSION',
  SESSION_IN_LIEU: 'SESSION_IN_LIEU',
  TAX_FREE_SESSION: 'TAX_FREE_SESSION',
};

export const PaymentStatusEnum = {
  Canceled: 'CANCELED',
  Captured: 'CAPTURED',
  Completed: 'COMPLETED',
  InBank: 'IN_BANK',
  Processing: 'PROCESSING',
  RequiresAction: 'REQUIRES_ACTION',
  RequiresCapture: 'REQUIRES_CAPTURE',
  RequiresConfirmation: 'REQUIRES_CONFIRMATION',
  RequiresPaymentMethod: 'REQUIRES_PAYMENT_METHOD',
  Succeeded: 'SUCCEEDED',
};
