import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import { parse as parseQueryString } from 'query-string';

import { AuthContext } from 'app/auth';
import { errorCodes } from 'graphql/constants';
import { profileQuery } from 'graphql/queries';
import { Nursery, NurseryAdminProfile } from 'models';
import { shouldFullstoryRecord } from 'utilities/fullstory';

/**
 * Custom hook that provides all the Nursery Profile logic to the `<ProfileContext.Provider>`
 *
 * This hook should be used to maintain the internal state and methods of the Profile
 * object that gets passed to any components that `useContext(ProfileContext)`.
 */
export const useProfileData = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);

  const [profile, setProfile] = useState(new NurseryAdminProfile());
  const { nurseryId: initialNurseryId } = parseQueryString(history.location.search);
  const [nurseryId, setNurseryId] = useState(initialNurseryId);
  const [nursery, setNursery] = useState(null);
  const { data, refetch, error } = useQuery(profileQuery, {
    fetchPolicy: 'network-only',
    skip: authContext.isInitializing || !authContext.isAuthenticated(),
  });
  const cognitoId = authContext.activeUser?.username;

  useEffect(() => {
    if (!data || !data.nurseryAdmin) return;

    const profileModel = new NurseryAdminProfile(data.nurseryAdmin);

    if (nurseryId) {
      const nurseryData = profileModel.nurseries.find((n) => n.id === nurseryId);

      if (nurseryData) {
        setNursery(new Nursery(nurseryData));
      } else {
        // Default to using the first nursery in the list
        setNursery(new Nursery(profileModel.nurseries[0]));
        // @todo - throw error... Nursery doesn't exist or user doesn't have access
      }
    } else {
      // No nurseryID in query params, so use the first from the list
      setNursery(new Nursery(profileModel.nurseries[0]));
    }

    setProfile(profileModel);

    // Send user ID to FullStory
    if (shouldFullstoryRecord) {
      FullStory.identify(data.nurseryAdmin.id);
    }

    // Send user IDs to Sentry
    Sentry.setUser({
      id: data.nurseryAdmin.id,
      'Cognito ID': cognitoId,
    });
  }, [cognitoId, data, nurseryId]);

  history.listen(({ search }) => {
    const parsedNurseryId = parseQueryString(search).nurseryId;

    if (parsedNurseryId !== nurseryId) {
      setNurseryId(parsedNurseryId);
    }
  });

  const requiresOnboarding =
    (error && error.message === errorCodes.NURSERY_ADMIN_DOES_NOT_EXIST) ||
    profile.nurseries?.length === 0 ||
    false;

  return {
    isInitializing: !profile.isLoaded && !error,
    profile,
    nursery,
    nurseryId,
    refetch,
    requiresOnboarding,
  };
};
