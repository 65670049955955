import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { nurseryPaymentAccountCreate } from 'graphql/mutations';
import { mutationHasErrors } from 'graphql/utils';
import { Blanket, ConfirmationPrompt } from 'ui';

import { useProfile } from 'app/profile';
import { PaymentComponent } from './components/PaymentComponent';

export const PayoutDetails = () => {
  const { nursery, refetch: refetchProfile } = useProfile();

  const [nurseryPaymentAccountCreateMutation, { data, error }] = useMutation(
    nurseryPaymentAccountCreate,
  );

  const [showErrorModal, setShowErrorModal] = useState(false);

  const handleOnboarding = () => {
    nurseryPaymentAccountCreateMutation({ variables: { nurseryId: nursery.id } });
  };

  useEffect(() => {
    if (!data && !error) {
      return;
    }

    const hasErrors = mutationHasErrors({
      innerData: data?.nurseryPaymentAccountCreate,
      error,
    });

    if (hasErrors) {
      setShowErrorModal(true);
      return;
    }

    const onboardingUrl =
      data.nurseryPaymentAccountCreate?.nurseryPaymentAccount?.paymentAccountOnboardingUrl;

    if (isOnboardingUrlValid(onboardingUrl)) {
      window.location.href = onboardingUrl;
    }
  }, [data, error]);

  useEffect(() => {
    // Force refetch of profile if paymentAccount was not properly fetched
    if (!nursery?.paymentAccount?.status) {
      refetchProfile();
    }
  }, [nursery, refetchProfile]);

  return (
    <>
      <h2>Payments</h2>

      <PaymentComponent
        status={nursery?.paymentAccount?.status}
        handleOnboarding={handleOnboarding}
      />

      <ConfirmationPrompt
        confirmationLabel="Close"
        showCancelLabel={false}
        isVisible={showErrorModal}
        message="Something didn't work while setting up your payments. Please close and try again or contact support."
        onConfirm={() => setShowErrorModal(false)}
        title="Oops! Something went wrong."
      />
      <Blanket isVisible={showErrorModal} onClick={() => setShowErrorModal(false)} />
    </>
  );
};

const isOnboardingUrlValid = (url) => {
  const hostname = new URL(url).hostname;
  return hostname === 'connect.stripe.com';
};
