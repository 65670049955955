import React from 'react';
import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconHome = ({ className, color = 'currentColor', size = 24, stroke, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 15 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 5.9L7.3 1L13.6 5.9V13.6C13.6 13.9713 13.4525 14.3274 13.1899 14.5899C12.9274 14.8525 12.5713 15 12.2 15H2.4C2.0287 15 1.6726 14.8525 1.41005 14.5899C1.1475 14.3274 1 13.9713 1 13.6V5.9Z"
      stroke={color}
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.20117 15V8H9.40117V15"
      stroke={color}
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
