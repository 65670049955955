import { useMemo } from 'react';
import classNames from 'classnames';
import { usePopperTooltip } from 'react-popper-tooltip';
import { isAfter } from 'date-fns';

import { Placeholder, SortableTable, Splotch, Dropdown } from 'ui';
import { IconCheck, IconLightningBolt, IconNetwork, IconInfoCircle } from 'icons';
import { BookingStatusEnum } from 'graphql/constants';

import { PaymentTag } from '../PaymentTag';
import styles from './BookingsTable.module.scss';

const NonPrimaryCell = ({ booking, handleRoomChange }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement: 'top',
    });

  const roomOptions = booking?.nursery?.nurseryRooms?.map((room) => ({
    label: room.name,
    value: room.id,
  }));

  const dropdownDisabled =
    isAfter(new Date(), booking.date) ||
    booking.status === BookingStatusEnum.CANCELLED ||
    booking.status === BookingStatusEnum.REJECTED;

  return (
    <>
      <Dropdown
        className={styles.inline}
        variant="outline"
        options={roomOptions}
        value={booking?.room?.id}
        size="small"
        palette="electro"
        disabled={dropdownDisabled}
        onChange={(room) => handleRoomChange(booking, room.value)}
      />

      {!dropdownDisabled && roomOptions.length > 1 && (
        <button type="button" ref={setTriggerRef} className={styles.iconButton}>
          <IconInfoCircle />
        </button>
      )}

      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: styles.tooltipContainer })}>
          You can reassign this child to a different room by selecting another room from the
          dropdown.
          <div {...getArrowProps({ className: styles.tooltipArrow })} />
        </div>
      )}
    </>
  );
};

export const BookingsTable = ({
  bookings,
  loading,
  onSortChange,
  paymentsEnabled,
  sortBy,
  statusActions,
  handleRoomChange,
}) => {
  const columns = useMemo(
    () => [
      SortableTable.column({
        title: 'Child',
        sortKey: 'child',
        extraRow: (booking) =>
          !booking?.isPrimary && (
            <>
              <span className={classNames(styles.heading, styles.bold)}>
                <IconNetwork className={styles.icon} />
                {booking?.child?.primaryNursery?.organization?.name} -{' '}
                {booking?.child.primaryNursery?.name}
              </span>
              <span className={styles.heading}>D.O.B: {booking?.child.birthDate}</span>
              <span className={styles.heading}>Guardian: {booking?.child?.guardian?.fullName}</span>
            </>
          ),
        content: (booking) => booking?.child?.fullName,
      }),

      SortableTable.column({
        title: 'Booking date',
        sortKey: 'date',
        content: (booking) => booking?.dateFormatted,
      }),

      SortableTable.column({
        title: 'Session',
        sortKey: 'sessionType',
        content: (booking) => (
          <>
            {booking?.customSessionType ? booking?.customSessionType.name : booking?.sessionType}
            {booking.isInstantBook && <IconLightningBolt size={16} className={styles.icon} />}
          </>
        ),
      }),

      SortableTable.column({
        title: 'Received',
        sortKey: 'created_at',
        width: 100,
        content: (booking) => booking?.createdAtFormatted,
      }),

      SortableTable.column({
        title: 'Room',
        sortKey: 'room',
        width: 120,
        className: styles.preventWordWrap,
        content: (booking) =>
          booking.isPrimary ? (
            <>
              <Splotch color={booking.roomColor} />
              {booking?.room?.name}
            </>
          ) : (
            <NonPrimaryCell booking={booking} handleRoomChange={handleRoomChange} />
          ),
      }),

      ...(paymentsEnabled
        ? [
            SortableTable.column({
              title: 'Payment',
              width: 120,
              className: styles.preventWordWrap,
              content: (booking) => <PaymentTag booking={booking} />,
            }),
          ]
        : []),

      SortableTable.column({
        title: 'Status',
        className: (booking) => classNames(styles.status, styles[booking.status.toLowerCase()]),
        content: (booking) =>
          statusActions(booking) ?? (
            <>
              {booking?.status === BookingStatusEnum.CONFIRMED && (
                <>
                  <IconCheck size={12} className={styles.icon} />
                  {booking?.statusLabel}
                </>
              )}
              {booking?.status === BookingStatusEnum.REJECTED && booking?.statusLabel}
              {booking?.status === BookingStatusEnum.CANCELLED && booking?.statusLabel}
              {booking?.status === BookingStatusEnum.ON_HOLD && booking?.statusLabel}
            </>
          ),
      }),
    ],
    [paymentsEnabled, statusActions, handleRoomChange],
  );

  if (loading) {
    return <LoadingPlaceholder />;
  }

  return (
    <SortableTable
      data={bookings}
      columns={columns}
      onSort={onSortChange}
      sortBy={sortBy}
      className={styles.container}
    />
  );
};

const LoadingPlaceholder = ({ columnCount = 10, rows = Array.from({ length: columnCount }) }) => {
  const columns = [
    SortableTable.column({
      title: 'Child',
      content: () => <Placeholder width={120} />,
    }),

    SortableTable.column({
      title: 'Booking date',
      content: () => <Placeholder width={116} />,
    }),

    SortableTable.column({
      title: 'Session Time',
      content: () => <Placeholder width={32} />,
    }),

    SortableTable.column({
      title: 'Type',
      content: () => <Placeholder width={80} />,
    }),

    SortableTable.column({
      title: 'Room',
      className: styles.preventWordWrap,
      content: () => <Placeholder width={80} />,
    }),

    SortableTable.column({
      title: 'Payment',
      className: styles.preventWordWrap,
      content: () => <Placeholder width={80} />,
    }),

    SortableTable.column({
      title: 'Status',
      className: styles.status,
      content: () => <Placeholder width={80} height={32} />,
    }),
  ];

  return <SortableTable data={rows} columns={columns} className={styles.loadingPlaceholder} />;
};
