import classNames from 'classnames';

import { Placeholder } from 'ui';

import styles from './AlertTile.module.scss';

const LoadingPlaceholder = () => (
  <div className={styles.container}>
    <div className={styles.iconContainer} />

    <div className={styles.tile}>
      <div className={styles.loadingPlaceholders}>
        <Placeholder className={styles.heading} width={216} height={26} />
        <Placeholder width={175} />
        <Placeholder width={137} />
      </div>
    </div>
  </div>
);

export const AlertTile = ({
  actions,
  actionRequired,
  className,
  children,
  icon: Icon,
  loading,
  showRedDot,
  title,
}) =>
  loading ? (
    <LoadingPlaceholder />
  ) : (
    <div
      className={classNames(
        styles.container,
        actionRequired ? styles.actionRequired : styles.allGood,
        className,
      )}
    >
      {Icon && (
        <div className={styles.iconContainer}>
          <div className={styles.icon}>
            <Icon size={24} className={styles.icon} />

            <div className={classNames(styles.redDot, showRedDot && styles.visible)} />
          </div>
        </div>
      )}

      <div className={styles.tile}>
        <div className={styles.tileContent}>
          <h3 className={styles.heading}>{title}</h3>
          {children}
        </div>

        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
    </div>
  );

AlertTile.defaultProps = {
  actionRequired: false,
  showRedDot: false,
};
