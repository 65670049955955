import { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { AuthContext } from 'app/auth';

export const Landing = () => {
  const authContext = useContext(AuthContext);

  if (authContext.isInitializing) return null;

  return <Redirect to={authContext.isAuthenticated() ? '/home' : '/login'} />;
};
