import { createContext, useEffect, useState } from 'react';

const FEATURE_FLAGS = {};

export const FeatureFlagContext = createContext(FEATURE_FLAGS);

const validFlagValues = /(on|off)/i;

const getFlagValue = (flagName, defaultValue) => {
  // Precedence:
  // 1. Default value
  // 2. Value from localStorage (because localStorage can override at runtime)
  // 3. Value from process variables

  const valueFromProcessVariables = process.env[`REACT_APP_${flagName.toUpperCase()}`];

  const valueFromLocalStorage = localStorage.getItem(flagName);

  if (validFlagValues.test(valueFromLocalStorage)) {
    return valueFromLocalStorage === 'on' ? true : false;
  }

  if (validFlagValues.test(valueFromProcessVariables)) {
    return valueFromProcessVariables === 'on' ? true : false;
  }

  return defaultValue;
};

/**
 * Iterates through each feature flag and checks to see if it's been turned on anywhere
 *
 * This function first checks for environment variables, then localStorage, then uses the default
 * state from FEATURE_FLAGS
 */
const buildInitialFlagState = () =>
  Object.entries(FEATURE_FLAGS).reduce((flags, [flagName, defaultValue]) => {
    flags[flagName] = getFlagValue(flagName, defaultValue);

    return flags;
  }, {});

/**
 * Custom context that provides feature flag functionality
 *
 * This context currently uses localStorage as a way to toggle features locally. This
 * leaves room to grow into if it becomes desirable to hook up to an API.
 */
export const FeatureFlagProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState(buildInitialFlagState());

  useEffect(() => {
    const handler = window.addEventListener('storage', ({ key, newValue }) => {
      // Detect whether the changed key was a flag that we care about
      const isValidFlag = Object.keys(featureFlags).includes(key);

      if (!isValidFlag) return;

      // When a valid feature flag is added to storage and it's value is `on`
      if (newValue === 'on') {
        setFeatureFlags({
          ...featureFlags,
          [key]: true,
        });
      }

      // When a valid feature flag was deleted from storage, or text was changed to not `on`
      if (newValue === null || newValue !== 'on') {
        setFeatureFlags({
          ...featureFlags,
          [key]: false,
        });
      }
    });

    return () => window.removeEventListener('storage', handler);
  }, [featureFlags, setFeatureFlags]);

  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
};
