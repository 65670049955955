import { NavLink } from 'react-router-dom';

import { DefaultAppPage } from 'layouts';

export const Account = () => {
  return (
    <DefaultAppPage title="Account">
      <ul>
        <li>
          <NavLink to="/logout">Logout</NavLink>
        </li>
      </ul>
    </DefaultAppPage>
  );
};
