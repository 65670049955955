import classNames from 'classnames';

import { useProfile } from 'app/profile';
import { NurserySwitcher } from 'components';

import styles from './DefaultAppPage.module.scss';

export const DefaultAppPage = ({
  children,
  contentClass,
  intro,
  headerClass,
  title,
  useNurserySwitcher,
  ...props
}) => {
  const { profile } = useProfile();

  const overrideParamForNoNurseryAccess = () => {
    intro = 'Please contact Pebble Support to complete your onboarding';
    title = 'Onboarding Required';
    headerClass = null;
    useNurserySwitcher = false;
    children = null;
  };

  if (useNurserySwitcher && profile.nurseries.length === 0) {
    overrideParamForNoNurseryAccess();
  }

  return (
    <div {...props} className={styles.container}>
      <header className={classNames(styles.header, headerClass)}>
        <div className={styles.headerWrapper}>
          <div className={styles.pageIntro}>
            {title && <h1 className={styles.title}>{title}</h1>}
            {intro && <div className={styles.introText}>{intro}</div>}
          </div>
          {useNurserySwitcher && <NurserySwitcher className={styles.nurserySwitcher} />}
        </div>
      </header>

      <div className={classNames(styles.content, contentClass)}>{children}</div>
    </div>
  );
};

DefaultAppPage.defaultProps = {
  useNurserySwitcher: false,
  intro: null,
};
