import { NavLink } from 'react-router-dom';

import { IconBack } from 'icons';
import { PublicPageWithIllustration } from 'layouts';
import { Button, Input } from 'ui';
import { electro600 } from 'utilities/colors';
import Page from 'pages/elements';

import styles from './ResetPassword.module.scss';

export const ResetPassword = ({
  formData,
  formErrors,
  goToPreviousScreen,
  handleResetPassword,
  isLoading,
  onChange,
}) => (
  <PublicPageWithIllustration>
    <div className={styles.container}>
      <NavLink to="/forgot-password" onClick={goToPreviousScreen} className={styles.link}>
        <IconBack size={14} className={styles.linkIcon} color={electro600} />
        Back
      </NavLink>

      <h1>Reset password</h1>

      <p>We have sent a password reset code by email. Enter it below to reset your password.</p>

      <div className={styles.inputWrapper}>
        <label className={styles.label}>
          Code
          <Input
            placeholder="Code"
            value={formData.code}
            onChange={(e) => onChange('code', e.target.value)}
            hasError={!!formErrors?.invalidOtp}
          />
        </label>

        <Page.ErrorMessage isVisible={!!formErrors?.invalidOtp} className={styles.errorMessage}>
          The code is invalid. Please try again.
        </Page.ErrorMessage>

        <label htmlFor="newPassword" className={styles.label}>
          New password
        </label>
        <small>Min 8 characters, with uppercase and lowercase and a number</small>
        <Input
          id="newPassword"
          placeholder="New Password"
          type="password"
          value={formData.newPassword}
          onChange={(e) => onChange('newPassword', e.target.value)}
          hasError={!!formErrors?.newPassword}
        />

        <Page.ErrorMessage isVisible={!!formErrors?.newPassword}>
          This password does not meet the requirements.
        </Page.ErrorMessage>

        <label className={styles.label}>
          Enter new password again
          <Input
            type="password"
            placeholder="Re-type Password"
            value={formData.confirmPassword}
            onChange={(e) => onChange('confirmPassword', e.target.value)}
            hasError={!!formErrors?.confirmPassword}
          />
        </label>

        <Page.ErrorMessage isVisible={!!formErrors?.confirmPassword}>
          This password doesn’t match the first one. Please try again.
        </Page.ErrorMessage>
      </div>

      <Button
        className={styles.button}
        disabled={
          isLoading ||
          formData.code?.trim().length === 0 ||
          formData.newPassword?.trim().length === 0 ||
          formData.confirmPassword?.trim().length === 0
        }
        expand
        onClick={handleResetPassword}
      >
        Change password
      </Button>
    </div>
  </PublicPageWithIllustration>
);

ResetPassword.screenKey = 'RESET_PASSWORD';
