import React from 'react';
import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconCalendar = ({
  className,
  color = 'currentColor',
  size = 24,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 15 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.2 2.40015H2.4C1.6268 2.40015 1 3.02695 1 3.80015V13.6001C1 14.3733 1.6268 15.0001 2.4 15.0001H12.2C12.9732 15.0001 13.6 14.3733 13.6 13.6001V3.80015C13.6 3.02695 12.9732 2.40015 12.2 2.40015Z"
      stroke={color}
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.0996 1V3.8"
      stroke={color}
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 1V3.8"
      stroke={color}
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 6.59985H13.6"
      stroke={color}
      fill="transparent"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
