import React from 'react';
import classNames from 'classnames';

import styles from './Table.module.scss';

export const Table = ({ className, headerTitles, rows }) => {
  return (
    <table className={classNames(styles.table, className)}>
      <thead>
        <tr>
          {headerTitles.map((title, columnIndex) => {
            return (
              <th key={columnIndex} className={styles.heading}>
                {title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>{rows.map((row) => row)}</tbody>
    </table>
  );
};

Table.row = ({ cells, className, ...rowProps }) => ({
  cells,
  className,
  rowProps,
});

Table.cell = ({ className, content, ...cellProps }) => ({
  className,
  content,
  cellProps,
});

Table.Row = ({ className, cells, rowIndex, rowProps }) => (
  <tr key={rowIndex} className={className} {...rowProps}>
    {cells.map(({ cellProps, className, colSpan, content }, cellIndex) => {
      return (
        <td key={cellIndex} className={className} colSpan={colSpan} {...cellProps}>
          {content}
        </td>
      );
    })}
  </tr>
);
