import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconClock = ({ className, color = 'currentColor', size = 24, stroke, ...props }) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.1875 21.7656C17.1581 21.7656 21.1875 17.7362 21.1875 12.7656C21.1875 7.79506 17.1581 3.76562 12.1875 3.76562C7.21694 3.76562 3.1875 7.79506 3.1875 12.7656C3.1875 17.7362 7.21694 21.7656 12.1875 21.7656Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1875 8.91016V12.7673L14.1161 14.6959"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
