import { useQuery } from '@apollo/client';
import { addDays, format, parseISO } from 'date-fns';

import { BookingFilterActionEnum } from 'graphql/constants';
import { getNurseryBookings } from 'graphql/queries';
import { IconCheckCircle, IconNetwork } from 'icons';
import { Splotch, Placeholder } from 'ui';
import { getMonday } from 'utilities/dates';

import styles from './BookingsTile.module.scss';
import { stringToColor } from 'utilities/colors';

const startOfThisWeek = getMonday(new Date());
const endOfThisWeek = addDays(startOfThisWeek, 7 - 1); // minus one because calendar starts on Sunday
const placeholderRows = Array.from({ length: 8 }); // determines how many rows to use for placeholder

export const BookingsTile = ({ nurseryId }) => {
  const { data: bookingsData, loading } = useQuery(getNurseryBookings, {
    variables: {
      nurseryId,
      filterAction: BookingFilterActionEnum.CONFIRMED,
      sortBy: 'date_asc',
      startDate: format(startOfThisWeek, 'yyyy-MM-dd'),
      endDate: format(endOfThisWeek, 'yyyy-MM-dd'),
    },
  });

  const data = bookingsData?.bookingsByNursery;

  if (loading) {
    return (
      <div className={styles.container}>
        <Placeholder width={40} height={40} className={styles.placeholderIcon} />

        <div className={styles.heading}>
          <Placeholder width={216} height={26} />
        </div>

        <Placeholder width={216} />

        <Placeholder width="100%" height={1} className={styles.placeholderDivider} />

        {placeholderRows.map((_, i) => (
          <Placeholder width="100%" height={24} key={i} className={styles.placeholderRow} />
        ))}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
        <IconCheckCircle className={styles.checkIcon} size={20} />
      </div>
      <h2 className={styles.heading}>Who’s coming this week?</h2>

      <div className={styles.bookings}>
        <table className={styles.bookingsTable}>
          <tbody>
            {data?.map((booking) => (
              <tr key={booking.id}>
                <td>
                  <div className={styles.flex}>
                    {booking?.child?.fullName}
                    {!booking.isPrimary && <IconNetwork />}
                  </div>
                </td>
                <td>
                  <Splotch color={stringToColor(booking.room?.name)} />
                  {booking?.room?.name}
                </td>
                <td>{format(parseISO(booking?.date), 'EEEE dd MMMM')}</td>
                <td>
                  {booking?.customSessionType
                    ? booking?.customSessionType?.name
                    : booking?.sessionType}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {data?.length === 0 && (
        <div className={styles.emptyState}>
          <p>
            You don’t have any confirmed bookings yet for this week. Once you do, they will appear
            here.
          </p>
        </div>
      )}
    </div>
  );
};
