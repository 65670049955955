import { Button } from 'ui';
import { trackAction, actions } from '../../../../app/amplitude';

export const ContractCancellation = () => (
  <>
    <h2>Contract cancellation</h2>
    <p>Let us know if you no longer want to be a Pebble partner.</p>

    <Button
      size="large"
      href="https://docs.google.com/forms/d/e/1FAIpQLScboaPaNOVVYqscy7t0NWm-hzrfQ3R2dQqFUQ8ADeHLx_ntTQ/viewform"
      target="__cancellation"
      onClick={() => {
        trackAction(actions.CANCEL_CONTRACT);
      }}>
      Cancel contract
    </Button>
  </>
);
