/**
 * Small class that sorts data from graphql into a way that's easier to consume by our components.
 *
 * Iterates through all the data that gets sent by the backend and creates an object in the following
 * shape:
 * {
 *   roomId: {
 *     room.id,
 *     room.name,
 *     availabilityByDay: {
 *       'yyyy-mm-dd': {
 *         amAvailability,
 *         pmAvailability,
 *         remainingAmSpots,
 *         remainingPmSpots,
 *       }
 *     }
 *   }
 * }
 */
export class SessionAvailabilityWorksheet {
  constructor(availabilityForNurseryAdmin) {
    const buckets = {};

    for (const { date, rooms } of availabilityForNurseryAdmin) {
      rooms.forEach(({ nurseryRoom, ...availability }) => {
        if (!buckets[nurseryRoom.id]) {
          buckets[nurseryRoom.id] = {
            ...nurseryRoom,
            availabilityByDay: {},
          };
        }

        buckets[nurseryRoom.id].availabilityByDay[date] = { ...availability };
      });
    }

    this.byRoom = buckets;
  }
}
