import { useMemo } from 'react';
import classNames from 'classnames';

import styles from './RoomPlanningGrid.module.scss';

export const RoomPlanningGrid = ({ datesByWeek, renderCell, rooms, title }) => {
  // Detect the first business day so it can be auto-focused for accessibility
  const firstRoomId = rooms?.[0]?.id;
  const firstBusinessDay = useMemo(() => {
    const allDays = datesByWeek.flat();

    return allDays.find((a) => {
      if (a.isBeforeToday || a.isToday || a.isWeekend) return false;

      return a;
    });
  }, [datesByWeek]);

  const shouldAutoFocus = (d, room) =>
    firstBusinessDay.dateStamp === d.dateStamp && room.id === firstRoomId;

  return (
    <main className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>

        <div className={styles.legend}>
          <div className={styles.instant}>Instant bookings available</div>
          <div className={styles.noCapacity}> No capacity</div>
        </div>
      </div>

      {datesByWeek.map((daysOfWeek, weekIndex) => (
        <div className={styles.weekRow} key={weekIndex}>
          <div className={styles.roomLabels}>
            {rooms.map(({ id, name }) => (
              <div className={styles.roomLabel} key={id}>
                {name}
              </div>
            ))}
          </div>

          {daysOfWeek.map((day) => (
            <div
              className={classNames(styles.day, day.isToday && styles.today)}
              key={day.dateStamp}>
              <div className={styles.dateLabel}>{day.label}</div>

              <div className={classNames(styles.dayRow, day.isBeforeToday && styles.beforeToday)}>
                <div className={styles.sessionLabel}>AM</div>
                <div className={styles.sessionLabel}>PM</div>
              </div>

              {rooms.map((room) => (
                <div className={styles.dayRow} key={room.id}>
                  <div className={styles.sessionCell}>
                    {renderCell({
                      day,
                      room,
                      session: 'am',
                      shouldAutoFocus: shouldAutoFocus(day, room),
                    })}
                  </div>
                  <div className={styles.sessionCell}>
                    {renderCell({
                      day,
                      room,
                      session: 'pm',
                      shouldAutoFocus: false,
                    })}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </main>
  );
};
