import { forwardRef, createElement } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { outlineSuppressionHandlers, ThreeDots } from 'ui';

import styles from './Button.module.scss';

export const Button = forwardRef(
  (
    {
      children,
      className,
      expand,
      href,
      isWorking,
      onClick,
      prefix,
      size,
      suffix,
      type,
      ...buttonProps
    },
    ref,
  ) => {
    const buttonClass = classNames(
      styles.button,
      styles[size],
      styles[type],
      expand && styles.expanded,
      isWorking && styles.working,
      className,
    );

    const handleClick = (e) => {
      if (isWorking) {
        e.preventDefault();
        e.stopPropagation();

        return;
      }

      if (typeof onClick === 'function') onClick(e);
    };

    const buttonContent = isWorking ? (
      <span className={styles.workingIndicator}>
        <ThreeDots />
      </span>
    ) : (
      <>
        {prefix && <span className={styles.prefix}>{prefix}</span>}
        {children && <span className={styles.label}>{children}</span>}
        {suffix && <span className={styles.suffix}>{suffix}</span>}
      </>
    );

    const props = {
      ...outlineSuppressionHandlers,
      ...buttonProps,
      ref,
      className: buttonClass,
      onClick: handleClick,
    };

    let element = 'button';

    if (href) {
      // Remove button `type` prop if it was set
      delete props.type;

      if (href.startsWith('/')) {
        element = Link;
        props.to = href;
      } else {
        element = 'a';
        props.href = href;
      }
    }

    return createElement(element, props, buttonContent);
  },
);

Button.defaultProps = {
  expand: false,
  isWorking: false,
  size: 'medium', // One of 'small', 'medium', or 'large'
  type: 'primary', // One of 'primary', 'secondary', or 'nav'
};
