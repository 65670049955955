import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from 'app/auth';
import { FullScreen, Pebbles } from 'ui';

/**
 * Higher-order component that accepts an auth object. If the user is authenticated, redirects
 * the user to the specified `redirectTo` route.
 */
export const AnonymousRoute = ({ component: Component, redirectTo, ...rest }) => {
  const authContext = useContext(AuthContext);

  if (authContext.isInitializing) {
    return (
      <FullScreen>
        <Pebbles fillSpace />
      </FullScreen>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authContext.isAuthenticated() === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: redirectTo, state: { from: props.location } }} />
        )
      }
    />
  );
};

AnonymousRoute.defaultProps = {
  redirectTo: '/home',
};
