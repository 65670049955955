import { createContext } from 'react';

import { useAuth } from './';

export const AuthContext = createContext({
  activeUser: null,
  isInitializing: true,
  isAuthenticated: () => {},
  login: async () => {},
  logout: async () => {},
  changePassword: async () => {},
  signUp: async () => {},
});

export const AuthProvider = ({ children }) => (
  <AuthContext.Provider value={useAuth()}>{children}</AuthContext.Provider>
);
