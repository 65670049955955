import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { getPendingRequestsSummary } from 'graphql/queries';
import { IconEnvelope } from 'icons';
import { Alert, Button, Placeholder } from 'ui';

import { AlertTile } from '../../components/AlertTile';
import styles from './ToDoTile.module.scss';
import { trackAction, actions } from 'app/amplitude';

export const ToDoTile = ({ nurseryId }) => {
  const { data = {}, loading } = useQuery(getPendingRequestsSummary, {
    variables: {
      nurseryId,
    },
  });

  const { totalPendingRequestsUpcoming, totalPendingRequestsUpcomingUrgent } = useMemo(
    () => ({
      totalPendingRequestsUpcoming:
        data?.bookingsByNurserySummary?.totalPendingRequestsUpcoming ?? 0,
      totalPendingRequestsUpcomingUrgent:
        data?.bookingsByNurserySummary?.totalPendingRequestsUpcomingUrgent ?? 0,
    }),
    [data.bookingsByNurserySummary],
  );

  const content = (
    <>
      {totalPendingRequestsUpcoming > 0 ? (
        <>
          Requiring your attention:
          <br />
          <strong>{totalPendingRequestsUpcoming} new booking requests</strong>
        </>
      ) : (
        <strong>You have responded to all booking requests. You’re all up to date! 👍🎉</strong>
      )}

      {totalPendingRequestsUpcomingUrgent > 0 && (
        <Alert className={styles.alert}>
          {totalPendingRequestsUpcomingUrgent} overdue requests
        </Alert>
      )}
    </>
  );

  return (
    <AlertTile
      loading={loading}
      title="Your to-do list"
      className={styles.container}
      actionRequired={totalPendingRequestsUpcoming !== 0}
      actions={
        totalPendingRequestsUpcoming > 0 ? (
          <Button
            href={`/bookings?nurseryId=${nurseryId}`}
            onClick={() => trackAction(actions.RESPOND_NOW)}>
            Respond now
          </Button>
        ) : null
      }
      icon={IconEnvelope}
      showRedDot={totalPendingRequestsUpcoming > 0}>
      {loading ? (
        <div className={styles.loading}>
          <Placeholder width={120} />
          <Placeholder width={140} />
          <Placeholder width={80} />
        </div>
      ) : (
        content
      )}
    </AlertTile>
  );
};
