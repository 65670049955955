import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { format, parseJSON } from 'date-fns';

import { getTotalRemainingAvailability } from 'graphql/queries';
import { Placeholder } from 'ui';

import { StatTile } from '../../components';

export const SpacesLeftTile = ({ nurseryId }) => {
  const { data = {}, loading } = useQuery(getTotalRemainingAvailability, {
    variables: {
      nurseryId,
    },
  });

  const { lastEditedAt, totalRemainingAvailability } = useMemo(() => {
    let lastEditedAt = <Placeholder width={120} />;
    try {
      lastEditedAt = format(
        parseJSON(data?.availabilityForNurseryAdminSummary?.lastUpdatedAt),
        'dd | MM | yyyy',
      );
    } catch {
      lastEditedAt = 'Never';
    }
    return {
      totalRemainingAvailability:
        data?.availabilityForNurseryAdminSummary?.totalRemainingAvailability ?? 0,
      lastEditedAt: lastEditedAt,
    };
  }, [data.availabilityForNurseryAdminSummary]);

  return (
    <StatTile
      loading={loading}
      title="Spaces left to fill"
      number={totalRemainingAvailability}
      footer={
        <>
          Based on the availability planner.
          <br />
          Last updated: {lastEditedAt}
        </>
      }>
      In your planner
    </StatTile>
  );
};
