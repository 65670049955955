import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';

const cache = new InMemoryCache();
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  return Auth.currentSession().then((session) => {
    const token = session.getIdToken().getJwtToken();
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  request: async (operation) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();

    operation.setContext({
      headers: {
        Authorization: `${token}`,
      },
    });
  },
});

export default client;
