import { Dropdown } from 'ui';

import styles from './RoomDropdown.module.scss';

export const RoomDropdown = ({ child, childFormData, roomOptions, setChildFormData }) => (
  <Dropdown
    className={styles.roomDropdown}
    onChange={(room) =>
      setChildFormData({
        id: child.id,
        roomId: room.value,
      })
    }
    options={roomOptions}
    value={childFormData?.roomId || child.primaryNurseryRoom?.id || null}
    size="small"
    palette="grey"
    variant="outline"
  />
);
