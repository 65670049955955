import { format, parse } from 'date-fns';

import Page from 'pages/elements';
import { IconCheckCircle } from 'icons';

import styles from './CustomSessionWidget.module.scss';

const formatSessionTime = (time = '00:00:00') =>
  format(parse(time, 'HH:mm:ss', new Date()), 'HH:mm');

const sortSessionTypesByStartTime = (a, b) => {
  if (a.startTime > b.startTime) {
    return 1;
  }
  if (a.startTime < b.startTime) {
    return -1;
  }
  return 0;
};

export const CustomSessionWidget = ({ sessionTypes }) => (
  <div className={styles.customSessionWidget}>
    <Page.Heading element="h3">
      <IconCheckCircle size={16} className={styles.customSessionWidgetIcon} />
      <br />
      Sessions
    </Page.Heading>
    <table>
      <thead>
        <tr>
          <th>Time</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {sessionTypes.sort(sortSessionTypesByStartTime).map((sessionType, index) => (
          <tr key={`session-type-${index}`}>
            <td width={'50%'}>
              {formatSessionTime(sessionType.startTime)} - {formatSessionTime(sessionType.endTime)}
            </td>
            <td>{sessionType.name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
