import { useRef, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { usePortal, useKeyPress } from 'hooks';
import { CloseButton } from 'ui';

import styles from './Dialog.module.scss';

export const Dialog = ({ children, isVisible, menu, onClose, title, zIndex }) => {
  const target = usePortal('dialogs');
  const containerRef = useRef(null);
  const dialogStyle = useMemo(() => (zIndex && isVisible ? { zIndex } : null), [isVisible, zIndex]);

  useKeyPress('Escape', onClose);

  useEffect(() => {
    if (isVisible) {
      // For accessibility, focus the container element so that the user can use
      // the Tab key to correctly hit the first element in the newly visible modal
      containerRef.current.focus();
    }
  }, [isVisible]);

  const content = (
    <div className={styles.container} tabIndex="0" ref={containerRef} style={dialogStyle}>
      <AnimatePresence>
        {isVisible && (
          <motion.div
            role="dialog"
            aria-live="polite"
            aria-modal="true"
            className={styles.dialog}
            originX={0.5}
            originY={0.5}
            variants={animationVariants}
            initial="initial"
            animate="visible"
            exit="hidden"
            style={dialogStyle}
          >
            <div className={styles.dialogContent}>
              {title && <h3 className={styles.title}>{title}</h3>}

              {children}

              {menu && <div className={styles.menu}>{menu}</div>}

              {onClose && <CloseButton onClick={onClose} className={styles.closeButton} />}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );

  return createPortal(content, target);
};

const animationVariants = {
  initial: {
    translateY: 50,
    opacity: 0,
  },
  visible: {
    opacity: 1,
    translateY: 0,
  },
  hidden: {
    opacity: 0,
  },
};
