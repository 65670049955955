import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconCaretDown = ({
  className,
  color = 'currentColor',
  size = 24,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 6 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 0H0.75C0.440983 0 0.26459 0.352786 0.45 0.6L2.6 3.46667C2.8 3.73333 3.2 3.73333 3.4 3.46667L5.4 0.8C5.64721 0.470382 5.41202 0 5 0Z"
      fill={color}
    />
  </svg>
);
