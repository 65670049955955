import { NavLink } from 'react-router-dom';

import { useAnalytics, ANALYTICS_EVENTS } from 'app/analytics';
import { useProfile } from 'app/profile';
import { PebbleLogo } from 'components';
import { IconCalendar, IconCheckCircle, IconCog, IconHome, IconGuardians } from 'icons';
import { Button } from 'ui';

import styles from './Sidebar.module.scss';
import { trackAction, actions } from 'app/amplitude';

export const Sidebar = ({ children }) => {
  const { nursery } = useProfile();
  const { trackEvent } = useAnalytics();

  const nurseryId = nursery?.id;

  const handleContactSupportClick = () => {
    trackEvent(ANALYTICS_EVENTS.CONTACT_SUPPORT_CLICKED);
    trackAction(actions.CONTACT_SUPPORT);
  };

  return (
    <>
      <div className={styles.sidebar}>
        <PebbleLogo height={82} className={styles.pebbleLogo} variant="white" />

        <nav className={styles.links}>
          <SidebarLink
            label="Home"
            icon={IconHome}
            url={`/home?nurseryId=${nurseryId}`}
            onClick={() => trackAction(actions.HOME_SELECTION)}
          />
          <SidebarLink
            label="Availability Planner"
            icon={IconCalendar}
            url={`/availability?nurseryId=${nurseryId}`}
            onClick={() => trackAction(actions.AVAILABILITY_PLANNER_SELECTION)}
          />
          <SidebarLink
            label="Bookings"
            icon={IconCheckCircle}
            url={`/bookings?nurseryId=${nurseryId}`}
            onClick={() => trackAction(actions.BOOKINGS_PAGE_SELECTION)}
          />
          <SidebarLink
            label="Guardian List"
            icon={IconGuardians}
            url={`/guardians?nurseryId=${nurseryId}`}
            onClick={() => {
              trackAction(actions.GUARDIAN_LIST_SELECTION);
              trackEvent(ANALYTICS_EVENTS.GUARDIAN.GUARDIAN_LIST);
            }}
          />

          <SidebarLink
            label="Settings"
            icon={IconCog}
            url={`/settings?nurseryId=${nurseryId}`}
            onClick={() => trackAction(actions.SETTINGS_SELECTION)}
          />
        </nav>

        <footer className={styles.helpArea}>
          <strong>Need help?</strong>
          <p>Contact our support team and get help with anything you need.</p>

          <Button
            type="nav"
            className={styles.supportLink}
            href="mailto:support@bookpebble.co.uk"
            onClick={handleContactSupportClick}
            target="__supportWindow">
            Contact support
          </Button>
        </footer>
      </div>

      <div className={styles.container}>{children}</div>
    </>
  );
};

export const SidebarLink = ({ label, icon: Icon, url, onClick }) => (
  <NavLink to={url} className={styles.link} activeClassName={styles.activeLink} onClick={onClick}>
    <Icon size={14} className={styles.linkIcon} />
    {label}
  </NavLink>
);
