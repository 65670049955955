import classNames from 'classnames';

import InputMask from 'react-input-mask';

import styles from './Input.module.scss';
import { IconTimes } from '../../icons/IconTimes';

export const Input = ({ mask, hintText, className, hasError, ...props }) =>
  mask ? (
    <InputMask mask={mask} className={styles.input} {...props} />
  ) : (
    <>
      {hintText && <div className={styles.hintText}>{hintText}</div>}

      <div className={styles.container}>
        <input className={classNames(styles.input, className)} {...props} />
        {hasError && <IconTimes className={styles.iconTimes} />}
      </div>
    </>
  );

Input.defaultProps = {
  type: 'text',
  hasError: false,
};
