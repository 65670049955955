import { useState } from 'react';

import { useAuth } from 'app/auth';
import { Wizard } from 'ui';
import { passwordMatcher } from 'utilities/regexes';

import { EnterEmail } from './screens/EnterEmail/index';
import { ResetPassword } from './screens/ResetPassword/index';
import { ResetPasswordConfirmation } from './screens/ResetPasswordConfirmation/index';

const screens = [EnterEmail, ResetPassword, ResetPasswordConfirmation];

export const ForgotPassword = () => {
  const auth = useAuth();
  const [screen, setScreen] = useState(screens[0].screenKey);
  const [formData, setFormData] = useState({
    email: '',
    code: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const initiatePasswordReset = async () => {
    setIsLoading(true);
    try {
      await auth.forgotPasswordInitiate(formData.email);
    } catch (error) {
      console.log('[ForgotPassword] initiatePasswordReset failure', error);
    }
    setIsLoading(false);
  };

  const resetPassword = async () => {
    const { code, email, newPassword } = formData;

    try {
      await auth.forgotPasswordSubmit(code, email, newPassword);

      setScreen(ResetPasswordConfirmation.screenKey);
    } catch (error) {
      setFormErrors({ ...formErrors, invalidOtp: true });
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    setFormErrors({});

    const errors = {};

    if (!passwordMatcher.test(formData.newPassword)) {
      errors.newPassword = true;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      errors.confirmPassword = true;
    }

    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      await resetPassword();
    }

    setIsLoading(false);
  };

  return (
    <Wizard
      currentScreen={screen}
      formData={formData}
      formErrors={formErrors}
      handleResetPassword={handleResetPassword}
      initiatePasswordReset={initiatePasswordReset}
      isLoading={isLoading}
      setFormData={setFormData}
      setFormErrors={setFormErrors}
      setScreen={setScreen}
      screens={screens}
      useHistoryApi={false}
    />
  );
};
