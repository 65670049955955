import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import emailValidator from 'email-validator';

import { AuthContext } from 'app/auth';
import { PublicPageWithIllustration } from 'layouts';
import { Blanket, Button, Dialog, LinkButton, TextInput } from 'ui';

import styles from './VerifyEmail.module.scss';

export const VerifyEmail = () => {
  const history = useHistory();
  const { confirmSignup, isInitializing, resendVerificationCode } = useContext(AuthContext);
  const [verficationCode, setVerificationCode] = useState('');
  const [isReSending, setIsReSending] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const emailFromState = history?.location?.state?.email;
  const [email, setEmail] = useState(emailFromState ?? '');

  const isEmailValid = emailValidator.validate(email);
  const isFormValid = verficationCode.length === 6 && isEmailValid;

  const handleVerifyClick = async () => {
    setShowErrors(false);

    if (!isFormValid) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);
    setShowErrorDialog(false);

    try {
      await confirmSignup(email, verficationCode);

      history.push('/login', { email });
    } catch (error) {
      console.log('[VerifyAccount] Failed to verify account', error);
      setShowErrorDialog(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendLinkClick = async () => {
    setIsReSending(true);

    try {
      await resendVerificationCode(email);
    } catch (error) {
      console.log('[VerifyAccount] Error requesting new verification code', error);
    } finally {
      setIsReSending(false);
    }
  };

  return (
    <PublicPageWithIllustration title="Verification">
      {emailFromState && (
        <p>
          We have sent a code to verify your account.
          <br />
          Enter the 6 digit code sent to {emailFromState}
        </p>
      )}

      {!emailFromState && (
        <TextInput
          label="Your nursery email"
          type="email"
          placeholder="your.name@mail.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={styles.textInput}
          hasError={showErrors && !isEmailValid}
        />
      )}

      <TextInput
        label="6 digit code"
        value={verficationCode}
        onChange={(e) => setVerificationCode(e.target.value)}
        className={styles.textInput}
        maxLength={6}
        hasError={showErrors && !verficationCode}
      />

      <LinkButton
        className={styles.resendVerificationLink}
        onClick={handleResendLinkClick}
        disabled={!isEmailValid || isReSending}
      >
        Resend verification code
      </LinkButton>

      <Button
        className={styles.loginButton}
        palette="pink"
        disabled={isInitializing || isLoading}
        isWorking={isLoading}
        expand
        onClick={handleVerifyClick}
      >
        Verify
      </Button>

      <Dialog
        title="Verification issue"
        isVisible={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        menu={
          <Button onClick={() => setShowErrorDialog(false)} expand palette="pink">
            Ok
          </Button>
        }
      >
        <p>
          The verification code you provided is incorrect. You can try requesting a new code, or
          contact us for help{' '}
          <a href="mailto:support@bookpebble.co.uk" className={styles.supportLink}>
            support@bookpebble.co.uk
          </a>
        </p>
      </Dialog>

      <Blanket isVisible={showErrorDialog} />
    </PublicPageWithIllustration>
  );
};
