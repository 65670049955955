import { useState, useContext } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { AuthContext } from 'app/auth';

import { useProfile } from 'app/profile';
import { useAnalytics, ANALYTICS_EVENTS } from 'app/analytics';
import { PublicPageWithIllustration } from 'layouts';
import { Button, TextInput } from 'ui';
import Page from 'pages/elements';

import styles from './Login.module.scss';

export const Login = () => {
  const { refetch: fetchProfile } = useProfile();
  const history = useHistory();
  const { trackEvent } = useAnalytics();
  const [email, setEmail] = useState(history?.location?.state?.email ?? '');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const authContext = useContext(AuthContext);
  const redirectTo =
    history?.location?.state?.from?.pathname + history.location?.state?.from?.search;

  const handleLogin = async (e) => {
    e.preventDefault();

    setShowError(false);
    setIsLoading(true);

    try {
      const loginResults = await authContext.login(email, password);

      await fetchProfile();

      trackEvent(ANALYTICS_EVENTS.LOGIN);
      setIsLoading(false);

      if (loginResults.isNewPasswordRequired) history.push('/change-password');
      else history.push(redirectTo || '/home');
    } catch (error) {
      console.log('[Login] Login Failed: ', error);
      setShowError(true);
      setIsLoading(false);
    }
  };

  const isDisabled = email.trim().length === 0 || password.trim().length === 0;

  return (
    <PublicPageWithIllustration title="Login">
      <form onSubmit={handleLogin} className={styles.container}>
        <TextInput
          label="Your e-mail"
          placeholder="Your nursery email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          id="email"
          hasError={showError}
          className={styles.textInput}
        />

        <TextInput
          label="Your password"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          id="password"
          hasError={showError}
          className={styles.textInput}
        />

        <Page.ErrorMessage isVisible={showError} data-testid="login__error">
          This e-mail address or password is not valid. Please try again
        </Page.ErrorMessage>

        <Button
          className={styles.loginButton}
          disabled={isDisabled || isLoading}
          isWorking={isLoading}
          expand
          data-testid="loginButton"
        >
          Login
        </Button>

        <div className={styles.linksWrapper}>
          <p>
            Don’t have an account yet? <NavLink to="/register">Sign up</NavLink>
          </p>
          <NavLink to="/forgot-password">Forgot password?</NavLink>
        </div>
      </form>
    </PublicPageWithIllustration>
  );
};
