import { useWindowHeight } from 'hooks';

import styles from './FullScreen.module.scss';

export const FullScreen = ({ children, className }) => {
  const { boxStyle } = useWindowHeight();

  return (
    <div className={className || styles.defaultAppearance} style={boxStyle}>
      {children}
    </div>
  );
};
