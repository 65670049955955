import { useState, useEffect } from 'react';
import Amplify, { Auth } from 'aws-amplify';

import { setUserId } from './../amplitude/amplitude';

/**
 * Custom hook that provides all the auth logic to the `<AuthContext.Provider>`
 *
 * This hook should be used to maintain the internal state and methods of the Auth
 * object that gets passed to any components that `useContext(AuthContext)`.
 */
export const useAuth = () => {
  const [isInitializing, setIsInitializing] = useState(true);
  const [activeUser, setActiveUser] = useState(null);

  const configureAmplify = () => {
    Amplify.configure({
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_APP_CLIENT_ID,
    });
  };

  const isAuthenticated = () => {
    return activeUser?.signInUserSession != null ?? false;
  };

  const initializeUser = async () => {
    let isUserSignedIn = false;
    try {
      const user = await Auth.currentAuthenticatedUser();
      setActiveUser(user);
      setUserId(user.username);
      isUserSignedIn = true;
    } catch (error) {
      setActiveUser(null);
    }
    setIsInitializing(false);
    console.log('[Auth] Is user signed in: ', isUserSignedIn);
  };

  const login = async (email, password) => {
    try {
      const user = await Auth.signIn(email.trim(), password.trim());
      console.log('[Auth] Login Success');
      setActiveUser(user);
      setUserId(user.username);
      return {
        isNewPasswordRequired: user.challengeName === 'NEW_PASSWORD_REQUIRED',
      };
    } catch (error) {
      console.log('[Auth] Login Failed: ', error);
      throw error;
    }
  };

  const changePassword = async (newPassword) => {
    try {
      const user = await Auth.completeNewPassword(activeUser, newPassword);
      console.log('[Auth] Complete New Password Success');
      setActiveUser(user);
      setUserId(user.username);
    } catch (error) {
      console.log('[Auth] Complete New Password Failed: ', error);
      throw new Error('Complete New Password Error', error);
    }
  };

  const forgotPasswordInitiate = async (email) => {
    try {
      await Auth.forgotPassword(email.trim());
    } catch (error) {
      throw new Error('Forgot Password Initiate Error', error);
    }
  };

  const forgotPasswordSubmit = async (code, email, newPassword) => {
    try {
      await Auth.forgotPasswordSubmit(email.trim(), code.trim(), newPassword.trim());
    } catch (error) {
      throw new Error('Forgot Password Submit Error', error);
    }
  };

  const logout = async () => {
    try {
      await Auth.signOut();
      console.log('[Auth] Logout Success');
      setActiveUser(null);
      setUserId(null);
    } catch (error) {
      console.log('[Auth] Logout Failed: ', error);
      throw new Error('Logout Error', error);
    }
  };

  const signUp = async (email, password) => {
    try {
      const { user } = await Auth.signUp({
        username: email.trim(),
        password: password.trim(),
      });
      console.log('[Auth] Signup Success');
      setActiveUser(user);
    } catch (error) {
      console.log('[Auth] Signup Failed:', error);
      throw new AuthError(error.code);
    }
  };

  const confirmSignup = async (email, code) => {
    try {
      await Auth.confirmSignUp(email.trim(), code.trim());
      console.log('[Auth] Confirm Signup Success');
    } catch (error) {
      console.log('[Auth] Confirm Signup Failed', error);
      throw new Error('Confirm Signup Error', error);
    }
  };

  const resendVerificationCode = async (email) => {
    await Auth.resendSignUp(email.trim());
  };

  useEffect(() => {
    configureAmplify();
    initializeUser();
  }, []);

  return {
    isInitializing,
    activeUser,
    isAuthenticated,
    login,
    changePassword,
    confirmSignup,
    resendVerificationCode,
    forgotPasswordInitiate,
    forgotPasswordSubmit,
    signUp,
    logout,
  };
};

export class AuthError extends Error {
  constructor(errorCode, errorMessage) {
    super(errorCode);

    // Maintains proper stack trace for where our error was thrown
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthError);
    }

    this.name = 'AuthError';

    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
  }
}
