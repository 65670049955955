/**
 * Given GraphQL innerData and error result from a mutation,
 * returns true if there is any error
 *
 * @param {TData} innerData the inner part of the mutation result data, usually in data[mutationName] format
 * @param {ApolloError} error mutation result error
 * @returns true if there is an error and false otherwise
 */
export const mutationHasErrors = ({ innerData, error }) => {
  return error || (innerData?.errors ? innerData.errors.length > 0 : false);
};
