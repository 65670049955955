import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../app/auth';

import apolloClient from 'graphql/client';

/**
 * Simple logout component. Removes localStorage items and redirects the user back to the application root
 */
export const Logout = () => {
  const { logout } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    async function onLogout() {
      await logout();
      await apolloClient.clearStore();

      history.push('/');
    }

    onLogout();
  }, [history, logout]);

  return null;
};
