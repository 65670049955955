import { differenceInDays, isToday, parseJSON } from 'date-fns';

import { IconCalendar } from 'icons';
import { useProfile } from 'app/profile';
import { Alert, Button } from 'ui';

import { AlertTile } from '../../components/AlertTile';
import styles from './AvailabilityPlannerTile.module.scss';
import { trackAction, actions } from 'app/amplitude';

export const AvailabilityPlannerTile = ({ nurseryId }) => {
  const { nursery } = useProfile();

  const needsFirstSave = nursery?.availabilityLastEditedAt === null;
  const externallyManaged = nursery?.isExternallyManaged;
  const lastUpdated = parseJSON(
    externallyManaged ? nursery?.availabilityLastSynchronised : nursery?.availabilityLastEditedAt,
  );
  const updatedToday = isToday(lastUpdated);
  const daysSinceUpdate = differenceInDays(new Date(), lastUpdated);

  let actionButtonText = needsFirstSave ? 'Add availabilities' : 'Update now';
  if (updatedToday) actionButtonText = 'Update';

  return (
    <AlertTile
      title="Availability Planner"
      className={styles.container}
      actionRequired={!updatedToday}
      actions={
        !externallyManaged && (
          <Button
            href={`/availability?nurseryId=${nurseryId}`}
            onClick={() => trackAction(actions.UPDATE_NOW)}>
            {actionButtonText}
          </Button>
        )
      }
      icon={IconCalendar}
      showRedDot={needsFirstSave || !updatedToday}>
      {!externallyManaged && needsFirstSave ? (
        <strong>To start things right, fill in availabilities in the planner.</strong>
      ) : (
        <>
          Last {externallyManaged ? 'synced' : 'updated'}: <br />
          <strong>
            {updatedToday ? 'Today' : `${daysSinceUpdate} days ago`}

            {!externallyManaged && daysSinceUpdate > 7 && (
              <Alert className={styles.alert}>Your planner needs updating</Alert>
            )}
          </strong>
        </>
      )}
    </AlertTile>
  );
};
