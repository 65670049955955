import classNames from 'classnames';
import { NavLink, useHistory } from 'react-router-dom';

import { IconChevronRight } from 'icons';

import styles from './SettingsLink.module.scss';

export const SettingsLink = ({
  children,
  className,
  disabled,
  href,
  indicator,
  label,
  slug,
  onClick,
}) => {
  const history = useHistory();

  const handleLinkClick = (e) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();

      return;
    }
  };

  let link = href;

  if (!href) {
    link = ['/settings', slug].filter(Boolean).join('/') + history.location.search;
  }

  return (
    <li className={classNames(styles.container, disabled && styles.disabled, className)}>
      <NavLink
        to={link}
        onClick={() => {
          handleLinkClick();
          onClick();
        }}
        className={styles.link}
        activeClassName={styles.activeLink}
        exact>
        {children || label}

        {!href && <IconChevronRight size={16} className={styles.chevronIcon} />}
      </NavLink>

      {indicator && <div className={styles.indicator}>{indicator}</div>}
    </li>
  );
};
