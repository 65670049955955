import classNames from 'classnames';

import { Input } from 'ui';

import styles from './TextInput.module.scss';

export const TextInput = ({ children, className, label, ...inputProps }) => (
  <label className={classNames(styles.container, className)}>
    {label && <div className={styles.label}>{label}</div>}

    <Input {...inputProps} />

    {children}
  </label>
);
