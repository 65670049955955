import classNames from 'classnames';

import styles from './Icon.module.scss';

export const IconExclamationTriangle = ({
  className,
  color = 'currentColor',
  size = 24,
  stroke,
  ...props
}) => (
  <svg
    className={classNames(styles.icon, className)}
    width={size}
    height={size}
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.98694 8.93787C9.98694 9.58523 9.45728 10.1149 8.80992 10.1149C8.16255 10.1149 7.63289 9.58523 7.63289 8.93787V5.40681C7.63289 4.75945 8.16255 4.22979 8.80992 4.22979C9.45728 4.22979 9.98694 4.75945 9.98694 5.40681V8.93787ZM9.98694 13.0574C9.98694 13.3811 9.72211 13.646 9.39843 13.646H8.22141C7.89772 13.646 7.63289 13.3811 7.63289 13.0574V11.8804C7.63289 11.5567 7.89772 11.2919 8.22141 11.2919H9.39843C9.72211 11.2919 9.98694 11.5567 9.98694 11.8804V13.0574ZM16.7984 13.141L10.3883 1.6733C9.16993 -0.561485 8.53295 -0.561475 7.23271 1.69566L0.822651 13.1304C-0.703226 15.6815 -0.0662466 16 2.39986 16H15.22C17.7692 16 18.4062 15.6815 16.7984 13.141Z"
      fill={color}
    />
  </svg>
);
