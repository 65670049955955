import { Route as PublicRoute, Switch } from 'react-router-dom';
import { AnonymousRoute, PrivateRoute } from 'app/auth';

import * as Pages from 'pages';

const Routes = () => (
  <Switch>
    <AnonymousRoute exact path="/" component={Pages.Landing} redirectTo="/home" />
    <AnonymousRoute path="/login" component={Pages.Login} />

    <PublicRoute path="/logout" component={Pages.Logout} />
    <PublicRoute path="/register" component={Pages.Register} />
    <PublicRoute path="/verify-account" component={Pages.VerifyEmail} />
    <PublicRoute path="/change-password" component={Pages.ChangePassword} />
    <PublicRoute path="/forgot-password" component={Pages.ForgotPassword} />

    <PrivateRoute path="/home" component={Pages.Home} />
    <PrivateRoute path="/account" component={Pages.Account} />
    <PrivateRoute path="/availability" component={Pages.AvailabilityPlanner} />
    <PrivateRoute path="/bookings" component={Pages.Bookings} />
    <PrivateRoute path="/guardians" component={Pages.GuardiansList} />
    <PrivateRoute path="/settings" component={Pages.Settings} />
    <PrivateRoute path="/onboarding" component={Pages.Onboarding} useSidebar={false} />

    {/* Catch-all route must be the last route inside of the Switch */}
    <PublicRoute component={Pages.FourOhFour} />
  </Switch>
);

export default Routes;
